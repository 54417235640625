import axios from "axios";
import { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  Container
} from 'react-bootstrap';


const Cards = () => {
  const [data, setdata] = useState([]);
  const fetchData = async () => {
    try {
      const response = await axios.get(`http://localhost:8080/allUsers`);
      const filterData = response.data.filter((val) => val.Admin === '0')
      setdata(filterData)
    } catch (error) {
      console.error('Error fetching data:', error);
    }

  };
  useEffect(() => {
    fetchData()
  }, [])
  return (
    <>
      <Container className="py-5">
        <Row className="justify-content-center align-items-center">
          {data.map((val) => (
            <Col md={6} lg={6} xl={4}>
              <Card className="shadow" style={{ borderRadius: '15px', backgroundColor: '#eee' }}>
                <Card.Body className="p-4 text-black">
                  <div className="d-flex justify-content-center mb-4">
                    <Row>
                      <Col sm={4} className="text-center">
                        <div className="flex-shrink-0">
                          <img
                            src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-profiles/avatar-2.webp"
                            alt="Generic placeholder image"
                            className="img-fluid rounded-circle border border-dark border-3"
                            style={{ width: '100px' }}
                          />
                        </div>
                      </Col>
                      <Col sm={8} className="text-center">
                        <h4>{val.name}</h4>
                        <hr className="w-50 m-auto" />
                        <div className="ms-3">
                          <div className="d-flex flex-row justify-content-center mb-2">
                            <i className="bi bi-person danger fs-5 text-success me-2"></i>
                            <p className="mb-0">{val.userId}</p>
                          </div>
                          <div className="d-flex flex-row justify-content-center mb-2">
                            <i className="bi bi-phone me-2 text-success"></i>
                            <p className="mb-0">{val.MOB_NUMBER}</p>
                          </div>
                          <div className="d-flex flex-row justify-content-center mb-2">
                            <i className="bi bi-envelope me-2 text-success"></i>
                            <p className="mb-0">{val.email}</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="d-flex justify-content-between">
                    <Button variant="secondary" size="sm" className="rounded">
                      View More
                    </Button>
                    <Button variant={data[0].status === 'active' ? 'success' : 'danger'} disabled size="sm" className="rounded-circle">
                      {data[0].status === 'active' ? <i className="bi bi-check2-circle"></i> : <i className="bi bi-x-circle"></i>}
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}

        </Row>
      </Container>
    </>
  );
};

export default Cards;
