import { useState, useEffect } from 'react';
import axios from 'axios';
// 23-03-2024 || BRIJESH SAINI
function useAxiosPost(Url, PostData, Header) {
    const [data, setData] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [imagesData, setimagesData] = useState([])

    const executePost = async (url, postData, header) => {
        try {
            setLoading(true);
            if (Url !== undefined) {
                const response = await axios.post(Url, PostData, Header);
                // console.log(response);
                setError('');
                setData(response.data);
            } else {
                const response = await axios.post(url, postData, header);
                setError('');
                setData(response.data);
            }
            // const response = await axios.post(url, postData, header);

        } catch (error) {
            setError(error.response);
            setData([])
        } finally {
            setLoading(false);
        }
    };

    const executePostImages = async (url, postData, header) => {
        try {
            setLoading(true);

            const response = await axios.post(url, postData, header);
            setError('');
            // console.log(response);
            setimagesData(response.data);

            // const response = await axios.post(url, postData, header);

        } catch (error) {
            setError(error.response);
            setData([])
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        executePost()
    }, [Url])
    return { data, error, loading,imagesData, execute: executePost, executeImageData: executePostImages };

}
// CODE END

export default useAxiosPost;