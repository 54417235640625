import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Container } from "reactstrap";
import Footer from "./Footer";

const FullLayout = () => {
  return (
    <main>
      {/********header**********/}
      <Header />
      <div className="pageWrapper d-lg-flex" >
        {/********Sidebar**********/}
        <aside className="sidebarArea shadow" id="sidebarArea" style={{ overflow: 'auto' }}>
          <Sidebar />
        </aside>
        {/********Content Area**********/}
        <div className="contentArea" style={{ overflowY: 'auto' }}>
          {/********Middle Content**********/}
          <Container className="p-2" fluid>
            <Outlet />
          </Container>

        </div>

      </div>
      <Footer />
    </main>
  );
};

export default FullLayout;
