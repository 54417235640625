import React, { useState } from 'react';
import { Container, Row, Col, Table, Form, InputGroup, Button, Card, CardBody, CardTitle, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik'
import 'jspdf-autotable';
import axios from 'axios';
import urls from '../../url';
import { useAuth } from '../../components/dashboard/context/AuthContext';
// import { CSVLink } from 'react-csv';

function VendorRating() {
    const [auth] = useAuth()

    const Initialvalue = {
        VendorCode: "",
        FromDate: '',
        EndDate: ''
    };
    const [vendorRating, setvendorRating] = useState([]);
    const [RatingQuality, setRatingQuality] = useState([]);
    const [RatingFreight, setRatingFreight] = useState([]);
    const [RatingOverAll, setRatingOverAll] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // 08-04-2024
    // const parsedRatingFreight = RatingQuality === "NO RECORD" ? []:RatingFreight
    // let combineobj = [...vendorRating,...RatingQuality,parsedRatingFreight,...RatingOverAll];
    // let objcomb = {
    //     VR: vendorRating,
    //     QR: RatingQuality
    // }
    // console.log(combineobj);
    const { handleBlur, handleChange, handleSubmit, values } = useFormik({
        initialValues: Initialvalue,
        // validationSchema: LoginSchema,
        onSubmit: async (values) => {
            const data = {
                Data: {
                    Vendor: values.VendorCode,
                    DateFrom: values.FromDate,
                    DateTo: values.EndDate

                }
            }
            // console.log(data);
            // console.log(auth?.token);
            setIsLoading(true)
            try {
                const response = await axios.post(`${urls.vendorRatingApi}`, data, {
                    headers: {
                        'Authorization': auth?.token
                    }
                });

                // console.log(response.data);
                function iterateStrength(obj) {
                    if (Array.isArray(obj)) {
                        for (const item of obj) {
                            iterateStrength(item);
                            // console.log(item);
                        }
                    } else if (typeof obj === 'object' && obj !== null) {
                        // console.log(obj);
                        for (const key in obj) {
                            if (key === 'RatingDelivery') {
                                // console.log((obj[key]));
                                setvendorRating(obj[key])
                                // console.log(Strength: ${ obj[key]});
                            } else if (key === 'RatingQuality') {
                                setRatingQuality(obj[key])
                            } else if (key === 'RatingFreight') {
                                setRatingFreight(obj[key])
                            } else if (key === 'RatingOverAll') {
                                // console.log(obj[key]);
                                setIsLoading(false)
                                setRatingOverAll(obj[key])
                            } else {
                                iterateStrength(obj[key]);
                            }
                        }
                    }
                }
                iterateStrength(response.data)
            } catch (error) {

            }
        }
    })

    // console.log(vendorRating);
    // console.log(RatingFreight);
    // console.log((RatingOverAll));
    // console.log(RatingQuality);

    return (
        <>
            <Card className='shadow bg-white  mx-auto' style={{ borderRadius: '.5rem', }}>
                <CardTitle className="border-bottom d-flex justify-content-between p-1 mb-0 bg-secondary-light text-white">
                    <h5>Vendor Rating</h5>
                </CardTitle>
                <CardBody>
                    <Container>
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col md={4} lg={4} xl={4}>
                                    <InputGroup size='sm' className="mb-1">
                                        <InputGroup.Text>Vendor Code</InputGroup.Text>
                                        <Form.Control
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name='VendorCode'
                                            type='text'
                                            value={values.VendorCode}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col md={4} lg={4} xl={4}>
                                    <InputGroup size='sm' className="mb-1">
                                        <InputGroup.Text>From Date</InputGroup.Text>
                                        <Form.Control
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name='FromDate'
                                            type='date'
                                            value={values.FromDate}
                                            aria-label="fdate" />
                                    </InputGroup>
                                </Col>
                                <Col md={3} lg={3} xl={3}>
                                    <InputGroup size='sm' className="mb-1">
                                        <InputGroup.Text>End   Date</InputGroup.Text>
                                        <Form.Control
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.EndDate}
                                            name='EndDate'
                                            type='date'
                                            aria-label="edate" />
                                    </InputGroup>
                                </Col>
                                <Col md={1} lg={1} xl={1} className='mb-1'>
                                    <Button disabled={isLoading ? true : false} type='submit' size='sm' variant="secondary">
                                        {isLoading ? <Spinner size='sm' animation='border' /> : 'Retrieve'}
                                    </Button>
                                </Col>
                                <Col role='group' className='mb-2'>
                                    {/* <Form onSubmit={handleSubmit}> 
                                     <CSVLink data={[...vendorRating,...RatingOverAll]} filename='AcceptedOrderList' className='btn btn-success'>
                                        Export to Excel
                                    </CSVLink> */}
                                </Col>
                            </Row>
                        </Form>
                        {vendorRating.length > 0 ? <>
                            <Row className='mt-2'>
                                <Col lg={12}>
                                <div style={{ maxHeight: '65vh', overflowY: 'auto' }}>
                                    <Table size='sm' bordered hover id='my-table' className='text-center'>
                                        <thead style={{
                                            position: 'sticky',
                                            top: '0'
                                        }} >
                                            <tr >
                                                <th>RatingType</th>
                                                <th>VendorCode</th>
                                                <th>APR</th>
                                                <th>MAY</th>
                                                <th>JUN</th>
                                                <th>JUL</th>
                                                <th>AUG</th>
                                                <th>SEP</th>
                                                <th>OCT</th>
                                                <th>NOV</th>
                                                <th>DEC</th>
                                                <th>JAN</th>
                                                <th>FEB</th>
                                                <th>MAR</th>

                                            </tr>

                                        </thead>
                                        <tbody >
                                            {vendorRating === 'NO RECORD' ? null :
                                                <>
                                                    {vendorRating.map((val, index) => (
                                                        <tr key={index}>
                                                            <td >{val.NameType}</td>
                                                            <td>{val.Vendor}</td>
                                                            <td>{val.APR}</td>
                                                            <td>{val.MAY}</td>
                                                            <td>{val.JUN}</td>
                                                            <td>{val.JUL}</td>
                                                            <td>{val.AUG}</td>
                                                            <td>{val.SEP}</td>
                                                            <td>{val.OCT}</td>
                                                            <td>{val.NOV}</td>
                                                            <td>{val.DEC}</td>
                                                            <td>{val.JAN}</td>
                                                            <td>{val.FEB}</td>
                                                            <td>{val.MAR}</td>



                                                            {/* <td>{val.verdor}</td>
                                                <td>{val.rating === '0' ? <> <Button className='rounded-circle' disabled size='sm' variant='success'>
                                                    <i class="bi bi-check"></i>
                                                </Button></> : null}</td>
                                                <td>{val.rating === '1' ? <> <Button className='rounded-circle' disabled size='sm' variant='warning'>
                                                    <i class="bi bi-check"></i>
                                                </Button></> : null}</td>
                                                <td>{val.rating === '2' ? <> <Button className='rounded-circle' disabled size='sm' variant='danger'>
                                                    <i class="bi bi-check"></i>
                                                </Button></> : null}</td> */}

                                                        </tr>
                                                    ))}
                                                </>
                                            }
                                            {RatingQuality === 'NO RECORD' ? null :
                                                <>
                                                    {RatingQuality.map((val, index) => (
                                                        <tr key={index}>
                                                            <td>{val.NameType}</td>
                                                            <td>{val.Vendor}</td>
                                                            <td>{val.APR}</td>
                                                            <td>{val.MAY}</td>
                                                            <td>{val.JUN}</td>
                                                            <td>{val.JUL}</td>
                                                            <td>{val.AUG}</td>
                                                            <td>{val.SEP}</td>
                                                            <td>{val.OCT}</td>
                                                            <td>{val.NOV}</td>
                                                            <td>{val.DEC}</td>
                                                            <td>{val.JAN}</td>
                                                            <td>{val.FEB}</td>
                                                            <td>{val.MAR}</td>

                                                        </tr>
                                                    ))}
                                                </>
                                            }
                                            {RatingFreight === 'NO RECORD' ? null :
                                                <>
                                                    {RatingFreight.map((val, index) => (
                                                        <tr key={index}>
                                                            <td>{val.NameType}</td>
                                                            <td>{val.Vendor}</td>
                                                            <td>{val.APR}</td>
                                                            <td>{val.MAY}</td>
                                                            <td>{val.JUN}</td>
                                                            <td>{val.JUL}</td>
                                                            <td>{val.AUG}</td>
                                                            <td>{val.SEP}</td>
                                                            <td>{val.OCT}</td>
                                                            <td>{val.NOV}</td>
                                                            <td>{val.DEC}</td>
                                                            <td>{val.JAN}</td>
                                                            <td>{val.FEB}</td>
                                                            <td>{val.MAR}</td>

                                                        </tr>
                                                    ))}
                                                </>
                                            }
                                            {RatingOverAll === 'NO RECORD' ? null :
                                                <>
                                                    {RatingOverAll.map((val, index) => (
                                                        <tr key={index}>
                                                            <td>{val.NameType}</td>
                                                            <td>{val.Vendor}</td>
                                                            <td>{val.APR}</td>
                                                            <td>{val.MAY}</td>
                                                            <td>{val.JUN}</td>
                                                            <td>{val.JUL}</td>
                                                            <td>{val.AUG}</td>
                                                            <td>{val.SEP}</td>
                                                            <td>{val.OCT}</td>
                                                            <td>{val.NOV}</td>
                                                            <td>{val.DEC}</td>
                                                            <td>{val.JAN}</td>
                                                            <td>{val.FEB}</td>
                                                            <td>{val.MAR}</td>

                                                        </tr>
                                                    ))}
                                                </>
                                            }

                                        </tbody>
                                        {/* <tbody>
                                        {paginatedData.map((val, index) => (
                                            <tr key={index}>
                                                <td>{val.INFNR}</td>
                                                <td>{val.EBELN}</td>
                                                <td>{val.EBELP}</td>
                                                <td>{val.AEDAT}</td>
                                                <td style={{ whiteSpace: 'nowrap' }}>{val.TXZ01}</td>
                                                <td>{val.MATNR}</td>
                                                <td>{val.WERKS}</td>
                                                <td>{val.LGORT}</td>
                                                <td>{val.MATKL}</td>
                                                <td>{val.INFNR}</td>
                                                <td>{val.MENGE}</td>
                                                <td>{val.MEINS}</td>
                                                <td>{val.NETPR}</td>
                                                <td>{val.BRTWR}</td>
                                                <td>{val.MWSKZ}</td>
                                                <td>{val.BWTAR}</td>
                                                <td>{val.ELIKZ}</td>
                                                <td>{val.WEPOS}</td>
                                                <td>{val.REPOS}</td>
                                                <td>{val.GEWEI}</td>
                                                <td>{val.KO_PRCTR}</td>
                                                <td>{val.MTART}</td>
                                                <td>{val.J_1BNBM}</td>
                                            </tr>
                                        ))}
                                    </tbody> */}
                                    </Table >
                                    </div>
                                    {/* <Col lg={12} className="d-flex justify-content-center">
                                    <Pagination className="text-secondary">
                                        {currentPage > 1 && (
                                            <Pagination.First className="text-secondary" onClick={() => setCurrentPage(1)} />
                                        )}
                                        {currentPage > 1 && (
                                            <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} />
                                        )}
                                        {Array.from({ length: pagesToShow }, (_, index) => {
                                            // console.log(index);
                                            const page = currentPage - 2 + index;
                                            // console.log(page);
                                            return page > 0 && page <= totalPages ? (
                                                <Pagination.Item
                                                    key={page}
                                                    active={currentPage === page}
                                                    onClick={() => setCurrentPage(page)}
                                                >
                                                    {page}
                                                </Pagination.Item>
                                            ) : null;
                                        })}
                                        {currentPage < totalPages - pagesToShow + 3 && (
                                            <>
                                                <Pagination.Ellipsis disabled />
                                                <Pagination.Item
                                                    onClick={() => setCurrentPage(totalPages)}
                                                >
                                                    {totalPages}
                                                </Pagination.Item>
                                            </>
                                        )}
                                        {currentPage < totalPages && (
                                            <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} />
                                        )}
                                        {currentPage < totalPages && (
                                            <Pagination.Last onClick={() => setCurrentPage(totalPages)} />
                                        )}
                                    </Pagination>
                                </Col> */}
                                </Col>
                            </Row>

                        </> : null}

                    </Container>
                </CardBody>
            </Card>

        </>
    )
}

export default VendorRating;