import React from 'react';
import { Pagination } from 'react-bootstrap';

const PaginationComponent = ({ currentPage, setCurrentPage, totalPages, pagesToShow }) => {


    return (
        <Pagination size='sm'>
            {currentPage > 1 && (
                <Pagination.First onClick={() => setCurrentPage(1)} />
            )}
            {currentPage > 1 && (
                <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} />
            )}

            {Array.from({ length: pagesToShow }, (_, index) => {
                // console.log(index);
                const page = currentPage - 2 + index;
                // console.log(page);
                return page > 0 && page <= totalPages ? (
                    <Pagination.Item
                        key={page}
                        active={currentPage === page}
                        onClick={() => setCurrentPage(page)}
                    >
                        {page}
                    </Pagination.Item>
                ) : null;
            })}

            {currentPage < totalPages - pagesToShow + 3 && (
                <>
                    <Pagination.Ellipsis disabled />
                    <Pagination.Item

                        onClick={() => setCurrentPage(totalPages)}
                    >
                        {totalPages}
                    </Pagination.Item>
                </>
            )}
            {currentPage < totalPages && (
                <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} />
            )}
            {currentPage < totalPages && (
                <Pagination.Last onClick={() => setCurrentPage(totalPages)} />
            )}
        </Pagination >
    );
};

export default PaginationComponent;

