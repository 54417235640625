import React, { useEffect, useState } from 'react';
import { QrScanner } from '@yudiel/react-qr-scanner';
import { Col, Container, Row, Card, CardBody, CardTitle, Table, Alert } from 'react-bootstrap';
// import axios from 'axios';
// import useAxios from '../../hooks/useAxios';
import useAxiosPost from '../../hooks/useAxiosPost';
import { useAuth } from '../../components/dashboard/context/AuthContext';
import urls from '../../url';
function Qrcode() {
  const [asnList, setProductList] = useState([]);
  const [loadings, setLoading] = useState(false);
  const { data,  execute } = useAxiosPost()
  const [auth] = useAuth()
  useEffect(() => {
    if (data) {
      setProductList(data.Data)
      setLoading(true)
    }
  }, [data]);
  const productScanList = async (result) => {
    const parseData = JSON.parse(result)

    const ASN = parseData?.ASN;
    const vendorCode = parseData?.Vendor
    console.log(auth);
    console.log(ASN,vendorCode);
    try {
      const Data = { Data: { VendorCode:vendorCode, DocumentNumber: ASN } }
      // const asnlistData = await axios.get(`http://localhost:8050/data`);
      await execute(urls.asnQRReportApi, Data, {
        headers: {
          "Authorization": auth?.token
        }

      })

    } catch (error) {
      console.log(error);
    }
    // try {
    //   await execute(result)

    // } catch (error) {
    //   console.log(error);
    // }
  };
  console.log(asnList);
  return (
    <>
      <Card className='shadow'>
        <CardTitle className="border-bottom d-flex justify-content-between p-2 mb-0 bg-secondary-light text-white">
          <h4>Scan QR Code - ASN</h4>
        </CardTitle>
        <CardBody>

          <Container>
            <Row className="justify-content-center">
              <Col md={4}>
                <QrScanner
                  onDecode={(result) => productScanList(result)}
                  onError={(error) => console.log(error?.message)}
                  containerStyle={{
                    backgroundImage: 'none'
                  }}
                  constraints={{
                    facingMode: 'environment',
                    width: {
                      min: 640,
                      ideal: 720,
                      max: 1920
                    },
                    height: {
                      min: 640,
                      ideal: 900,
                      max: 1920
                    }
                  }}
                  scanDelay={500}
                  tracker={
                    true
                  }
                  hideCount={
                    true
                  }
                />
              </Col>
            </Row>
            <Row>
              {loadings ? <>
                <Col>
                  <Card >
                    <CardTitle tag="h6" className="border-bottom d-flex justify-content-between p-3 mb-0 bg-secondary-light text-white text-center">
                      <span style={{ fontWeight: '600' }}>
                        Basic Details
                      </span>
                    </CardTitle>
                    <CardBody className="shadow">
                      <Table responsive bordered hover className="text-center">
                        <thead>
                          <tr >
                            <th>DispatchNo</th>
                            <th>ExpectedDelDate</th>
                            {/* <th>DeliveryDate</th> */}
                            <th>FromAddress</th>
                            <th>ToAddress</th>
                            <th>InDays</th>
                            <th>VehicleNo</th>
                            {/* <th>VehicleNo</th> */}
                            <th>TransporterName</th>
                            {/* <th>MobileNO</th> */}
                            <th>ASN</th>

                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{asnList?.[0]?.DISPATCH?.[0].DispatchNo}</td>
                            {/* <td>{new Date(asnList?.[0]?.DISPATCH?.[0].ExpectedDelDate).toISOString().split('T')[0]}</td> */}
                            {/* <td>{productList.newformdata.DilveryDate}</td> */}
                            <td>{ asnList?.[0]?.DISPATCH?.[0].ExpectedDelDate}</td>
                            <td>{asnList?.[0]?.DISPATCH?.[0].FromAddress}</td>
                            <td>{asnList?.[0]?.DISPATCH?.[0].ToAddress}</td>
                            <td>{asnList?.[0]?.DISPATCH?.[0].InDays}</td>
                            <td>{asnList?.[0]?.DISPATCH?.[0].VehicleNo}</td>
                            {/* <td>{productList.newformdata.VehicleNo}</td> */}
                            <td>{asnList?.[0]?.DISPATCH?.[0].TransporterName}</td>
                            {/* <td>{productList.newformdata.MobileNO}</td> */}
                            <td>{asnList?.[0]?.DISPATCH?.[0].ASN}</td>

                          </tr>
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
                <Col>
                  <Card >
                    <CardTitle tag="h6" className="border-bottom d-flex justify-content-between p-3 mb-0 bg-secondary-light text-white text-center">
                      <span style={{ fontWeight: '600' }}>
                        Details
                      </span>
                    </CardTitle>
                    <CardBody className="shadow">
                      <Table bordered hover className="text-center">
                        <thead>
                          <tr >
                            <th>OrderID</th>
                            <th>Vendor</th>
                            <th>POItem</th>
                            <th>OrderDate</th>
                            <th>Invoice No</th>
                            <th>Invoice Date</th>
                            {/* <th>DeliveryDate</th>
                            <th>InvNo</th>
                            <th>InvDate</th> */}
                            <th>ASN</th>
                          </tr>
                        </thead>
                        <tbody>
                          {asnList?.[0]?.HEADER?.map((val, index) => (
                            <tr key={index}>
                              <td>{val.OrderID}</td>
                              <td>{val.Vendor}</td>
                              <td>{val.POItem}</td>
                              <td>{val.OrderDate}</td>
                              <td>{val.InvoiceNo}</td>
                              <td>{val.InvocieDate}</td>
                              {/* <td>{val.DeliveryDate}</td>
                              <td>{val.InvNo}</td>
                              <td>{val.InvDate}</td> */}
                              <td>{val.ASN}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
              </> : <>
                <Col>
                  <Alert variant='success'>
                    Scan QR Code to generate ASN
                  </Alert>
                </Col>
              </>}
            </Row>
          </Container>
        </CardBody>
      </Card>

    </>
  )
}

export default Qrcode