import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Table, Pagination, Form, InputGroup, Button, Card, CardBody, CardTitle } from 'react-bootstrap';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useAuth } from '../../components/dashboard/context/AuthContext';

const itemsPerPage = 5;
function TableData() {
    const [auth] = useAuth()
    const [dateRange, setDateRange] = useState({
        name: '',
        fdate: "",
        edate: ""
    })
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    // const [filterCheck, setFilterCheck] = useState(true);
    const getTableData = async () => {

        const listData = await axios.get(`http://localhost:7000/OrderDetail?INFNR=${auth.user}`);
        setTableData(listData.data);
    };

    const validationSchema = Yup.object().shape({
        group1: Yup.string().required('Select "Portrait" or "Landscape"'),
    });

    useEffect(() => {
        getTableData()
    }, [ ]);
    // DATE STATE UPDATE
    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setDateRange((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleFetchData = async () => {
        // Assuming dateRange is an object with fdate and edate properties
        const formattedFDate = formatDate(dateRange.fdate);
        const formattedEDate = formatDate(dateRange.edate);
        console.log(formattedEDate, formattedFDate);
        try {
            // AFTER WE USE BOTH DATE PARMAS LIKE  const url = `http://localhost:8000/data?fdate=${formattedFDate}&edate=${formattedEDate}`;
            const url = `http://localhost:8000/data?date=${formattedFDate}`;
            const response = await axios.get(url);
            setTableData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        // setTableData(response.data);
    };

    function formatDate(inputDateString) {
        const parts = inputDateString.split('-');
        const year = parts[0];
        const month = parts[1];
        const day = parts[2];
        // Create a Date object using the parsed components
        const date = new Date(year, month - 1, day); // Note: Months are 0-indexed in the Date objec
        // Extract the formatted components
        const formattedDay = String(date.getDate()).padStart(2, '0');
        const formattedMonth = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 to get the correct month
        const formattedYear = date.getFullYear();
        // Build the formatted date string
        const formattedDate = `${formattedDay}/${formattedMonth}/${formattedYear}`;
        return formattedDate;
    }

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedData = tableData.slice(startIndex, endIndex);
    const totalPages = Math.ceil(tableData.length / itemsPerPage);
    const pagesToShow = 5;

    const { handleBlur, handleChange, errors, handleSubmit, values } = useFormik({
        initialValues: { group1: '' },
        validationSchema: validationSchema,
        onSubmit: (val) => {
            const doc = new jsPDF({ orientation: `${val.group1}` });
            // Fetch all the data (without pagination) based on the current filter
            const allData = tableData;
            // Create an array of arrays representing the table data
            const tableRows = allData.map((val) => [
                val.INFNR.toString(),
                val.EBELN,
                val.EBELP.toString(),
                val.AEDAT.toString(),
                val.TXZ01,
                val.MATNR,
                val.WERKS,
                val.LGORT,
                val.MATKL,
                val.MENGE,
                val.MEINS,
                val.NETPR,
                val.BRTWR,
                val.MTART
            ]);

            // Add headers to the tableRows
            tableRows.unshift(['INFNR', 'EBELN', 'EBELP', 'AEDAT', 'TXZ01', 'MATNR', 'WERKS', 'LGORT', 'MATKL', 'MENGE', 'MEINS', 'NETPR', 'BRTWR']);
            // Set the table headers
            const headers = tableRows[0];
            // Remove the headers from the tableRows
            tableRows.shift();
            // DEFINE => COLUMN LINE 
            const chunkSize =8;
            for (let i = 0; i < headers.length; i += chunkSize) {
                const headerChunk = headers.slice(i, i + chunkSize);
                const bodyChunk = tableRows.map(row => row.slice(i, i + chunkSize));
                // Auto-generate the table in the PDF
                doc.autoTable({
                    head: [headerChunk],
                    body: bodyChunk,
                });
                if (i + chunkSize < headers.length) {
                    doc.addPage();
                }
            }
            // Save the PDF
            doc.save('data.pdf');
        }
    })

    //     const doc = new jsPDF({ orientation: 'portrait' });
    //     // Fetch all the data (without pagination) based on the current filter
    //     const allData = tableData;
    //     // Create an array of arrays representing the table data
    //     const tableRows = allData.map((val) => [
    //         val.id.toString(),
    //         val.name,
    //         val.price.toString(),
    //         val.quantity.toString(),
    //         val.date,
    //     ]);
    //     // Add headers to the tableRows
    //     tableRows.unshift(['Id', 'Name', 'Price', 'Quantity', 'Date']);
    //     // Set the table headers
    //     const headers = tableRows[0];
    //     // Remove the headers from the tableRows
    //     tableRows.shift();
    //     // Auto-generate the table in the PDF
    //     doc.autoTable({
    //         head: [headers],
    //         body: tableRows,
    //     });
    //     // Save the PDF
    //     doc.save('data.pdf');
    // };
    return (
        <>
            <Card className='shadow'>
                <CardTitle  className="border-bottom d-flex justify-content-between p-2 mb-0 bg-secondary-light text-white">
                <h4>Report Details</h4>
                </CardTitle>
                <CardBody>
                    <Container> 
                            <Row>
                                <Col md={5} lg={4} xl={2}>
                                    <InputGroup className="mb-3">
                                        <Form.Control name='name' placeholder='Name' onChange={handleDateChange} type='text' aria-label="name" />
                                    </InputGroup>
                                </Col>
                                <Col md={6} lg={4} xl={3}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>From Date</InputGroup.Text>
                                        <Form.Control name='fdate' onChange={handleDateChange} type='date' aria-label="fdate" />
                                    </InputGroup>
                                </Col>
                                <Col md={5} lg={4} xl={3}>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>End Date</InputGroup.Text>
                                        <Form.Control name='edate' onChange={handleDateChange} type='date' aria-label="edate" />
                                    </InputGroup>
                                </Col>
                                <Col md={2} lg={1} xl={1} className='mb-3'>
                                    <Button onClick={handleFetchData} variant="outline-secondary">Retrieve</Button>
                                </Col>
                                <Col role='group' className='mb-5'>
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group className="position-absolute">
                                            <Form.Check
                                                inline
                                                label="Portrait"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                name="group1"
                                                value='portrait'
                                                type='radio'
                                                id={`inline-${'radio'}-1`}
                                                checked={values.group1 === 'portrait'}
                                                // feedback={errors.group1}
                                                feedbackType="invalid"
                                                isInvalid={!!errors.group1}
                                            />
                                            <Form.Check
                                                inline
                                                label="Landscape"
                                                name="group1"
                                                value='landscape'
                                                type='radio'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                checked={values.group1 === 'landscape'}
                                                id={`inline-${'radio'}-2`}
                                                isInvalid={!!errors.group1}
                                                feedback={errors.group1}
                                                feedbackType="invalid"
                                                feedbackTooltip
                                            />
                                            <Button type='submit' variant="outline-success">Export</Button>
                                        </Form.Group>

                                    </Form>
                                </Col>
                            </Row>
                        <Row className='mt-3'>
                            <Col lg={12}>
                                <Table bordered hover id='my-table' className='text-center' responsive>
                                    <thead>
                                        <tr>
                                            <th>INFNR</th>
                                            <th>EBELN</th>
                                            <th>EBELP</th>
                                            <th>AEDAT</th>
                                            <th>TXZ01</th>
                                            <th>MATNR</th>
                                            <th>WERKS</th>
                                            <th>LGORT</th>
                                            <th>MATKL</th>
                                            <th>INFNR</th>
                                            <th>MENGE</th>
                                            <th>MEINS</th>
                                            <th>NETPR</th>
                                            <th>BRTWR</th>
                                            <th>MWSKZ</th>
                                            <th>BWTAR</th>
                                            <th>ELIKZ</th>
                                            <th>WEPOS</th>
                                            <th>REPOS</th>
                                            <th>GEWEI</th>
                                            <th>KO_PRCTR</th>
                                            <th>MTART</th>
                                            <th>J_1BNBM</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedData.map((val, index) => (
                                            <tr key={index}>
                                                <td>{val.INFNR}</td>
                                                <td>{val.EBELN}</td>
                                                <td>{val.EBELP}</td>
                                                <td>{val.AEDAT}</td>
                                                <td style={{whiteSpace:'nowrap'}}>{val.TXZ01}</td>
                                                <td>{val.MATNR}</td>
                                                <td>{val.WERKS}</td>
                                                <td>{val.LGORT}</td>
                                                <td>{val.MATKL}</td>
                                                <td>{val.INFNR}</td>
                                                <td>{val.MENGE}</td>
                                                <td>{val.MEINS}</td>
                                                <td>{val.NETPR}</td>
                                                <td>{val.BRTWR}</td>
                                                <td>{val.MWSKZ}</td>
                                                <td>{val.BWTAR}</td>
                                                <td>{val.ELIKZ}</td>
                                                <td>{val.WEPOS}</td>
                                                <td>{val.REPOS}</td>
                                                <td>{val.GEWEI}</td>
                                                <td>{val.KO_PRCTR}</td>
                                                <td>{val.MTART}</td>
                                                <td>{val.J_1BNBM}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table >
                                <Col lg={12} className="d-flex justify-content-center">
                                    <Pagination className="text-secondary">
                                        {currentPage > 1 && (
                                            <Pagination.First className="text-secondary" onClick={() => setCurrentPage(1)} />
                                        )}
                                        {currentPage > 1 && (
                                            <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} />
                                        )}
                                        {Array.from({ length: pagesToShow }, (_, index) => {
                                            // console.log(index);
                                            const page = currentPage - 2 + index;
                                            // console.log(page);
                                            return page > 0 && page <= totalPages ? (
                                                <Pagination.Item
                                                    key={page}
                                                    active={currentPage === page}
                                                    onClick={() => setCurrentPage(page)}
                                                >
                                                    {page}
                                                </Pagination.Item>
                                            ) : null;
                                        })}
                                        {currentPage < totalPages - pagesToShow + 3 && (
                                            <>
                                                <Pagination.Ellipsis disabled />
                                                <Pagination.Item
                                                    onClick={() => setCurrentPage(totalPages)}
                                                >
                                                    {totalPages}
                                                </Pagination.Item>
                                            </>
                                        )}
                                        {currentPage < totalPages && (
                                            <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} />
                                        )}
                                        {currentPage < totalPages && (
                                            <Pagination.Last onClick={() => setCurrentPage(totalPages)} />
                                        )}
                                    </Pagination>
                                </Col>
                            </Col>
                        </Row>
                    </Container>
                </CardBody>
            </Card>

        </>
    )
}
export default TableData;