import React, { useEffect, useReducer } from 'react'
import { useAuth } from '../../components/dashboard/context/AuthContext'
import { initialState } from './initialState';
import { reducer } from './generateAsnReducer';
// import useAxios from '../../hooks/useAxios';
import urls from '../../url';
import { all_Check_Boxes_SelectedAction, get_generateASN_Data, handle_Search_Filter, handle_Submit_Data, set_Dispatch_Qty, set_Inv_Date, set_Inv_No, set_Pagination_Page, single_Check_Box_SelectAction } from './actionCreator';
import DeliveryPageForm from '../ui/DeliveryPageForm';
import { Col, Form, Container, Row, Badge, CardTitle, CardBody, Card, Button, InputGroup, Table } from 'react-bootstrap';
import Loader from '../../layouts/loader/Loader';
import PaginationComponent from '../../components/Pagination';
import useAxiosPost from '../../hooks/useAxiosPost';

function GenerateAsn() {
    const [auth] = useAuth();
    const [state, dispatch] = useReducer(reducer, initialState);
    const currentDate = new Date().toISOString().split('T')[0];
    const parseUserData = JSON.parse(auth?.user)
    const Data = { Data: { VendorCode: parseUserData?._id } }
    const { data, loading, } = useAxiosPost(`${urls.generateASNApi}`, Data, {
        headers: {
            "Authorization": auth?.token
        }
    });
    useEffect(() => {
        if (data) {
            //    console.log(data);

            // console.log('data is ' + JSON.stringify(data));
            // const responseFilterByPendingQTY = data?.filter((val)=>val.PendingQty>0)
            // Number(val.OrderQuantity) < Number(val.DispatchQty)
            // console.log(data);
            const responseFilterByPendingQTY = data?.filter((val) => Number(val.OrderQuantity) > Number(val.DispatchQty || 0));
            // const responseFilterByPendingQTY = data?.filter((val) =>val.DispatchQty ? Number(val.OrderQuantity) > Number(val.DispatchQty ):val) 
            // console.log(responseFilterByPendingQTY);
            get_generateASN_Data(dispatch, responseFilterByPendingQTY, loading)
            // console.log('Data with pending zero is ' + responseFilterByPendingQTY);
        }
    }, [data, loading])
    console.log(data);
    // PAGINATION 
    const itemsPerPage = 7;
    const startIndex = (state.currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedData = (state.filterData.length > 0 ? state.filterData : state.data).slice(startIndex, endIndex);
    const totalPages = Math.ceil((state.filterData.length > 0 ? state.filterData : state.data).length / itemsPerPage);
    const pagesToShow = 5;

    const AllCheckBoxesSelected = () => {
        all_Check_Boxes_SelectedAction(dispatch, state, paginatedData)
    };

    const SingleChechBoxSelect = (id) => {
        single_Check_Box_SelectAction(dispatch, state, id)
    }

    const HandleSearchFilte = (e) => {
        handle_Search_Filter(dispatch, state, e)
    }

    const SetPaginationPage = (page) => {
        set_Pagination_Page(dispatch, page)
    }

    const setDispatchQuantity = (e, id, ScheduledQty) => {
        set_Dispatch_Qty(e, id, ScheduledQty, dispatch)
    }

    const SetInvDate = (e, id) => {
        set_Inv_Date(e, id, currentDate, dispatch)
    }

    const setInvNo = (e, id) => {
        set_Inv_No(e, id, dispatch)
    }

    const handleSubmitAllData = () => {
        handle_Submit_Data(state, dispatch)
    }
    return (
        <>
            <Card className='shadow'>
                <CardTitle className="border-bottom d-flex justify-content-between p-1 mb-0 bg-secondary-light text-white">
                    <Container>
                        <Row className='d-flex align-items-center justify-content-center'>
                            <Col xs={12} md={2}>
                                <h5>Generate ASN</h5>
                            </Col>
                            <Col xs={12} md={5} >
                                <Form>
                                    <InputGroup>
                                        <Form.Control
                                            onChange={(e) => HandleSearchFilte(e)}
                                            placeholder='Search here...'
                                            type='text'
                                            size='sm'
                                            aria-label='search'
                                            aria-describedby='basic-addon1'
                                        />
                                        <Button variant='light'>
                                            <i className='bi bi-search'></i>
                                        </Button>
                                    </InputGroup>
                                </Form>
                            </Col>
                            <Col className='text-md-end text-sm-start'>
                                {state.selectedRows.length > 0 && (
                                    <Badge bg="secondary">{state.selectedRows.length} selected</Badge>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </CardTitle>
                <CardBody>
                    {state.data.length <= 0 ? (
                        'No data found'
                    ) : (
                        <>
                            <Table responsive size='sm' bordered className='mt-2' hover>
                                <thead>
                                    <tr className='text-center'>
                                        <th>
                                            <Form.Group id='formGridCheckbox'>
                                                <Form.Check
                                                    type='checkbox'
                                                    onChange={() => AllCheckBoxesSelected()}
                                                    checked={paginatedData.length > 0 && paginatedData.every((val) => state.selectedRows.includes(`${val.PONumber + "" + val.PoItem}`))}
                                                // checked={check}
                                                // onChange={(e) => handleCheckBoxChange(e)}
                                                />
                                            </Form.Group>
                                        </th>
                                        <th>PONumber </th>
                                        <th>Vendor</th>
                                        <th>DocType</th>
                                        <th>PoItem</th>
                                        <th>Plant</th>
                                        <th>MaterialCode</th>
                                        <th>OrderQuantity</th>
                                        <th>OrderUnit</th>

                                        <th>PendingQty</th>

                                        <th>OrderDate</th>
                                        <th>AcceptedQty</th>
                                        <th>QuantityDel</th>
                                        <th>AlreadyDispatched</th>
                                        {/* <th>DeliveryDate</th> */}
                                        <th>DispatchQty</th>
                                        {/* <th>DispatchDate</th> */}
                                        <th>InvNo</th>
                                        <th>InvDate</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.data.length > 0 && state.filterCheck ? <>
                                        {paginatedData.map((val, index) => (
                                            <tr key={index} className={`text-center ${state.selectedRows.includes(`${val.PONumber + "" + val.PoItem}`) ? 'table-active' : ''}`}>
                                                <td>
                                                    <Form.Check
                                                        type='checkbox'
                                                        onChange={() => SingleChechBoxSelect(`${val.PONumber + "" + val.PoItem}`)}
                                                        checked={state.selectedRows.includes(`${val.PONumber + "" + val.PoItem}`)}
                                                    // onChange={(e) => handleCheckBoxChange(e, val.id, index)}
                                                    // checked={selectedRows.includes(val.id)}
                                                    />
                                                </td>
                                                <td>{val.PONumber}</td>
                                                <td>{val.Vendor}</td>
                                                <td>{val.PurchasingDocType}</td>
                                                <td>{val.PoItem}</td>
                                                <td>{val.PLANT}</td>
                                                <td style={{ whiteSpace: 'nowrap' }}>{val.MaterialCode}</td>
                                                <td>{val.OrderQuantity}</td>
                                                <td>{val.OrderUnit}</td>

                                                <td>{val.PendingQty}</td>

                                                <td>{val.DocumentDate}</td>
                                                <td>{val.AcceptedQty}</td>

                                                <td>{val.QuantityDel}</td>
                                                <td>{val.DispatchQty}</td>
                                                {/* <td>{val.DeliveryDate}</td> */}
                                                <td>  <Form.Group key={index} className={`text-center ${state.selectedRows.includes(`${val.PONumber + "" + val.PoItem}`) ? 'table-active' : ''}`}>
                                                    <Form.Control
                                                        style={{ minWidth: '70px' }}
                                                        // maxLength='5'
                                                        name='dispatchQuantity'
                                                        value={state.dispatchQuantity[`${val.PONumber + "" + val.PoItem}`] !== undefined ? state.dispatchQuantity[`${val.PONumber + "" + val.PoItem}`] : ""}
                                                        // onChange={(e) => handleChangeInput(e, val.id, val.ScheduledQty)}
                                                        onChange={(e) => setDispatchQuantity(e.target.value, `${val.PONumber + "" + val.PoItem}`, val.ScheduledQty)}
                                                        // onInput={(e) => {
                                                        //     e.target.value = e.target.value.replace(/[^\d.]/g, '');
                                                        // }}
                                                      /*  onInput={(e) => {
                                                            e.target.value = e.target.value.replace(/[^\d.]/g, '');
                                                        }}
                                                        type='text' Commented on 11_06_2024*/
                                                        type="number"
                                                       
                                                        size='sm'

                                                    />
                                                </Form.Group></td>
                                                <td>

                                                    <Form.Group key={index} className={`text-center ${state.selectedRows.includes(`${val.PONumber + "" + val.PoItem}`) ? 'table-active' : ''}`}>
                                                        <Form.Control
                                                            style={{ minWidth: '100px' }}
                                                            name='invNo'
                                                            size='sm'
                                                            value={state.invoiceInput[`${val.PONumber + "" + val.PoItem}`] !== undefined ? state.invoiceInput[`${val.PONumber + "" + val.PoItem}`] : ""}
                                                            onChange={(e) => setInvNo(e.target.value, `${val.PONumber + "" + val.PoItem}`)}
                                                            type='text'
                                                        />
                                                    </Form.Group>
                                                </td>
                                                <td>
                                                    <Form.Group key={index} className={`text-center ${state.selectedRows.includes(`${val.PONumber + "" + val.PoItem}`) ? 'table-active' : ''}`}>
                                                        <Form.Control
                                                          //  min={currentDate}
                                                            name='invDate'
                                                            value={state.invDate[`${val.PONumber + "" + val.PoItem}`] !== undefined && state.invDate[`${val.PONumber + "" + val.PoItem}`]}
                                                            onChange={(e) => SetInvDate(e.target.value, `${val.PONumber + "" + val.PoItem}`)}
                                                            // value={selectedDate[val.id]}
                                                            // value={inputValues.invDate[val.id] !== undefined && inputValues.invDate[val.id]}
                                                            // onChange={(e) => handleChangeInput(e, val.id)}
                                                            type='date'
                                                            size='sm'
                                                        />
                                                    </Form.Group>
                                                </td>
                                            </tr>
                                        ))}
                                    </> : <>
                                        <tr>
                                            <td colSpan="10">No data found</td>
                                        </tr>
                                    </>}
                                </tbody>
                            </Table>
                            <Container className='mt-2'>
                                <Row className="justify-content-center">
                                    <Col md={6} className="d-flex justify-content-center">
                                        {/* <Pagination>
                                            {currentPage > 1 && (
                                                <Pagination.First onClick={() => setCurrentPage(1)} />
                                            )}
                                            {currentPage > 1 && (
                                                <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} />
                                            )}

                                            {Array.from({ length: pagesToShow }, (_, index) => {
                                                // console.log(index);
                                                const page = currentPage - 2 + index;
                                                // console.log(page);
                                                return page > 0 && page <= totalPages ? (
                                                    <Pagination.Item
                                                        key={page}

                                                        active={currentPage === page}
                                                        onClick={() => setCurrentPage(page)}
                                                    >
                                                        {page}
                                                    </Pagination.Item>
                                                ) : null;
                                            })}

                                            {currentPage < totalPages - pagesToShow + 3 && (
                                                <>
                                                    <Pagination.Ellipsis disabled />
                                                    <Pagination.Item

                                                        onClick={() => setCurrentPage(totalPages)}
                                                    >
                                                        {totalPages}
                                                    </Pagination.Item>
                                                </>
                                            )}
                                            {currentPage < totalPages && (
                                                <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} />
                                            )}
                                            {currentPage < totalPages && (
                                                <Pagination.Last onClick={() => setCurrentPage(totalPages)} />
                                            )}
                                        </Pagination> */}
                                        <PaginationComponent

                                            currentPage={state.currentPage}
                                            setCurrentPage={SetPaginationPage}
                                            totalPages={totalPages}
                                            pagesToShow={pagesToShow}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </>
                    )}
                    {/* <DeliveryPageForm griddata={state.gridData} handleSubmitAllData={handleSubmitAllData} counter={state.counter} /> */}
                    <DeliveryPageForm griddata={state.gridData} handleSubmitAllData={handleSubmitAllData} />
                </CardBody>
            </Card>
        </>
    )
}

export default GenerateAsn