import {  FINAL_SUBMIT, GET_GENERATE_ASN_DATA, HANDLE_SUBMIT, SET_CURRENT_PAGE, SET_DISPATCH_QUANTITY, SET_FILTER_CHECK, SET_FILTER_DATA, SET_INV_DATE, SET_INV_NO, SHOW_TOAST, allCheckBoxSelected, singleCheckBoxSelected } from "./action"

export const get_generateASN_Data = (dispatch, data, isloading) => {
    dispatch({
        type: GET_GENERATE_ASN_DATA,
        payload: data,
        isLoading: isloading
    })
}


export const all_Check_Boxes_SelectedAction = (dispatch, state, paginatedData) => {
    // const updatedSelectedRows = state.selectedRows.length === paginatedData.length
    //     ? []
    //     : paginatedData.map(value => value.id);
    // Check if all visible rows are selected
    const allVisibleRowsSelected = paginatedData.every((val) =>
        state.selectedRows.includes(`${val.PONumber + "" + val.PoItem}`)
    );


    // Toggle select all on the current page
    const newSelectedRows = allVisibleRowsSelected
        ? state.selectedRows.filter((rowId) => !paginatedData.map((val) => `${val.PONumber + "" + val.PoItem}`).includes(rowId))
        : Array.from(new Set([...state.selectedRows, ...paginatedData.map((val) => `${val.PONumber + "" + val.PoItem}`)]));

    dispatch({
        type: allCheckBoxSelected,
        payload: newSelectedRows,
    });
};


export const single_Check_Box_SelectAction = (dispatch, state, id) => {
    // const updatedSelectedRows = state.selectedRows.includes(id)
    //     ? state.selectedRows.filter(rowId => rowId !== id)
    //     : [...state.selectedRows, id];
    const isSelected = state.selectedRows.includes(id);
    const newSelectedRows = isSelected
        ? state.selectedRows.filter((rowId) => rowId !== id)
        : Array.from(new Set([...state.selectedRows, id]));
    dispatch({
        type: singleCheckBoxSelected,
        payload: newSelectedRows
    });
};

export const handle_Search_Filter = (dispatch, state, e) => {
    const filteredData = state.data.filter(item => {
        return (
            item.OrderHId.toLowerCase().includes(e.target.value) ||
            item.VendorCode.toLowerCase().includes(e.target.value) ||
            item.Org.toLowerCase().includes(e.target.value) ||
            item.LineItem.toLowerCase().includes(e.target.value) ||
            item.Plant.toLowerCase().includes(e.target.value) ||
            item.QuantityDel.toLowerCase().includes(e.target.value) ||
            item.UOM.toLowerCase().includes(e.target.value)
        );
    }
    );
    dispatch({ type: SET_FILTER_DATA, payload: filteredData });
    dispatch({ type: SET_CURRENT_PAGE, payload: 1 });
    dispatch({ type: SET_FILTER_CHECK, payload: filteredData.length > 0 ? true : false })
}

export const set_Pagination_Page = (dispatch, page) => {
    dispatch({
        type: SET_CURRENT_PAGE,
        payload: page
    })
}

export const handle_Submit_Data = (state, dispatch) => {
    const textValues = state.data.reduce((accumulator, currentValue) => {
        // dispatch({ type: COUNTER })
        if (state.selectedRows.includes(`${currentValue.PONumber + "" + currentValue.PoItem}`)) {
            accumulator.push({
                // ...currentValue,
                VendorCode:currentValue.Vendor,   
                DispatchQty: state.dispatchQuantity[`${currentValue.PONumber + "" + currentValue.PoItem}`] ? state.dispatchQuantity[`${currentValue.PONumber + "" + currentValue.PoItem}`] : currentValue.Scheduled_Quantity,
                InvoiceNo: state.invoiceInput[`${currentValue.PONumber + "" + currentValue.PoItem}`],
                InvoiceDate: state.invDate[`${currentValue.PONumber + "" + currentValue.PoItem}`],
                OrderID:currentValue.PONumber,
                OrderType:currentValue.PurchasingDocType,
                LineItem:currentValue.PoItem
            });
        }
        return accumulator;
    }, []);
    dispatch({ type: HANDLE_SUBMIT, payload: textValues })
    console.log("Text Box Values:", textValues);
    dispatch({ type: SHOW_TOAST, payload: true })
    setTimeout(() => {
        dispatch({ type: SHOW_TOAST, payload: false })
    }, 5000);
    // Update Product List
    // if (filterData.length > 0) {
    //     let updatedProductList = product.filter((val) => !selectedRows.includes(val.id));
    //     setFilterData(updatedProductList);
    // }

    // let updatedProductList = product.filter((val) => !selectedRows.includes(val.id));
    const counter = state.counter + 1
    // const FilterResult = state.filterData.length > 0 && state.data.filter((val) => !state.selectedRows.includes(val.id));
    // const ProductResult = state.data.filter((val) => !state.selectedRows.includes(val.id));
    const FilterResult = state.filterData.length > 0 && state.data.filter((val) => !state.selectedRows.includes(`${val.PONumber + "" + val.PoItem}`));
    const ProductResult = state.data.filter((val) => !state.selectedRows.includes(`${val.PONumber + "" + val.PoItem}`));
    // setproducts(updatedProductList);
    // setSelectedRows([])
    // setCheck(false)
    dispatch({ type: FINAL_SUBMIT, payload: { FilterResult, ProductResult, counter } })
}


export const set_Dispatch_Qty = (e, id, ScheduledQty, dispatch) => {
    if (Number(e) > (Number(ScheduledQty)))
        alert(`Selected Quantitiy not greater then ${ScheduledQty}`)
    else {
        dispatch({
            type: SET_DISPATCH_QUANTITY,
            payload: { e, id }
        })

        // const inputValue = Number(value);
        // if (inputValue > Number(scheduleQuantity)) {
        //     alert(`Selected Quantitiy not greater then ${scheduleQuantity}`)
        //     // console.error("Error: Value cannot be greater than 100");
        // } else {
        //     setInputValues((prevInputval) => ({
        //         ...prevInputval,
        //         dispatchQuantity: {
        //             ...prevInputval.dispatchQuantity,
        //             [id]: inputValue,
        //         },
        //     }));
    }
}

export const set_Inv_Date = (e, id, currentDate, dispatch) => {
   // e < currentDate                       Commented Alert message on 11_06_2024
     //   ? alert('Selected date should be greater then current date')
       // :
        dispatch({
            type: SET_INV_DATE,
            payload: e, id: id
        })
}

export const set_Inv_No = (e, id, dispatch) => {
    dispatch({
        type: SET_INV_NO,
        payload: { e, id }
    })
}

