import React, { useState } from 'react'
import { Container, Row, Col, Card, Form, Button, FormGroup, Image,Spinner } from 'react-bootstrap';
import { useFormik } from 'formik'
import { LoginSchema } from './LoginSchema';
import {  useNavigate } from 'react-router-dom';
import logo from '../assets/images/logos/gna-logo.gif'
import { useAuth } from '../components/dashboard/context/AuthContext';
// import useAxiosPost from '../hooks/useAxiosPost';
import urls from '../url';
import axios from 'axios';


function Login() {
    const [loginError, setLoginError] = useState('');
    const [isLoading, setIsLoading] = useState(false); // 08-04-2024
    const [, setAuth] = useAuth();

    const navigate = useNavigate();



    const LoginInitialvalue = {
        userId: '',
        password: ''
    };
    const { handleBlur, handleChange, handleSubmit, values, touched, errors } = useFormik({
        initialValues: LoginInitialvalue,
        validationSchema: LoginSchema,
        onSubmit: async (values) => {
            // navigate('/forget')
            setIsLoading(true)
            try {
                const Data = { Data: { VendorCode: values.userId, VendorSaltPassword: values.password, VendorIPAddress: "" } }

                const response = await axios.post(`${urls.loginApi}`, Data);
                // console.log(response.data);
                if (response.data) {
                    setIsLoading(false)
                    if (response.data.data.vfirstLogin === 2) {
                        setAuth((pre) => ({
                            ...pre,
                            user: JSON.stringify(response.data.data),
                            token: response.data.token,
                            admin: response.data.vType,
                        }))

                        navigate('/changepassword');
                    } else if (response.data.data.vfirstLogin === 0) {
                        setAuth((pre) => ({
                            ...pre,
                            user: JSON.stringify(response.data.data),
                            token: response.data.token,
                            admin: response.data.vType,
                        }))
                        // console.log(response.data);
                        localStorage.setItem('auth', JSON.stringify({
                            user: JSON.stringify(response.data.data),
                            token: response.data.token,
                            admin: response.data.vType,
                        }))
                        navigate('/dashboard');
                    }
                }
            } catch (error) {
                if (error.response.data.error === "Authentication failed") {
                    console.log("error in api");
                    setLoginError('Either UserId and Password is incorrect');
                    setIsLoading(false)
                }
            }
        }


        // new this part is chatgpt suggect above ode is yours
        // onSubmit: async (values) => {
        //     setIsLoading(true);
        //     try {
        //         const Data = {
        //             Data: {
        //                 VendorCode: values.userId,
        //                 VendorSaltPassword: values.password,
        //                 VendorIPAddress: ""
        //             }
        //         };
        
        //         const response = await axios.post(`${urls.loginApi}`, Data);
        //         if (response.data) {
        //             setIsLoading(false);
        //             const responseData = response.data.data; // Check if data property exists
        //             if (responseData) {
        //                 if (responseData.vfirstLogin === 2) {
        //                     setAuth((pre) => ({
        //                         ...pre,
        //                         user: JSON.stringify(responseData),
        //                         token: response.data.token,
        //                         admin: response.data.vType,
        //                     }));
        //                     navigate('/changepassword');
        //                 } else if (responseData.vfirstLogin === 0) {
        //                     setAuth((pre) => ({
        //                         ...pre,
        //                         user: JSON.stringify(responseData),
        //                         token: response.data.token,
        //                         admin: response.data.vType,
        //                     }));
        //                     localStorage.setItem('auth', JSON.stringify({
        //                         user: JSON.stringify(responseData),
        //                         token: response.data.token,
        //                         admin: response.data.vType,
        //                     }));
        //                     navigate('/dashboard');
        //                 }
        //             } else {
        //                 console.error("Response data is undefined or doesn't contain the expected structure.");
        //             }
        //         }
        //     } catch (error) {
        //         if (error.response && error.response.data && error.response.data.error === "Authentication failed") {
        //             console.error("Authentication failed:", error.response.data.error);
        //             setLoginError('Either UserId and Password is incorrect');
        //         } else {
        //             console.error("Error in API request:", error);
        //             setLoginError('An unexpected error occurred. Please try again later.');
        //         }
        //         setIsLoading(false);
        //     }
        // }
        
    })
    // console.log(loginError);
    return (
        <Container fluid style={{ height: '100vh', backgroundColor: '#E7E9EB' }}>
            <Row className='d-flex justify-content-center align-items-center h-100'>
                <Col col='12'>
                    <Card className='bg-white my-5 mx-auto shadow' style={{ borderRadius: '.5rem', maxWidth: '400px' }}>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Card.Body className='p-5 w-100 d-flex flex-column '>
                                <Image src={logo} fluid alt="Logo" style={{ height: '100px', maxWidth: '400px', margin: 'auto', width: '100%' }} className="mb-3" />
                                <FormGroup controlId="validationFormik01">
                                    <Form.Label >User Id</Form.Label>
                                    <Form.Control onChange={handleChange} onBlur={handleBlur}
                                        value={values.userId}
                                        maxLength='10'
                                        type="text"
                                        name='userId'
                                        className={`mb-2 ${touched.userId && (errors.userId || loginError) ? 'is-invalid' : ''}`}
                                        size='sm'
                                        isValid={(touched.userId && !errors.userId) || loginError}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {touched.userId && errors.userId}
                                    </Form.Control.Feedback>
                                </FormGroup>
                                <Form.Label >Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    name='password'
                                    className={`mb-2 ${touched.password && (errors.password || loginError) ? 'is-invalid' : ''}`}
                                    size='sm'
                                    isValid={(touched.password && !errors.password) || loginError}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {(touched.password && errors.password) || loginError}
                                </Form.Control.Feedback>
                                <Button disabled={isLoading ? true : false} variant="secondary" className='mt-2' type='submit' size='sm'>
                                    {isLoading ? <Spinner size='sm' animation='border' /> : 'Login'}
                                </Button>
                                {/* <hr className="my-3" /> */}
                                {/* <div className='ms-auto'>
                                    <Link style={{ textDecoration: 'none' }} to='/forget'>Forgot Password</Link>
                                </div> */}
                            </Card.Body>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Container>
        // </div>
    )
}

export default Login