import React from 'react'
import { Container, Row, Col, Image, Button, Card } from 'react-bootstrap'
import logo from '../icon/send.gif'

function Forget() {

    return (
        <>
            <div style={{ backgroundColor: '#E7E9EB', height: '100vh' }}>
                <Container fluid >
                    <Row className='d-flex justify-content-center align-items-center h-100'>
                        <Col col='12'>
                            <Card className='bg-white my-5 mx-auto' style={{ borderRadius: '.5rem', maxWidth: '500px' }}>
                                <Card.Body className='p-5 w-100 d-flex flex-column '>
                                    <Image src={logo} fluid style={{ maxWidth: '500px', width: '100%' }} />
                                    <p>Req will be send to the administrator. Please contact </p>
                                    <Button variant="outline-secondary" type='submit' size='lg'>
                                        Send Request
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
export default Forget