//const api = `http://172.16.16.75:551/api`

//const api = `http://112.196.45.205:551/api`
const api = `https://supplier.gnaent.in:551/api`



const urls = {
    // acceptanceOfPoApi: `http://localhost:5000/deliverdata?VendorCode=`,
    // acceptancepolistget: `http://localhost:5000/deliverdata`,
    // dispatchReportApi: `http://localhost:8050/data`,
    // submit_data_Api: `http://localhost:5000/deliverdata`,
    // user_Data_Api: `http://localhost:8080/allUsers?userId=`,
    // gererateASNApi: `http://localhost:8040/Data?VendorCode=`,



    loginApi: `${api}/v1/vendor/login`,
    changePasswordApi: `${api}/v1/vendor/password`,
    generateASNApi: `${api}/v1/vendor/gasn`,
    asnQRReportApi: `${api}/v1/vendor/qr`,
    acceptancepolistpost: `${api}/v1/vendor/po`,
    acceptancepolistget: `${api}/v1/vendor/gpo`,
    registerAdmin: `${api}/v1/vendor/register`,
    dashboardTile: `${api}/v1/vendor/tile`,
    dashboardGridTable: `${api}/v1/vendor/grid`,
    GenerateASNSubmitReport: `${api}/v1/vendor/asn`,
    acceptanceOfPoReportApi:`${api}/v1/vendor/rpo`,
    disptachASNRepostImagesApi:`${api}/v1/vendor/path`,
    vendorRatingApi:`${api}/v1/vendor/rrating`,
    vendorRatingModification:`${api}/v1/vendor/mrating`,
    vendorRatingModificationReport:`${api}/v1/vendor/rmr`,
    goodsReceiptReportApi:`${api}/v1/vendor/rgrn`,
    generateASNReportApi:`${api}/v1/vendor/rasn`,
    runScheduleApi:`${api}/v1/vendor/schrun`
}

export default urls;