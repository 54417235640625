import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';

function CustomSpinner() {
    const [count, setCount] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            setCount((previous) => --previous);
        }, 100);

        if (count === 0) {
            clearInterval(interval);
            navigate(`/dashboard`);
        }

        return () => clearInterval(interval);
    }, [count, navigate]);

    return (
        <Container fluid className="d-flex align-items-center justify-content-center vh-100">
            <Row className="text-center">
                <Col>
                    <h4>Redirecting in {count}</h4>
                    <Spinner animation="border" />
                </Col>
            </Row>
        </Container>
    );
}

export default CustomSpinner;