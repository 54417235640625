import React, { useState } from 'react';
import { Card, Toast, ToastContainer } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
// import JasonData from "../../utils/form.json"
import axios from 'axios';
import { useAuth } from '../../components/dashboard/context/AuthContext';
import urls from '../../url';



function DeliveryPageForm({ griddata, handleSubmitAllData, counter }) {
    const [showToast, setShowToast] = useState(false);
    // error handle message this usestste
    const [auth] = useAuth();
    const parseUserData = JSON.parse(auth?.user)
    const [error, seterror] = useState({})
    const [num, setnum] = useState('')
    //mangage all input data this usestste
    const [data, setdata] = useState({
        DispatchNo: "",
        DispatchDate: "",
        DilveryDate: "",
        FromAddress: "",
        ToAddress: "",
        InDays: "",
        PackingList: "",
        VehicleNo: "",
        TransporterName: "",
        MobileNO: ''
        // ASN: ''
    })
    const [images, setImages] = useState([]);


    // for suggegtion in input name
    const handleChange = (e) => {
        // target value of transpoterName
        const { name, value } = e.target;
        setdata((pre) => ({
            ...pre,
            [name]: value
        }))

    }
    /// set suggetion in  input

    // handle value of every set in input
    const handleChangeData = (e) => {
        const { name, value, type } = e.target;
        //  console.log(e.target.files[0]);
        if (type === "date") {
            // Calculate the difference in days
            if (name === 'DispatchDate') {

                setdata({
                    ...data,
                    [name]: value,
                });
            } else {
                const dispatchDate = new Date(data.DispatchDate);
                const deliveryDate = new Date(value);
                const timeDifference = deliveryDate.getTime() - dispatchDate.getTime();
                const inDays = Math.ceil(timeDifference / (1000 * 3600 * 24));

                setdata({
                    ...data,
                    [name]: value,
                    InDays: inDays
                });
            }

        } else if (type === "file") {
            // For file input
            // const newImages = [];
            // for (let i = 0; i < e.target.files.length; i++) {
            //     const file = e.target.files[i];

            //     const fileInfo = {
            //         name: file.name,
            //         lastModified: file.lastModified,
            //         size: file.size,
            //         type: file.type
            //         // Add other properties as needed
            //     };
            //     newImages.push(fileInfo);
            // }

            setImages(e.target.files);
        } else {
            // For other inputs
            setdata({
                ...data,
                [name]: value
            });
        }
        // if (type === "date") {
        //     // Calculate the difference in days
        //     const dispatchDate = new Date(data.DispatchDate);
        //     const deliveryDate = new Date(value);
        //     const timeDifference = deliveryDate.getTime() - dispatchDate.getTime();
        //     const inDays = Math.ceil(timeDifference / (1000 * 3600 * 24));
        //     console.log(inDays);
        //     setdata({
        //         ...data,
        //         InDays: inDays,
        //     });
        // }
        // if (type === "file") {
        //     // For file input

        //     const newImages = [];
        //     for (let i = 0; i < e.target.files.length; i++) {
        //         const file = e.target.files[i];
        //         const fileInfo = {
        //             name: file.name,
        //             lastModified: file.lastModified,
        //             size: file.size,
        //             // Add other properties as needed
        //         };
        //         newImages.push(fileInfo);
        //     }

        //     setdata({
        //         ...data,
        //         image: newImages
        //     });
        // } else {
        //     // For other inputs
        //     setdata({
        //         ...data,
        //         [name]: value
        //     });
        // }
    }


    const handlesubmit = async (e) => {
        e.preventDefault();
        // const formdata = new FormData();
        const newFormDataArray = griddata.map((item) => ({
            ...item,
        }));

        // Create an array of image objects
        // const imageArray = images.map((imageInfo) => ({ ...imageInfo }));
        const formData = new FormData();
        // const formImageData = new FormData();
        // Combine everything into the final submittedData object
        const submitGridFormData = {
            Data: {
                griddata:newFormDataArray,
                newformdata: { VendorCode: parseUserData?._id, DispatchNo: data.DispatchNo, ExpectedDeliveryDate: data.DilveryDate, FromAddress: data.FromAddress, ToAddress: data.ToAddress, InDays: data.InDays, PackingList: data.PackingList, VehicleNo: data.VehicleNo, TransporterName: data.TransporterName, MobileNo: data.MobileNO }
            }
        };
        // const object = {'data':submitGridFormData,"user_files": images.map((imageInfo) => ({ ...imageInfo })),}
    //    console.log(object);
        // image: imageArray,
        formData.append('data',JSON.stringify(submitGridFormData));
        // images.forEach((image, index) => {
        //     formData.append(`user_files`, image.file);
        // });
        for (let index = 0; index < images.length; index++) {
            formData.append('user_files',images[index])
            
        }
        // formData.append('user_files',JSON.stringify(imageArray))
        // formData.append('Data',submitGridFormData);
        // formData.append('user_files',imageArray)
     console.log(formData);
        // console.log(submitGridFormData);
        const res = await axios.post(`${urls.GenerateASNSubmitReport}`,formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Authorization": auth?.token
            }
        })
        setnum(res.data.description);
        console.log(res);
        // console.log(submittedData);

        // Log or send the submittedData as needed
        // Append data to FormData
        // if (data.image && data.image.length > 0) {
        //     for (let i = 0; i < data.image.length; i++) {
        //         // console.log(data.image[i].name);
        //         formdata.append('image', data.image[i].name);
        //     }
        // }
        // const arr = griddata.map((item) => ({ ...item, newFormData: data }));
        // console.log(arr);
        // axios.post(`http://localhost:8050/data`, JSON.stringify(submittedData)).then(response => {
        //     console.log('Data posted successfully:', response.data);
        // }).catch(error => {
        //     console.error('Error posting data:', error.message);
        // });
        // console.log(data);
        setdata({
            DispatchNo: "",
            DispatchDate: "",
            DilveryDate: "",
            FromAddress: "",
            ToAddress: "",
            InDays: "",
            PackingList: "",
            VehicleNo: "",
            TransporterName: "",
            MobileNO: "",
        });
        // setImages([])
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 5000);
    }

    const handleCancel = () => {
        seterror({});
        setdata({
            DispatchNo: "",
            DispatchDate: "",
            DilveryDate: "",
            FromAddress: "",
            ToAddress: "",
            InDays: "",
            PackingList: "",
            VehicleNo: "",
            TransporterName: "",
            MobileNO: "",
        });
        // setImages([])
    }
    return (
        <Card style={{ margin: "8px", padding: "5px" }}>
            <Card.Body>
                <Form onSubmit={handlesubmit}>
                    <Row className="mb-3">
                        <Form.Group as={Col} sm={6} controlId="formGridEmail">
                            <Form.Label style={{ fontSize: '14px' }}>Dispatch No</Form.Label>
                            <Form.Control
                                name='DispatchNo'
                                value={data.DispatchNo}
                                onChange={handleChangeData}
                                type="text"
                                size='sm'
                                placeholder="Enter dispatch number"
                                style={{ borderColor: error.DispatchNo ? "red" : "" }}
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={6} controlId="formGridDate">
                            <Form.Label style={{ fontSize: '14px' }}>Dispatch Date</Form.Label>
                            <Form.Control
                                name='DispatchDate'
                                value={data.DispatchDate}
                                onChange={handleChangeData}
                                type="date"
                                size='sm'
                                style={{ borderColor: error.DispatchDate ? "red" : "" }}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} sm={6} className="mb-2" controlId="formGridAddress1">
                            <Form.Label style={{ fontSize: '14px' }}> From Address</Form.Label>
                            <Form.Control
                                name='FromAddress'
                                as="textarea"
                                placeholder="From Address"
                                value={data.FromAddress}
                                onChange={handleChangeData}
                                size='sm'
                                style={{ borderColor: error.FromAddress ? "red" : "" }}
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={6} className="mb-2" controlId="formGridAddress2">
                            <Form.Label style={{ fontSize: '14px' }}>To Addreess</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="To Address"
                                name='ToAddress'
                                value={data.ToAddress}
                                onChange={handleChangeData}
                                size='sm'
                                style={{ borderColor: error.ToAddress ? "red" : "" }}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} sm={6} controlId="formGridCity">
                            <Form.Label style={{ fontSize: '14px' }}>Expected Delivery Date</Form.Label>
                            <Form.Control type='date'
                                value={data.DilveryDate}
                                name='DilveryDate'
                                onChange={handleChangeData}
                                size='sm'
                                style={{ borderColor: error.DispatchDate ? "red" : "" }}
                            />
                        </Form.Group>
                        <Form.Group as={Col} sm={6} controlId="formGridState">
                            <Form.Label style={{ fontSize: '14px' }}>In Days</Form.Label>
                            <Form.Control type='text'
                                disabled
                                value={data.InDays}
                                name='InDays'
                                // onChange={handleChangeData}
                                placeholder='Enter Days'
                                size='sm'
                                style={{ borderColor: error.InDays ? "red" : "" }}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} sm={6} className="mb-2" controlId="formGridAddress1">
                            <Form.Label style={{ fontSize: '14px' }}>Packing List</Form.Label>
                            <Form.Control
                                placeholder="Packing List"
                                value={data.PackingList}
                                name='PackingList'
                                onChange={handleChangeData}
                                size='sm'
                                style={{ borderColor: error.PackingList ? "red" : "" }}
                            />

                        </Form.Group>
                        <Form.Group as={Col} sm={6} className="mb-2" controlId="formGridAddress2">
                            <Form.Label style={{ fontSize: '14px' }}> Vehicle No </Form.Label>
                            <Form.Control
                                placeholder="Vechile No"
                                value={data.VehicleNo}
                                name='VehicleNo'
                                onChange={handleChangeData}
                                size='sm'
                                style={{ borderColor: error.VehicleNo ? "red" : "" }}

                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} sm={6} className="mb-2" controlId="formGridAddress1">
                            <Form.Label style={{ fontSize: '14px' }}>Transporter Name</Form.Label>
                            <Form.Control
                                placeholder="Enter Transporter Name"
                                value={data.TransporterName}
                                name='TransporterName'
                                onChange={(e) => handleChange(e)}
                                size='sm'
                                style={{ borderColor: error.TransporterName ? "red" : "" }}
                            />

                        </Form.Group>
                        <Form.Group as={Col} sm={6} className="mb-2" controlId="formGridAddress2">
                            <Form.Label style={{ fontSize: '14px' }}>Mobile Number</Form.Label>
                            <Form.Control
                                placeholder="Enter Mobile Number"
                                name='MobileNO'
                                value={data.MobileNO}
                                onChange={handleChangeData}
                                size='sm'
                                style={{ borderColor: error.MobileNO ? "red" : "" }}
                            />
                        </Form.Group>
                    </Row>
                    <Form.Group controlId="formFileMultiple" className="mb-2">
                        <Form.Label style={{ fontSize: '14px' }}>Multiple files input example</Form.Label>
                        <Form.Control accept=".jpg,.jpeg,.png" type="file" size='sm' multiple onChange={handleChangeData} />
                    </Form.Group>
                    <span className='d-inline-block'>
                        {Object.values(error).map((errorMsg, index) => (
                            errorMsg && (
                                <div key={index} style={{ display: "inline-block" }}>
                                    <p style={{ color: 'red', margin: "20px" }}>*{errorMsg}</p>
                                </div>
                            )
                        ))}
                    </span>
                    <div className='d-flex ms-auto'>

                        <Button className='ms-auto' variant="danger" size='sm' onClick={handleCancel}>Cancel</Button>
                        <Button className='ms-2' onClick={handleSubmitAllData} size='sm' variant="secondary" type="submit">
                            Submit
                        </Button>
                    </div>
                </Form>
                <ToastContainer position="bottom-center">
                    <Toast onClose={() => setShowToast(false)} show={showToast} delay={5000} autohide>
                        <Toast.Header className='text-white bg-success'>
                            <strong className="me-auto">{num}</strong>
                        </Toast.Header>
                    </Toast>
                </ToastContainer>
                {/* <Spinner animation="border" role="status">
                    <span className="visually-hidden" style={{position:'absolute',top:'50vh'}}>Loading...</span>
                </Spinner> */}
            </Card.Body>
        </Card>
    );
}


export default DeliveryPageForm;