import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { Container } from 'reactstrap';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../components/dashboard/context/AuthContext';
import useAxiosPost from '../../hooks/useAxiosPost';
import urls from '../../url';
import { ChnagePasswordSchema } from '../../pages/ChnagePasswordScehma';
import { useFormik } from 'formik'

function ChangePassword() {
    const [auth, setAuth] = useAuth()
    const { data, error, loading, execute } = useAxiosPost();
    const [loginError, setLoginError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    // const [show, setShow] = useState(true);
    const navigate = useNavigate();
   const parseData = JSON.parse(auth?.user)
    // 23-03-2024 BRIJESH SAINI
    useEffect(() => {
        if (data.message === 'Password Changed Successful!') {
            // setShow(true)
            setAuth((pre) => ({
                ...pre,
                user: null,
                token: '',
                admin: null,
            }))
            navigate('/')
        }
    }, [data,navigate,setAuth]);

    useEffect(() => {
        if (error?.data?.error === 'Old Password wrong !') {
            setLoginError("Old password is incorrect")
        }
    }, [error]);
    // CODE END

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }
    // 23-03-2024 BRIJESH SAINI
    const changePasswordInitialvalue = {
        password: "",
        Cpassword: "",
        Cfpassword: ""
    };
    const { handleBlur, handleChange, handleSubmit, values, touched, errors } = useFormik({
        initialValues: changePasswordInitialvalue,
        validationSchema: ChnagePasswordSchema,
        onSubmit: async (values) => {
            const Data = { Data: { VendorCode: parseData?._id, VendorSaltPassword: values.password, VendorNewPassword: values.Cpassword, VendorConfirmPassword: values.Cfpassword } }
            console.log(Data);
            await execute(`${urls.changePasswordApi}`, Data, {
                headers: {
                    "Authorization": auth?.token
                }
            })
        }

    })
    // CODE END
    return (
        <>
            {/* <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
                <Toast.Header>
                </Toast.Header>
                <Toast.Body>Password Changed Successful!</Toast.Body>
            </Toast> */}
            <Container fluid style={{ height: '100vh', backgroundColor: '#E7E9EB' }}>
                <Row className='d-flex justify-content-center align-items-center h-100'>
                    <Col col='12'>
                        <Card className='bg-white my-5 mx-auto shadow' style={{ borderRadius: '.5rem', maxWidth: '400px' }}>
                            <Form style={{ marginTop: "10px" }} onSubmit={handleSubmit}>
                                <Card.Body className='p-4 w-100 d-flex flex-column '>
                                    <h6 style={{ maxWidth: '450px', margin: 'auto', width: '100%' }} className="mb-2 text-center  bg-secondary-light text-white p-2">Change Password</h6>
                                    <Form.Group className="mb-32" controlId="validationFormik01">
                                        <Form.Label >Old Password</Form.Label>
                                        <div className='input-group'>
                                            <Form.Control
                                                onChange={handleChange} onBlur={handleBlur}
                                                value={values.password}
                                                name='password'
                                                size='sm'
                                                type={showPassword ? 'text' : 'password'}
                                                className={` ${touched.password && (errors.password || loginError) ? 'is-invalid' : ''}`}
                                                isValid={(touched.password && !errors.password) || loginError}
                                            />
                                            <Button size='sm' variant='outline-secondary' onClick={togglePasswordVisibility}>
                                                {showPassword ? <i class="bi bi-eye"></i> : <i class="bi bi-eye-slash"></i>}
                                            </Button>
                                            <Form.Control.Feedback type="invalid">
                                                {(touched.password && errors.password) || loginError}
                                            </Form.Control.Feedback>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="mb-2" controlId="formBasicPassword">
                                        <Form.Label >New Password</Form.Label>
                                        <div className='input-group'>
                                            <Form.Control
                                                onChange={handleChange} onBlur={handleBlur}
                                                value={values.Cpassword}
                                                name='Cpassword'
                                                size='sm'
                                                type={showNewPassword ? 'text' : 'password'}
                                                isValid={(touched.Cpassword && !errors.Cpassword) || loginError}
                                                className={`${touched.Cpassword && (errors.Cpassword || loginError) ? 'is-invalid' : ''}`}
                                            />
                                            <Button size='sm' variant='outline-secondary' onClick={toggleNewPasswordVisibility}>
                                                {showNewPassword ? <i class="bi bi-eye"></i> : <i class="bi bi-eye-slash"></i>}
                                            </Button>
                                            <Form.Control.Feedback type="invalid">
                                                {touched.Cpassword && errors.Cpassword}
                                            </Form.Control.Feedback>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
                                        <Form.Label>Confirm New Password</Form.Label>
                                        <div className='input-group'>
                                            <Form.Control
                                                size='sm'
                                                onChange={handleChange} onBlur={handleBlur}
                                                value={values.Cfpassword}
                                                name='Cfpassword'
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                className={`${touched.Cfpassword && (errors.Cfpassword || loginError) ? 'is-invalid' : ''}`}
                                                isValid={(touched.Cfpassword && !errors.Cfpassword) || loginError}
                                            />
                                            <Button size='sm' variant='outline-secondary' onClick={toggleConfirmPasswordVisibility}>
                                                {showConfirmPassword ? <i class="bi bi-eye"></i> : <i class="bi bi-eye-slash"></i>}
                                            </Button>
                                            <Form.Control.Feedback type="invalid">
                                                {touched.Cfpassword && errors.Cfpassword}
                                            </Form.Control.Feedback>
                                        </div>
                                    </Form.Group>
                                    <Button size='sm' disabled={loading ? true : false} variant="secondary" type="submit">
                                        {loading ? <Spinner size='sm' animation="border" /> : 'Change Password'}
                                    </Button>
                                </Card.Body >
                            </Form>
                        </Card>
                    </Col>
                </Row>

            </Container>
        </>

    );
}

export default ChangePassword;
