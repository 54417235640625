import { Table, Dropdown, DropdownButton, Card, CardBody, CardTitle} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import axios from 'axios';

const User = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://localhost:8080/allUsers');
        // console.log('Data received from API:', response.data);
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleBlockUser = async (index) => {
    // Confirming user action


    try {
      const response = await axios.patch(`http://localhost:8080/allUsers/${index}`, { status: "blocked" });
      console.log('User blocked:', response.data);
      // Custom alert message for user blocked
      const confirmBlock = window.confirm('Are you sure you want to block this user?');

      if (!confirmBlock) {
        return; // If user cancels, exit the function
      }
      // Assuming you want to update the UI after blocking the user
      // You may refetch data or update the local state accordingly
      const updatedUsers = users.map(user => {
        if (user.id === index) {
          return { ...user, status: "blocked" };
        }
        return user;
      });
      setUsers(updatedUsers);
    } catch (error) {
      console.error('Error blocking user:', error);
    }
  };


  const handleUnblockUser = async (index) => {
    try {
      // const response = await axios.patch(`http://localhost:8080/allUsers/${index}`, { status: "active" });
      // console.log('User unblocked:', response.data);
      // Alert message for user unblocked
      const confirmBlock = window.confirm('Are you sure you want to unblock this user?');

      if (!confirmBlock) {
        return; // If user cancels, exit the function
      }

      // You may refetch data or update the local state accordingly
      const updatedUsers = users.map(user => {
        if (user.id === index) {
          return { ...user, status: "active" };
        }
        return user;
      });
      setUsers(updatedUsers);
    } catch (error) {
      console.error('Error unblocking user:', error);
    }
  };

  return (
    <>
      <Card className="shadow">
        <CardTitle className="border-bottom d-flex justify-content-between p-2 mb-0 bg-secondary-light text-white">
          <h4>User Listing</h4>
        </CardTitle>
        <CardBody >
          <Table className="no-wrap mt-3 align-middle" responsive borderless>
            <thead>
              <tr>
                <th>Team Lead</th>
                <th>Project</th>
                <th>Status</th>
                <th>Weeks</th>
                <th>Budget</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((userData, index) => (
                <tr key={index}>
                  <td>
                    {userData.name}
                    <br />
                    <span>{userData.email}</span>
                  </td>
                  <td>{userData.project}</td>
                  <td>
                    <span className={`p-2 rounded-circle d-inline-block ms-3 ${userData.status === 'active' ? 'bg-success' : 'bg-danger'}`}></span>
                  </td>
                  <td>{userData.weeks}</td>
                  <td>{userData.budget}</td>
                  <td>
                    {userData.status === 'active' ? (
                      <DropdownButton title="" id="dropdown-basic-button" variant="light" className="border-0 bg-white">
                        <Dropdown.Item onClick={() => handleBlockUser(userData.id)}>Block User</Dropdown.Item>
                      </DropdownButton>
                    ) : (
                      <DropdownButton title="" id="dropdown-basic-button" variant="light" className="border-0 bg-white">
                        <Dropdown.Item onClick={() => handleUnblockUser(userData.id)}>Unblock User</Dropdown.Item>
                      </DropdownButton>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );
};

export default User;