import React from 'react';
import { Container, Row, Col, Card, Form, Button, FormGroup, Image } from 'react-bootstrap';
// import Spinner from 'react-bootstrap/Spinner';
import logo from '../assets/images/logos/gna-logo.gif'
import { useFormik } from 'formik'
import { ResetPasswordSchema } from './ResetPasswordSchema';
function ResetPassword() {
    const ResetPasswordInitialvalue = {
        userId: '',
        password: ''
    };
    const { handleBlur, handleChange, handleSubmit, values, touched, errors ,resetForm} = useFormik({
        initialValues: ResetPasswordInitialvalue,
        validationSchema: ResetPasswordSchema,
        onSubmit: async (values) => {
            try {
                const Data = { Data: { VendorCode: values.userId, Password: values.password } }
                console.log(Data);
                //     axios.post('', Data, {
                //         headers: {
                //             "Authorization": auth?.token
                //         }
                //     })
resetForm()
            } catch (error) {

            }
        }
    })
    return (
        <>
            <Container fluid style={{ height: '100vh', backgroundColor: '#E7E9EB' }}>
                <Row className='d-flex justify-content-center align-items-center h-100'>
                    <Col col='12'>
                        <Card className='bg-white my-5 mx-auto shadow' style={{ borderRadius: '.5rem', maxWidth: '400px' }}>
                            <Form noValidate onSubmit={handleSubmit}>
                                <Card.Body className='p-5 w-100 d-flex flex-column '>
                                    <Image src={logo} fluid alt="Logo" style={{ height: '100px', maxWidth: '400px', margin: 'auto', width: '100%' }} className="mb-3" />
                                    <FormGroup controlId="validationFormik01">
                                        <Form.Label ><i class="bi bi-person"></i>  User Id</Form.Label>
                                        <Form.Control
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.userId}
                                            maxLength='10'
                                            type="text"
                                            name='userId'
                                            className={`mb-2 ${touched.userId && (errors.userId) ? 'is-invalid' : ''}`}
                                            size='sm'
                                            isValid={(touched.userId && !errors.userId)}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {touched.userId && errors.userId}
                                        </Form.Control.Feedback>
                                    </FormGroup>
                                    <Form.Label ><i class="bi bi-key"></i> Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                        name='password'
                                        // className='mb-2'
                                        className={`mb-2 ${touched.password && (errors.password) ? 'is-invalid' : ''}`}
                                        size='sm'
                                        isValid={(touched.password && !errors.password)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {(touched.password && errors.password)}
                                    </Form.Control.Feedback>
                                    {/* </FloatingLabel> */}
                                    {/* <Row className="mb-3">
                                    {loginBlockError && (
                                        <div className="alert alert-danger mt-2" role="alert">
                                            {loginBlockError}
                                        </div>
                                    )}
                                </Row> */}
                                    <Button variant="secondary" type='submit' size='sm'>
                                        {/* {loading ? <Spinner size='sm' animation="border" /> : 'Reset Password'} */}
                                        submit
                                    </Button>
                                    <hr className="my-3" />
                                </Card.Body>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ResetPassword