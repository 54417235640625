import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Table, Pagination, Form, InputGroup, Button, Card, CardBody, CardTitle, Spinner } from 'react-bootstrap';
import 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import useAxios from '../../hooks/useAxios';
import urls from '../../url';
import Loader from '../../layouts/loader/Loader';
import { useAuth } from '../../components/dashboard/context/AuthContext';

function AcceptedOrderList() {
    const [auth] = useAuth();

    const [dateRange, setDateRange] = useState({
        fdate: "",
        edate: ""
    })
    // const { data, error, loading, execute } = useAxios()
    const [tableData, setTableData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // 08-04-2024
    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setDateRange((prev) => ({
            ...prev,
            [name]: value
        }));
    };
    // useEffect(() => {
    //     if (data) {
    //         const startDate = new Date(dateRange.fdate);
    //         const endDate = new Date(dateRange.edate);
    //         let filteredData = data?.filter((val) => {
    //             const dispatchDate = new Date(val.DispatchDate);
    //             return dispatchDate >= startDate && dispatchDate <= endDate;
    //         });
    //         setTableData(filteredData);
    //     }
    // }, [data])
    // const handleFetchData = async () => {
    //     const startDate = new Date(dateRange.fdate);
    //     const endDate = new Date(dateRange.edate);
    //     const vendorcode = auth.user
    //     let obj = { data: { vendorcode: vendorcode, startDate: startDate, endDate: endDate } }
    //     console.log(JSON.stringify(obj));
    //     await execute(urls.acceptancepolistget);
    // };
    // console.log(tableData);
    const handleFetchData = async () => {
        const startDate = new Date(dateRange.fdate).toISOString().split('T')[0]
        const endDate = new Date(dateRange.edate).toISOString().split('T')[0]
        const vendorid = JSON.parse(auth?.user);
        const vendorcode = vendorid?.vType === 'VENDOR' ? vendorid?._id : ''
        // console.log(vendorcode);
        setIsLoading(true)
        const obj = {
            Data: {
                VendorCode: vendorcode,
                DateFrom: startDate,
                DateTo: endDate
            }
        };
        console.log(obj);
        try {
            const response = await axios.post(urls.acceptanceOfPoReportApi,
                obj,
                {
                    headers: {
                        "Authorization": auth?.token
                    }
                }
            );
   
            const responseData = response.data;
            setIsLoading(false)
            // console.log(responseData);
            // console.log(responseData.Data[0].Data)
            if (responseData.Data[0].Data === 'NO RECORD FOUND') {
                setTableData(responseData.Data[0].Data)
            } else {
                // console.log(responseData.Data[0].Data)
                const filterDataByVendor_Admin = responseData.Data[0].Data.filter((val) => val.Vendor === vendorcode);
                // console.log(filterDataByVendor_Admin);
                if (filterDataByVendor_Admin.length === 0 && vendorid?.vType === 'VENDOR') {
                    setTableData('NO RECORD FOUND') // 08-04-2024
                } else {
                    // console.log( responseData.Data[0].Data);
                    setTableData(vendorid?.vType === 'VENDOR' ? filterDataByVendor_Admin : responseData.Data[0].Data)
                }
                // console.log(filterDataByVendor_Admin);
                // console.log(filterDataByVendor_Admin);

            }


        } catch (error) {
            console.error(error);
        }
    };

    // SEARCH FILTER 
    const handleFilter = (e) => {
        const filteredData = tableData.filter(item => {
            console.log(item);
            return (
                item.PONumber.includes(e.target.value)

            );
        }
        );
        setFilterData(filteredData);
        // setCurrentPage(1);
        //CHECK => NO FILTERDATA =>SHOW MSG =>NO DATA FOUND

    };

    // console.log(tableData);
    return (
        <>
            <Card className='shadow'>
                <CardTitle className="border-bottom d-flex justify-content-between p-2 mb-0 bg-secondary-light text-white">
                    <h5>AcceptedOrderList  Details</h5>
                </CardTitle>
                <CardBody>
                    <Container>
                        <Row>
                            <Col md={6} lg={4} xl={3}>
                                <InputGroup size='sm' className="mb-2">
                                    <InputGroup.Text>From Date</InputGroup.Text>
                                    <Form.Control name='fdate' onChange={handleDateChange} type='date' aria-label="fdate" />
                                </InputGroup>
                            </Col>
                            <Col md={5} lg={4} xl={3}>
                                <InputGroup size='sm' className="mb-2">
                                    <InputGroup.Text>End Date</InputGroup.Text>
                                    <Form.Control name='edate' onChange={handleDateChange} type='date' aria-label="edate" />
                                </InputGroup>
                            </Col>
                            <Col md={2} lg={1} xl={2} className='mb-2'>
                                <Button size='sm' disabled={isLoading?true:false} onClick={handleFetchData} variant="secondary">
                                  
                                    {isLoading? <Spinner size='sm' animation='border' /> :'  Retrieve'}
                                    </Button>
                            </Col>
                            <Col role='group' className='mb-2'>
                                {/* <Form onSubmit={handleSubmit}> */}
                                <CSVLink style={{ fontSize: '13px' }} data={tableData} filename='AcceptedOrderList' className='btn btn-success'>
                                    Export to Excel
                                </CSVLink>
                            </Col>
                            <Col>
                                <Form.Control
                                    size='sm'
                                    onChange={handleFilter}
                                    placeholder='Search here...'
                                    type='text'
                                    aria-label='search'
                                    aria-describedby='basic-addon1'
                                />
                            </Col>
                        </Row>
                        {tableData.length > 0 ?
                            <Row className='mt-2' style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                                <Col lg={12}>

                                    < >
                                        {tableData !== 'NO RECORD FOUND' &&
                                            <>
                                                <Table bordered hover id='my-table' className='text-center'>
                                                    <thead style={{
                                                        position: "sticky",
                                                        top: "0"
                                                    }}>
                                                        <tr>

                                                            <th>Vendor</th>
                                                            <th>PONumber</th>
                                                            <th>POITEM</th>
                                                            <th>PLANT</th>

                                                            <th>OrderQuantity</th>
                                                            <th>OrderUnit</th>


                                                            <th>DocumentDate</th>
                                                            <th>AcceptedQty</th>
                                                            <th>PurchOrganization</th>
                                                            <th>APO</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody >
                                                        {(filterData.length > 0 ? filterData : tableData).map((val, index) => (
                                                            <tr key={index}>
                                                                <td>{val.Vendor}</td>
                                                                <td>{val.PONumber}</td>
                                                                <td>{val.POITEM}</td>
                                                                <td>{val.PLANT}</td>
                                                                <td>{val.OrderQuantity}</td>
                                                                <td>{val.OrderUnit}</td>
                                                                <td>{val.DocumentDate}</td>
                                                                <td>{val.AcceptedQty}</td>
                                                                <td>{val.PurchOrganization}</td>
                                                                <td>{val.APO}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table >
                                            </>

                                        }

                                    </>
                                    {tableData === 'NO RECORD FOUND' && 'NO RECORD FOUND'}
                                </Col>
                            </Row>
                            : null}



                    </Container>
                </CardBody>
            </Card>
        </>
    )
}
export default AcceptedOrderList;

