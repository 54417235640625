
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login.js";
import Forget from "./pages/Forget.js";
import FullLayout from "./layouts/FullLayout.js"
import VendorRating from "./views/ui/VendorRating.jsx";
import VendorModification from "./views/ui/VendorRatingModification.jsx";
import GoodsReceiptReport from "./views/ui/GoodsReceiptReport.jsx";
import LoginLout from "./components/dashboard/routes/LoginLogOutProtectedRoute.js";

//   import FullLayout from "./layouts/FullLayout.js";
import Starter from "./views/Starter.js";
import About from "./views/About.js";
import Cards from "./views/ui/Cards";
//   import DispatchForm from "./views/ui/DispatchForm.jsx";
import DispatchForm from "./views/generateAsn/GenerateAsn.js";
import QrCode from "./views/ui/Qrcode.jsx";
import DispatchReport from "./views/ui/DispatchReport.jsx";
import AcceptanceOfPo from "./views/ui/AcceptanceOfPo.jsx";
//   import AcceptanceOfPo from "./acceptanceOfPo/AcceptanceOfPo.js";
import TableData from "./views/ui/TableData.jsx";
import TableData2 from "./views/ui/Tabledata2.jsx";
import PrivateRoute from "./components/dashboard/routes/PrivateRoute.js";
import ChangePassword from "./views/ui/ChangePassword.jsx";
import UserData from "./views/ui/User.jsx";
import MyAccountDetails from "./views/ui/MyAccountDetails.jsx";
import AcceptedOrderList from "./views/ui/AcceptedOrderList.jsx";
import SignUp from "./pages/SignUp.jsx";
import Goods from "./pages/Goods.jsx";
import Purchase from "./pages/Purchase.jsx";
import Dispatch from "../src/DispatchReport.jsx";
import ResetPassword from "./pages/ResetPassword.jsx";
import VendorRatingReport from "./views/ui/VendorRatingReport.jsx";
import RunSchedule from "./views/ui/RunSchedule.jsx";
const App = () => {
  return (
    <Routes>
      {/* <Route element={<LoginLout />}> */}
      <Route element={<LoginLout />} >
        <Route path="/forget" element={<Forget />} />
        <Route path="/" element={<Login />} />
      </Route>

      {/* </Route> */}
      {/* <Route path="/forget" element={<Forget />} />
      <Route path="/" element={<Login />} /> */}



      <Route element={<PrivateRoute />} >
        <Route path="/changepassword" element={<ChangePassword />} />
        <Route path="/dashboard" element={<FullLayout />}>

          <Route index element={<Starter />} />
          <Route path="starter" element={<Starter />} />
          <Route path="starter/tabledata" element={<TableData />} />
          <Route path="starter/tabledata2" element={<TableData2 />} />
          <Route path="dispatchreport" element={<DispatchReport />} />
          <Route path="vendor-rating" element={<VendorRating />} />
          <Route path="vendor-rating-report" element={<VendorRatingReport />} />
          <Route path="vendor-modification" element={<VendorModification />} />
          <Route path="goods-receipt-report" element={<GoodsReceiptReport />} />
          <Route path="about" element={<About />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="goods" element={<Goods />} />
          <Route path="dispatchs" element={<Dispatch />} />
          <Route path="purchase" element={<Purchase />} />
          <Route path="cards" element={<Cards />} />
          <Route path="dispatch" element={<DispatchForm />} />
          <Route path="acceptanceofpo" element={<AcceptanceOfPo />} />
          <Route path="qrcode" element={<QrCode />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="user" element={<UserData />} />
          <Route path="run-schedule" element={<RunSchedule/>} />
          <Route path="my-account" element={<MyAccountDetails />} />
          <Route path="acceptedorderlist" element={<AcceptedOrderList />} />
          <Route path="signup" element={<SignUp />} />
        </Route>
      </Route>
    </Routes>
  )
};

export default App;
