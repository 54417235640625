import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
// import useAxios from '../hooks/useAxios';
// import urls from '../url';
import { useAuth } from './dashboard/context/AuthContext';
// import Loader from '../layouts/loader/Loader';
import profileAvtar from '../assets/images/users/user4.jpg'

function ProfileCard() {
  const [auth] = useAuth()
  // const { data,  loading } = useAxios(`${urls.user_Data_Api}${auth.user}`);
 const parseUserData = JSON.parse(auth?.user)
 console.log(parseUserData);
  return (
    
        <>
          <Container className="py-5">
            <Row className="justify-content-center align-items-center">
              <Col md={6} lg={6} xl={4}>
                <Card className="shadow" style={{ borderRadius: '15px', backgroundColor: '#eee' }}>
                  <Card.Body className="p-4 text-black">
                    <div className="d-flex justify-content-center mb-4">
                      <Row>
                        <Col sm={4} className="text-center">
                          <div className="flex-shrink-0">
                            <img
                              src={profileAvtar}
                              alt=""
                              className="img-fluid rounded-circle border border-dark border-3"
                              style={{ width: '100px' }}
                            />
                          </div>
                        </Col>
                        <Col sm={8} className="text-center">
                          <h4>{parseUserData?.vName}</h4>
                          <hr className="w-50 m-auto" />
                          <div className="ms-3">
                            <div className="d-flex flex-row justify-content-center mb-2">
                              <i className="bi bi-person danger fs-5 text-success me-2"></i>
                              <p className="mb-0">{parseUserData?._id}</p>
                            </div>
                            <div className="d-flex flex-row justify-content-center mb-2">
                              <i className="bi bi-phone me-2 text-success"></i>
                              <p className="mb-0">{parseUserData?.vPhone}</p>
                            </div>
                            <div className="d-flex flex-row justify-content-center mb-2">
                              <i className="bi bi-envelope me-2 text-success"></i>
                              <p className="mb-0">{parseUserData?.vEmailAddress}</p>
                            </div>
                            <div className="d-flex flex-row justify-content-center mb-2">
                              {/* <i className="bi bi-envelope me-2 text-success"></i> */}
                              GST : -
                              <p className="mb-0">{parseUserData?.vGST}</p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="d-flex justify-content-between">
                      {/* <Button variant="secondary" size="sm" className="rounded">
                        View More
                      </Button> */}
                      <Button variant={parseUserData?.vActive === true ? 'success' : 'danger'} disabled size="sm" className="rounded-circle">
                        {parseUserData?.vActive === true ? <i className="bi bi-check2-circle"></i> : <i className="bi bi-x-circle"></i>}
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>

        </>

  )
}

export default ProfileCard