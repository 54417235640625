import React, { useState } from 'react';
import { Container, Row, Col, Table, Form, InputGroup, Button, Card, CardBody, CardTitle, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik'
import axios from 'axios';
import * as YUP from 'yup';
import { useAuth } from '../../components/dashboard/context/AuthContext';
import urls from '../../url';
import { CSVLink } from 'react-csv';


const Initialvalue = {
    VendorCode: "",
    DateFrom: '',
    DateTo: '',
};
const VendorCodeSchema = YUP.object({
    VendorCode: YUP.string().required('VendorCode Is Must'),
    DateFrom: YUP.string().required('Select Finanical Year Start Date'),
    DateTo: YUP.string().required('Select Finanical Year End Date')
})
function VendorRatingReport() {
    const [auth] = useAuth()
    const [loading, setloading] = useState(false);
    const [vendorRatingdata, SetvendorRatingData] = useState([]);
    const [DateError, setDateError] = useState('')
    // const [showToastRecord, setshowToastRecord] = useState(false);
    
    const { handleBlur, handleChange, handleSubmit, values, touched, errors } = useFormik({
        initialValues: Initialvalue,
        validationSchema: VendorCodeSchema,
        // validationSchema: LoginSchema,
        onSubmit: async (values) => {

            try {
                if (values.DateFrom > values.DateTo) {
                    setDateError('Start date must be less than end date');

                } else {

                    setDateError('')
                    const data = {
                        "Data": [{
                            VendorCode: values.VendorCode,
                            DateFrom: values.DateFrom,
                            DateTo: values.DateTo
                        }]
                    };
                    setloading(true)
                    const response = await axios.post(urls.vendorRatingModificationReport,
                        data,
                        {
                            headers: {
                                "Authorization": auth?.token
                            }
                        }
                    );
                    if (response.data) {
                        setloading(false)
                        // setshowToastRecord(false)
                        SetvendorRatingData(response.data.Data);
                        
                        // console.log(response.data);
                        // console.log(response.data.Data);
                        // SetvendorRatingData(response.data.Data)
                    }

                }

            } catch (error) {
                console.error('Error fetching data:', error);
                SetvendorRatingData([])
                // setshowToastRecord(true)
                setloading(false)
            }
        }
    })
// console.log(vendorRatingdata);
    return (
        <>
            <Card className='shadow bg-white  mx-auto' style={{ borderRadius: '.5rem', }}>
                <CardTitle className="border-bottom d-flex justify-content-between p-1 mb-0 bg-secondary-light text-white">
                    <h5>Vendor Rating Report</h5>
                </CardTitle>
                <CardBody>
                    <Container>
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col md={4} lg={4} xl={4}>
                                    <InputGroup size='sm' className="mb-1">
                                        <InputGroup.Text>Vendor Code</InputGroup.Text>
                                        <Form.Control
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name='VendorCode'
                                            type='text'
                                            value={values.VendorCode}
                                            className={`${touched.VendorCode && errors.VendorCode ? 'is-invalid' : ''}`}
                                            isValid={touched.VendorCode && !errors.VendorCode}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {touched.VendorCode && errors.VendorCode}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                                <Col md={4} lg={4} xl={4}>
                                    <InputGroup size='sm' className="mb-1">
                                        <InputGroup.Text>From Date</InputGroup.Text>
                                        <Form.Control
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name='DateFrom'
                                            type='date'
                                            value={values.DateFrom}
                                            className={`${touched.DateFrom && (errors.DateFrom || DateError) ? 'is-invalid' : ''}`}
                                            isValid={(touched.DateFrom && !errors.DateFrom) || DateError}
                                            aria-label="fdate" />
                                        <Form.Control.Feedback type="invalid">
                                            {(touched.DateFrom && errors.DateFrom) || DateError}
                                        </Form.Control.Feedback>
                                    </InputGroup>

                                </Col>
                                <Col md={4} lg={4} xl={4}>
                                    <InputGroup size='sm' className="mb-1">
                                        <InputGroup.Text>End   Date</InputGroup.Text>
                                        <Form.Control
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.DateTo}
                                            name='DateTo'
                                            type='date'
                                            className={`${touched.DateTo && errors.DateTo ? 'is-invalid' : ''}`}
                                            isValid={touched.DateTo && !errors.DateTo}
                                            aria-label="edate" />

                                        <Form.Control.Feedback type="invalid">
                                            {touched.DateTo && errors.DateTo}
                                        </Form.Control.Feedback>
                                    </InputGroup>

                                </Col>
                                <Col md={4} lg={4} xl={4} className='mb-1'>
                                    <Button type='submit' disabled={loading ? true : false} size='sm' variant="secondary">
                                        {loading ? <Spinner size='sm' animation='border' /> : 'Retrieve'}
                                    </Button>
                                </Col>
                                <Col md={4} lg={4} xl={4}>
                                    <CSVLink style={{ fontSize: '13px' }} data={vendorRatingdata} filename='VendorRatingReport' className='btn btn-success'>
                                        Export to Excel
                                    </CSVLink>
                                </Col>
                            </Row>
                        </Form>
                        {vendorRatingdata.length > 0 ? <>
                            <Row className='mt-2'>
                                <Col lg={12}>
                                    <Table size='sm' bordered hover id='my-table' className='text-center' responsive>
                                        <thead >
                                            <tr >
                                                <th>RatingType</th>
                                                <th>VendorCode</th>
                                                <th>APR</th>
                                                <th>MAY</th>
                                                <th>JUN</th>
                                                <th>JUL</th>
                                                <th>AUG</th>
                                                <th>SEP</th>
                                                <th>OCT</th>
                                                <th>NOV</th>
                                                <th>DEC</th>
                                                <th>JAN</th>
                                                <th>FEB</th>
                                                <th>MAR</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {vendorRatingdata?.map((val, index) => (
                                                <tr key={index}>
                                                    <td>{val.RatingType}</td>
                                                    <td>{val.VendorCode}</td>
                                                    <td>{val.APR}</td>
                                                    <td>{val.MAY}</td>
                                                    <td>{val.JUN}</td>
                                                    <td>{val.JUL}</td>
                                                    <td>{val.AUG}</td>
                                                    <td>{val.SEP}</td>
                                                    <td>{val.OCT}</td>
                                                    <td>{val.NOV}</td>
                                                    <td>{val.DEC}</td>
                                                    <td>{val.JAN}</td>
                                                    <td>{val.FEB}</td>
                                                    <td>{val.MAR}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table >
                                </Col>
                            </Row>
                        </> : 'No Record Found'}
                    </Container>
                </CardBody>
              {/* {showToastRecord?
              <CardBody>
              <div style={{ margintop: '50px' }}>
                          <CustomToast showToast={showToastRecord} setShowToast={setshowToastRecord} textMessage='No Record Found' Error='bg-danger' />
                      </div>
              </CardBody>:null
            }   */}
            </Card>


        </>
    )
}

export default VendorRatingReport;