import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import CustomSpinner from './Spinner'; 
import { useAuth } from '../context/AuthContext';

function LoginLout() {
  const [ok, setOk] = useState(false);
  const [auth] = useAuth()

  useEffect(() => {
    const authCheck = async () => {
        if (auth?.token) {
          setOk(true);
        } else {
          setOk(false);
        }
    };

    if (auth?.token) authCheck();
  }, [auth?.token]);

  return (
    <div>
      {!ok ? <Outlet /> : <CustomSpinner  />}
    </div>
  );
}

export default LoginLout;