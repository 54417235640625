import { Button, Nav, NavItem, Collapse } from 'react-bootstrap';
import { Link, useLocation } from "react-router-dom";
import React, { useState } from "react";
import { useAuth } from '../components/dashboard/context/AuthContext';


const navigation = [
  {
    title: "Dashboard",
    href: "/dashboard/starter",
    icon: "bi bi-house",
  },
  // {
  //   title: "Purchase Orders",
  //   href: "/dashboard/purchase",
  //   icon: "bi bi-list-ul",
  // },

  {
    title: "Acceptance of PO",
    href: "/dashboard/acceptanceofpo",
    icon: "bi bi-card-checklist",
  },
  {
    title: "Generate ASN",
    href: "/dashboard/dispatch",
    icon: "bi bi-journal-text",
  },
  {
    title: "Scan QR",
    href: "/dashboard/qrcode",
    icon: "bi bi-qr-code-scan",
  },
  {
    title: 'Reports',
    icon: 'bi bi-layout-text-sidebar-reverse',
    dropdownIcon: 'bi bi-caret-down-fill ',
    dropdownItems: [
      { title: 'Accepted Orders List', href: '/dashboard/acceptedorderlist' },
      { title: 'Dispatch Report', href: '/dashboard/dispatchreport' },
      { title: 'Goods Receipt Report', href: '/dashboard/goods-receipt-report' },
    ],
  },
];

const navigationAdmin = [
  {
    title: "Dashboard",
    href: "/dashboard/starter",
    icon: "bi bi-speedometer2",
  },
  {
    title: "Vendor Modification",
    href: "/dashboard/vendor-modification",
    icon: "bi bi-speedometer2",
  },
  {
    title: "Vender Rating",
    href: "/dashboard/vendor-rating",
    icon: "bi bi-speedometer2",
  },
  {
    title: "Create New Vendor",
    href: "/dashboard/signup",
    icon: "bi bi-speedometer2",
  },
  // {
  //   title: "Vendor Reset Password",
  //   href: "/dashboard/reset-password",
  //   icon: "bi bi-speedometer2",
  // },
  {
    title: "Run Schedule",
    href: "/dashboard/run-schedule",
    icon: "bi bi-speedometer2",
  },
  {
    title: 'Reports',
    icon: 'bi bi-hdd-stack',
    dropdownIcon: 'bi bi-caret-down-fill',
    dropdownItems: [
      { title: 'Accepted Orders List', href: '/dashboard/acceptedorderlist' },
      { title: 'Dispatch Report', href: '/dashboard/dispatchreport' },
      { title: 'Goods Receipt Report', href: '/dashboard/goods-receipt-report' },
      { title: 'Vendor Rating Report', href: '/dashboard/vendor-rating-report' },
    ],
  },
];

const navigationSubType = [
  {
    title: "Dashboard",
    href: "/dashboard/starter",
    icon: "bi bi-speedometer2",
  },
  {
    title: 'Reports',
    icon: 'bi bi-hdd-stack',
    dropdownIcon: 'bi bi-caret-down-fill',
    dropdownItems: [
      { title: 'Accepted Orders List', href: '/dashboard/acceptedorderlist' },
      { title: 'Dispatch Report', href: '/dashboard/dispatchreport' },
      { title: 'Goods Receipt Report', href: '/dashboard/goods-receipt-report' },
      { title: 'Vendor Rating Report', href: '/dashboard/vendor-rating-report' },
    ],
  },
];


const Sidebar = () => {
  const [auth] = useAuth();
  const [open, setOpen] = useState({});
  const handleCollapse = (index) => {
    setOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };
  let location = useLocation();
  // 23-03-2024 || BRIJESH SAINI
  const userParse = JSON.parse(auth?.user)
  // console.log(userParse);
  // CODE END
  return (
    <div>
      <div className="d-flex">
        <Button
          color="white"
          className="ms-auto text-white d-lg-none"
          onClick={() => showMobilemenu()}
        >
          <i className="bi bi-x"></i>
        </Button>
      </div>
      <div className="p-3 mt-2">
        <Nav vertical='true' className="sidebarNav flex-column">
          {/* 23-03-2024 || BRIJESH SAINI */}
          {(userParse?.vType) === "VENDOR" && (navigation.map((navi, index) => (
            <React.Fragment key={navi.title || navi.href}>
              {navi.dropdownItems ? (
                <>
                  <NavItem
                    // className="sidenav-bg"
                    key={navi.title || navi.href}
                    className={`sidenav-bg`}
                    onClick={() => { handleCollapse(index); showMobilemenu() }}
                    aria-controls={`example-collapse-text-${index}`}
                    aria-expanded={open[index]}
                  >
                    <div
                      className="nav-link py-3"
                      style={{ cursor: 'pointer' }}
                      onClick={() => showMobilemenu()}
                    >
                      <i className={navi.icon}></i>
                      <span className="ms-3 d-inline-block">{navi.title}</span>
                      {open[index] === '0' ? <>
                        <i className={`${navi.dropdownIcon} ms-4`}></i>
                      </> : <>
                        <i className={`bi bi-caret-up-fill ms-4`}></i>
                      </>}

                    </div>
                  </NavItem>
                  {open[index] && (
                    <>
                      <Collapse in={open[index]} className="d-inline-block">
                        <div id={`example-collapse-text-${index}`} style={{ display: 'inline-block' }}>
                          <ul className="nav flex-column">
                            {navi.dropdownItems.map((item, subIndex) => (
                              <li className="ms-3 mt-1 d-inline-block" key={item.title || item.href}>
                                <NavItem key={item.title || item.href} className="sidenav-bg">
                                  <Link
                                    to={item.href}
                                    onClick={() => showMobilemenu()}
                                    className={
                                      location.pathname === item.href
                                        ? "active nav-link py-3"
                                        : "nav-link py-3"
                                    }
                                  >
                                    {item.title}
                                  </Link>
                                </ NavItem>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </Collapse>
                    </>
                  )}
                </>
              ) : (
                <NavItem key={navi.title || navi.href} className="sidenav-bg">
                  <Link
                    to={navi.href}
                    onClick={() => showMobilemenu()}
                    className={
                      location.pathname === navi.href
                        ? "active nav-link py-3"
                        : "nav-link py-3"
                    }
                  >
                    <i className={navi.icon}></i>
                    <span className="ms-3 d-inline-block">{navi.title}</span>
                  </Link>
                </NavItem>
              )}
            </React.Fragment>
          )))}
          {(userParse?.vType) === "ADMIN" && (navigationAdmin.map((navi, index) => (
            <React.Fragment key={navi.title || navi.href}>
              {navi.dropdownItems ? (
                <>
                  <NavItem
                    // className="sidenav-bg"
                    key={navi.title || navi.href}
                    className={`sidenav-bg`}
                    onClick={() => handleCollapse(index)}
                    aria-controls={`example-collapse-text-${index}`}
                    aria-expanded={open[index]}
                  >
                    <div
                      className="nav-link py-3"
                      style={{ cursor: 'pointer' }}
                    >
                      <i className={navi.icon}></i>
                      <span className="ms-3 d-inline-block">{navi.title}</span>
                      {open[index] === '0' ? <>
                        <i className={`${navi.dropdownIcon} ms-4`}></i>
                      </> : <>
                        <i className={`bi bi-caret-up-fill ms-4`}></i>
                      </>}
                    </div>
                  </NavItem>
                  {open[index] && (
                    <>
                      <Collapse in={open[index]} className="d-inline-block">
                        <div id={`example-collapse-text-${index}`} style={{ display: 'inline-block' }}>
                          <ul className="nav flex-column">
                            {navi.dropdownItems.map((item, subIndex) => (
                              <li className="ms-3 d-inline-block" key={item.title || item.href}>
                                <NavItem key={item.title || item.href} className="sidenav-bg">
                                  <Link
                                    to={item.href}
                                    className={
                                      location.pathname === item.href
                                        ? "active nav-link py-3"
                                        : "nav-link py-3"
                                    }
                                  >
                                    {item.title}
                                  </Link>
                                </ NavItem>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </Collapse>
                    </>
                  )}
                </>
              ) : (
                <NavItem key={navi.title || navi.href} className="sidenav-bg">
                  <Link
                    to={navi.href}
                    className={
                      location.pathname === navi.href
                        ? "active nav-link py-3"
                        : "nav-link py-3"
                    }
                  >
                    <i className={navi.icon}></i>
                    <span className="ms-3 d-inline-block">{navi.title}</span>
                  </Link>
                </NavItem>
              )}
            </React.Fragment>
          )))
          }
          {(userParse?.vType) === "USER" && (navigationSubType.map((navi, index) => (
            <React.Fragment key={navi.title || navi.href}>
              {navi.dropdownItems ? (
                <>
                  <NavItem
                    // className="sidenav-bg"
                    key={navi.title || navi.href}
                    className={`sidenav-bg`}
                    onClick={() => handleCollapse(index)}
                    aria-controls={`example-collapse-text-${index}`}
                    aria-expanded={open[index]}
                  >
                    <div
                      className="nav-link py-3"
                      style={{ cursor: 'pointer' }}
                    >
                      <i className={navi.icon}></i>
                      <span className="ms-3 d-inline-block">{navi.title}</span>
                      {open[index] === '0' ? <>
                        <i className={`${navi.dropdownIcon} ms-4`}></i>
                      </> : <>
                        <i className={`bi bi-caret-up-fill ms-4`}></i>
                      </>}
                    </div>
                  </NavItem>
                  {open[index] && (
                    <>
                      <Collapse in={open[index]} className="d-inline-block">
                        <div id={`example-collapse-text-${index}`} style={{ display: 'inline-block' }}>
                          <ul className="nav flex-column">
                            {navi.dropdownItems.map((item, subIndex) => (
                              <li className="ms-3 d-inline-block" key={item.title || item.href}>
                                <NavItem key={item.title || item.href} className="sidenav-bg">
                                  <Link
                                    to={item.href}
                                    className={
                                      location.pathname === item.href
                                        ? "active nav-link py-3"
                                        : "nav-link py-3"
                                    }
                                  >
                                    {item.title}
                                  </Link>
                                </ NavItem>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </Collapse>
                    </>
                  )}
                </>
              ) : (
                <NavItem key={navi.title || navi.href} className="sidenav-bg">
                  <Link
                    to={navi.href}
                    className={
                      location.pathname === navi.href
                        ? "active nav-link py-3"
                        : "nav-link py-3"
                    }
                  >
                    <i className={navi.icon}></i>
                    <span className="ms-3 d-inline-block">{navi.title}</span>
                  </Link>
                </NavItem>
              )}
            </React.Fragment>
          )))
          }
          {/*    {(navigationAdmin.map((navi, index) => (
              <React.Fragment key={navi.title || navi.href}>
                {navi.dropdownItems ? (
                  <>
                    <NavItem
                      // className="sidenav-bg"
                      key={navi.title || navi.href}
                      className={`sidenav-bg`}
                      onClick={() => handleCollapse(index)}
                      aria-controls={`example-collapse-text-${index}`}
                      aria-expanded={open[index]}
                    >
                      <div
                        className="nav-link py-3"
                        style={{ cursor: 'pointer' }}
                      >
                        <i className={navi.icon}></i>
                        <span className="ms-3 d-inline-block">{navi.title}</span>
                        {open[index] === '0' ? <>
                          <i className={`${navi.dropdownIcon} ms-4`}></i>
                        </> : <>
                          <i className={`bi bi-caret-up-fill ms-4`}></i>
                        </>}
                      </div>
                    </NavItem>
                    {open[index] && (
                      <>
                        <Collapse in={open[index]} className="d-inline-block">
                          <div id={`example-collapse-text-${index}`} style={{ display: 'inline-block' }}>
                            <ul className="nav flex-column">
                              {navi.dropdownItems.map((item, subIndex) => (
                                <li className="ms-3 d-inline-block" key={item.title || item.href}>
                                  <NavItem key={item.title || item.href} className="sidenav-bg">
                                    <Link
                                      to={item.href}
                                      className={
                                        location.pathname === item.href
                                          ? "active nav-link py-3"
                                          : "nav-link py-3"
                                      }
                                    >
                                      {item.title}
                                    </Link>
                                  </ NavItem>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </Collapse>
                      </>
                    )}
                  </>
                ) : (
                  <NavItem key={navi.title || navi.href} className="sidenav-bg">
                    <Link
                      to={navi.href}
                      className={
                        location.pathname === navi.href
                          ? "active nav-link py-3"
                          : "nav-link py-3"
                      }
                    >
                      <i className={navi.icon}></i>
                      <span className="ms-3 d-inline-block">{navi.title}</span>
                    </Link>
                  </NavItem>
                )}
              </React.Fragment>
            )))}
           */}
        </Nav>
      </div>
    </div >
  );
};

export default Sidebar;


