import { COUNTER, FINAL_SUBMIT, GET_GENERATE_ASN_DATA, HANDLE_SUBMIT, SET_CURRENT_PAGE, SET_DISPATCH_QUANTITY, SET_FILTER_CHECK, SET_FILTER_DATA, SET_INV_DATE, SET_INV_NO, SHOW_TOAST, allCheckBoxSelected, singleCheckBoxSelected } from "./action";

export const reducer = (state, action) => {
    switch (action.type) {
        case GET_GENERATE_ASN_DATA:
            return { ...state, data: action.payload, isLoading: action.isLoading }
        case allCheckBoxSelected:
            return { ...state, selectedRows: action.payload }
        case singleCheckBoxSelected:
            return { ...state, selectedRows: action.payload }
        case SET_FILTER_DATA:
            return { ...state, filterData: action.payload }
        case SET_CURRENT_PAGE:
            return { ...state, currentPage: action.payload }
        case SET_FILTER_CHECK:
            return { ...state, filterCheck: action.payload }
        case SET_DISPATCH_QUANTITY:
            return { ...state, dispatchQuantity: { ...state.dispatchQuantity, [action.payload.id]: action.payload.e } }
        case SET_INV_DATE:
            return { ...state, invDate: { ...state.invDate, [action.id]: action.payload } }
        case SET_INV_NO:
            return { ...state, invoiceInput: { ...state.invoiceInput, [action.payload.id]: action.payload.e } }
        case COUNTER:
            return { ...state, counter: state.counter + 1 }
        case FINAL_SUBMIT:
            return { ...state, selectedRows: [], dispatchQuantity: {}, invoiceInput: {}, invDate: {}, filterData: action.payload.FilterResult, data: action.payload.ProductResult, counter: action.payload.counter }
        case HANDLE_SUBMIT:
            return { ...state, gridData: action.payload }
        case SHOW_TOAST:
            return { ...state, showToast: action.payload }
        default:
            return state
    }
}