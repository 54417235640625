export const initialState = {
    data: [],
    filterData: [],
    filterCheck: true,
    dispatchQuantity: {},
    invoiceInput: {},
    invDate: {},
    showToast: false,
    selectedRows: [],
    isLoading: true,
    error: false,
    currentPage: 1,
    ASN: '',
    // counter: 1000,
    gridData: []
}
