import { Container, Row, Col } from "reactstrap";
import { Card, CardTitle, Table, CardBody, Spinner } from 'react-bootstrap';
import { useEffect, useState } from "react";
import axios from "axios";
// import { useNavigate } from "react-router-dom";
import { useAuth } from "../components/dashboard/context/AuthContext";
import CustomCard from "../components/card/CustomCard";
import useAxiosPost from "../hooks/useAxiosPost";
import urls from "../url";


const Starter = () => {
  const [recentPo, setrecentPO] = useState([]);
  const [shippment, setship] = useState([]);
  const [cards, setcarddata] = useState([])
  // const [userdata, setshipd] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [auth] = useAuth();
  const [noRecordRecentPO,setNoRecordRecentPO]= useState('');
  const [noRecordShipment,setNoRecordShipment]= useState('')
  // console.log(auth);
  // const [dropdownOpen1, setDropdownOpen1] = useState(false);
  // const [dropdownOpen2, setDropdownOpen2] = useState(false);
  // const toggle1 = () => setDropdownOpen1((prevState) => !prevState);
  // const toggle2 = () => setDropdownOpen2((prevState) => !prevState);
  // const toggle = () => setDropdownOpen((prevState) => !prevState);
  // const navigate = useNavigate()
  // const date = new Date().toLocaleDateString()

  const vendorid = JSON.parse(auth?.user);

  const token = auth?.token
  const vendorcode = vendorid?.vType === 'VENDOR' ? vendorid?._id : ''
  // const vendorid = JSON.parse(auth?.user);

  const { data } = useAxiosPost(`${urls.dashboardTile}`, {
    Data: {
      VendorCode: vendorcode
    }
  }, {
    headers: {
      Authorization: token
    }
  }
  )

  // const carddata = async () => {

  //   try {
  //     const response = await axios.post(
  //      ,
  //       obj,

  //     );

  //     const responseData = response;
  //     console.log(responseData);

  //     //  setcarddata
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };



  useEffect(() => {
    const listData = async () => {
      const obj = {
        Data: {
          VendorCode: vendorcode
        }
      };
      setIsLoading(true)
      try {
        const response = await axios.post(
          `${urls.dashboardGridTable}`,
          obj,
          {
            headers: {
              Authorization: token
            }
          }
        );
        const responseData = response.data;
        // console.log(responseData);

        if (response.data.Data[0].RecentPO !== undefined) {
          setrecentPO(responseData?.Data?.[0]?.RecentPO);
          setNoRecordRecentPO('')
        } else{
          setNoRecordRecentPO('No RecentPO Record Found');
        }
        if (response.data.Data[0].Shipment !== undefined) {
          setship(responseData?.Data?.[0]?.Shipment);
          setNoRecordShipment('')
        }else{
          setNoRecordShipment('No Shipment Record Found')
        }

        setIsLoading(false)
        // setdata([responseData])
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    listData()

  }, [token, vendorcode]);
  useEffect(() => {
    if (data) {
      setcarddata(data.Data?.[0]);
    }
  }, [data])
  // console.log(recentPo);
  // console.log(auth);
  // console.log(shippment);
  return (
    <>
      <Container>
        <Row>
          <Col md="6" lg="4" >
            <CustomCard heading={'Pending PO'} className='bg-light-warning' status={cards?.PendingPO} />
          </Col>
          <Col md="6" lg="4">
            <CustomCard heading={'Pending Acceptance of PO'} className='bg-light-info' status={
              cards?.PendingAcceptance
            } />
          </Col>
          <Col md="6" lg="4">
            <CustomCard heading={'Delayed Shipments'} className='bg-light-warning' status={cards?.DelayShipment} />
          </Col>
          {/* <Col md="6" lg="3">
            <CustomCard heading={'Delayed Shipments'} className='bg-light-info' status={'100'} />
          </Col> */}
        </Row>

        <Row className="mt-0">
          <Col lg="6">
           
            <Card >
              <CardTitle tag="h6" className="border-bottom d-flex justify-content-between p-2 mb-0 bg-secondary-light text-white">
                <span>
                  Upcoming Shipments
                </span>
                {/* Table2 with Hover */}
                {/* <Dropdown isopen={dropdownOpen1.toString()} onToggle={toggle1}>
                  <DropdownToggle size='sm' variant='light'>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem header onClick={() => navigate('/dashboard/starter/tabledata')}>
                      View More
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown> */}
              </CardTitle>
              {noRecordShipment !==''?noRecordShipment:
            <>
            <CardBody className="shadow">
                {isLoading ? <Spinner style={{ marginLeft: '50%' }} size='sm' animation='border' /> : <>

                  <div style={{ maxHeight: '40vh', overflowY: 'auto' }}>
                    <Table bordered hover className="text-center">
                      <thead style={{
                        position: 'sticky',
                        top: '0'
                      }}>
                        <tr>
                          <th>OrderId</th>
                          <th>POITEM</th>
                          <th>ExpectedDelDate</th>
                          <th>DispatchedQty</th>
                        </tr>
                      </thead>
                      <tbody>

                        {shippment?.map((val, index) => (
                          <tr
                            key={index}
                          >
                            <td>{val.OrderID}</td>
                            <td>{val.PoItem}</td>
                            <td>{val.ExpectedDelDate}</td>
                            <td>{val.DispatchedQty}</td>
                          </tr>
                        ))}



                      </tbody>
                    </Table>
                  </div>
                </>}
              </CardBody>
            </>
            }
              
            </Card>
          </Col>
          <Col lg="6">
            <Card >
              <CardTitle tag="h6" className="border-bottom d-flex justify-content-between p-2 mb-0 bg-secondary-light text-white">

                <span>
                  Recent PO
                </span>

                {/* Table2 with Hover */}
                {/* <Dropdown isopen={dropdownOpen2.toString()} onToggle={toggle2}>
                  <DropdownToggle size='sm' variant='light'>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem header onClick={() => navigate('/dashboard/starter/tabledata2')}>
                      View More
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown> */}

              </CardTitle>
              {noRecordRecentPO !==''?noRecordRecentPO:
              
              <>
                <CardBody className="shadow">
                {isLoading ? <Spinner style={{ marginLeft: '50%' }} size='sm' animation='border' /> : <>
                  <div style={{ maxHeight: '40vh', overflowY: 'auto' }}>
                    <Table bordered hover className="text-center">
                      <thead style={{
                        position: 'sticky',
                        top: '0'
                      }}>
                        <tr >
                          {vendorid?.vType === 'VENDOR' && <>

                            <th style={{
                              position: 'sticky',
                              top: '0'
                            }}>Vendor</th>
                          </>}
                          <th style={{
                            position: 'sticky',
                            top: '0'
                          }}>PurchasingDocument</th>
                          <th style={{
                            position: 'sticky',
                            top: '0'
                          }}>POITEM</th>
                          <th style={{
                            position: 'sticky',
                            top: '0'
                          }}>PurchasingDocType</th>
                          <th style={{
                            position: 'sticky',
                            top: '0'
                          }}>DocumentDate</th>
                          <th style={{
                            position: 'sticky',
                            top: '0'
                          }}>MATERIALCODE</th>
                          <th style={{
                            position: 'sticky',
                            top: '0'
                          }}>PLANT</th>
                          <th>OrderQuantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {recentPo?.map((val, index) => (
                          <tr key={index}>
                            {vendorid?.vType === 'VENDOR' && <td>{val.Vendor}</td>}
                            <td>{val.PurchasingDocument}</td>
                            <td>{val.POITEM}</td>
                            <td>{val.PurchasingDocType}</td>
                            <td>{val.DocumentDate}</td>
                            <td>{val.MATERIALCODE}</td>
                            <td>{val.PLANT}</td>
                            <td>{val.OrderQuantity}</td>
                          </tr>
                        ))}

                      </tbody>
                    </Table>
                  </div>
                </>}

              </CardBody>
              </>
              }
            
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Starter;

