export const GET_GENERATE_ASN_DATA = 'GET_GENERATE_ASN_DATA';
export const allCheckBoxSelected = 'ALL_CHECKBOX_SELECTED';
export const singleCheckBoxSelected = 'SINGLE_CHECKBOX_SELECTED';
export const SET_FILTER_DATA = 'SET_FILTER_DATA';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_FILTER_CHECK = 'SET_FILTER_CHECK';
export const SET_DISPATCH_QUANTITY = 'SET_DISPATCH_QUANTITY';
export const SET_INV_DATE = 'SET_INV_DATE';
export const SET_INV_NO = 'SET_INV_NO';
export const COUNTER = 'COUNTER';
export const HANDLE_SUBMIT = 'HANDLE_SUBMIT'
export const FINAL_SUBMIT = 'FINAL_SUBMIT';
export const SHOW_TOAST = 'SHOW_TOAST'