
import React, { useState } from 'react';
import { Container, Row, Col, Table, Form, InputGroup, Button, Card, CardBody, CardTitle, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik'
import axios from 'axios'
// import { initialState } from '../generateAsn/initialState';
import urls from '../../url';
import { useAuth } from '../../components/dashboard/context/AuthContext';
import * as YUP from 'yup';
import CustomToast from '../../components/toast/Toast';

const VendorCodeSchema = YUP.object({
    VendorCode: YUP.string().required('VendorCode Is Must'),
    // password: YUP.string().required('Password Is Must')
})

function GetDefaultEndDate() {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    // const currentDay = today.getDate();
    if (currentMonth > 2) {
        return `${currentYear + 1}-03-31`
    } else {
        return `${currentYear}-03-31`
    }
}

function GetDefaultStartDate() {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    // const currentDay = today.getDate();
    if (currentMonth > 2) {
        return `${currentYear}-04-01`
    } else {
        return `${currentYear - 1}-04-01`
    }
}

function VendorRatingModification() {
    const startDate = GetDefaultStartDate();
    const endDate = GetDefaultEndDate();
    const [loading, setloading] = useState(false);
    const [isloading, setisloading] = useState(false);
    const [showtoast,setshowtoast] = useState(false);
    const [showToastRecord,setshowToastRecord] = useState(false)
    // console.log(endDate);
    const [auth] = useAuth()
    const [RatingDelivery, setRatingDelivery] = useState({
        RatingType: 'RatingDelivery'
    });
    const [RatingQuality, setRatingQuality] = useState({
        RatingType: 'RatingQuality'
    });
    const [RatingFreight, setRatingFreight] = useState({
        RatingType: 'RatingFreight'
    });
    const [RatingOverAll, setRatingOverAll] = useState({
        RatingType: 'RatingOverAll'
    });
    const [ratingData, setRatingData] = useState([])

    const Initialvalue = {
        FromDate: startDate,
        EndDate: endDate,
        VendorCode: ''
    };



    // console.log(initialState);
    const { handleBlur, handleChange, handleSubmit, values, resetForm, errors, touched } = useFormik({
        initialValues: Initialvalue,
        validationSchema: VendorCodeSchema,
        onSubmit: async (values) => {
            // console.log(RatingDelivery);
            const data = {
                "Data": [{
                    VendorCode: values.VendorCode,
                    DateFrom: startDate,
                    DateTo: endDate
                }]
            };
            setloading(true)
            try {
                const response = await axios.post(urls.vendorRatingModificationReport,
                    data,
                    {
                        headers: {
                            "Authorization": auth?.token
                        }
                    }
                )
                if (response.data) {
                    setRatingData(response.data.Data)
                }
                setloading(false)
                setshowToastRecord(false)
            } catch (error) {
                console.log('Error fetching vendor report' +error);
                setshowToastRecord(true)
                setloading(false)
                setRatingDelivery(() => ({
                    RatingType: 'RatingDelivery'
                }));
                setRatingQuality(() => ({
                    RatingType: 'RatingQuality'
                }));
                setRatingFreight(() => ({
                    RatingType: 'RatingFreight'
                }))
                setRatingOverAll(() => ({
                    RatingType: 'RatingOverAll'
                }))
                setRatingData([])
            }
            
            // console.log(obj)
            // try {
            //     const response = await axios.get('http://localhost:7050/data')
            //     console.log('Data received from API:', response.data);
            //     SetvendorRating(response.data)
            // } catch (error) {
            //     console.error('Error fetching data:', error);
            // }

        }
    })
// console.log(new Date().toDateString().split(' ')[1]);
    const handleReterive = async (e) => {
        e.preventDefault()


        const allRating = [
            RatingDelivery,
            RatingQuality,
            RatingFreight,
            RatingOverAll
        ];
        // console.log(allRating);
        const finalValue = allRating.map((val, index) => {
            const { RatingType } = val
            // console.log(val.RatingType);
            return {
                // Vendor,
                // NameType,
                DateFrom: startDate,
                DateTo: endDate,
                VendorCode: values.VendorCode,
                RatingType: RatingType,
                JAN: val[`${RatingType}-JAN`] ? Number(val[`${RatingType}-JAN`]) : ratingData[index].JAN ? ratingData[index].JAN : '',
                FEB: val[`${RatingType}-FEB`] ? Number(val[`${RatingType}-FEB`]) : ratingData[index].FEB ? ratingData[index].FEB : '',
                MAR: val[`${RatingType}-MAR`] ? Number(val[`${RatingType}-MAR`]) : ratingData[index].MAR ? ratingData[index].MAR : '',
                APR: val[`${RatingType}-APR`] ? Number(val[`${RatingType}-APR`]) : ratingData[index].APR ? ratingData[index].APR : '',
                MAY: val[`${RatingType}-MAY`] ? Number(val[`${RatingType}-MAY`]) : ratingData[index].MAY ? ratingData[index].MAY : '',
                JUN: val[`${RatingType}-JUN`] ? Number(val[`${RatingType}-JUN`]) : ratingData[index].JUN ? ratingData[index].JUN : '',
                JUL: val[`${RatingType}-JUL`] ? Number(val[`${RatingType}-JUL`]) : ratingData[index].JUL ? ratingData[index].JUL : '',
                AUG: val[`${RatingType}-AUG`] ? Number(val[`${RatingType}-AUG`]) : ratingData[index].AUG ? ratingData[index].AUG : '',
                SEP: val[`${RatingType}-SEP`] ? Number(val[`${RatingType}-SEP`]) : ratingData[index].SEP ? ratingData[index].SEP : '',
                OCT: val[`${RatingType}-OCT`] ? Number(val[`${RatingType}-OCT`]) : ratingData[index].OCT ? ratingData[index].OCT : '',
                NOV: val[`${RatingType}-NOV`] ? Number(val[`${RatingType}-NOV`]) : ratingData[index].NOV ? ratingData[index].NOV : '',
                DEC: val[`${RatingType}-DEC`] ? Number(val[`${RatingType}-DEC`]) : ratingData[index].DEC ? ratingData[index].DEC : '',
            };
        })

        const data = { "Data": finalValue }
        setisloading(true)
        const submitData = await axios.post(urls.vendorRatingModification,
            data,
            {
                headers: {
                    "Authorization": auth?.token
                }
            }
        )
      
        console.log(submitData.status);
        if (submitData.status === 200) {
            setshowtoast(true)
            setisloading(false)
            setRatingDelivery(() => ({
                RatingType: 'RatingDelivery'
            }));
            setRatingQuality(() => ({
                RatingType: 'RatingQuality'
            }));
            setRatingFreight(() => ({
                RatingType: 'RatingFreight'
            }))
            setRatingOverAll(() => ({
                RatingType: 'RatingOverAll'
            }))
            setRatingData([])
            resetForm()
        }

    }
    // console.log();
    const handleChangeRatingDelivery = (e, name, deliveryType) => {
        const { value } = e;
        setRatingDelivery((pre) => ({
            ...pre,
            [`${deliveryType}-${name}`]: value
        }))

    };
    // console.log(RatingDelivery);
    const handleChangeRatingQuality = (e, name, deliveryType) => {
        const { value } = e;
        setRatingQuality((pre) => ({
            ...pre,
            [`${deliveryType}-${name}`]: value
        }))

    };
    const handleChangeRatingFreight = (e, name, deliveryType) => {
        const { value } = e;
        setRatingFreight((pre) => ({
            ...pre,
            [`${deliveryType}-${name}`]: value
        }))
    }

    const handleChangeRatingOverAll = (e, name, deliveryType) => {
        const { value } = e;
        setRatingOverAll((pre) => ({
            ...pre,
            [`${deliveryType}-${name}`]: value
        }))
    }
    // console.log(ratingData);
    return (
        <>
            <Card className='shadow bg-white my-3 mx-auto' style={{ borderRadius: '.5rem', }}>
                <CardTitle className="border-bottom d-flex justify-content-between p-1 mb-0 bg-secondary-light text-white">
                    <h5>Vendor Rating Modification</h5>
                </CardTitle>
                <CardBody>
                    <Container>
                        <Form onSubmit={handleReterive}>
                            <Row>
                                <Col md={4} lg={6} xl={4}>
                                    <InputGroup aria-controls='validationFormik01' size='sm' className="mb-1">
                                        <InputGroup.Text>Vendor Code</InputGroup.Text>
                                        <Form.Control
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name='VendorCode'
                                            type='text'
                                            value={values.VendorCode}
                                            className={`${touched.VendorCode && errors.VendorCode ? 'is-invalid' : ''}`}
                                            size='sm'
                                            isValid={touched.VendorCode && !errors.VendorCode}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {touched.VendorCode && errors.VendorCode}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                                <Col md={4} lg={6} xl={3}>
                                    <InputGroup size='sm' className="mb-1">
                                        <InputGroup.Text>From Date</InputGroup.Text>
                                        <Form.Control
                                            name='FromDate'
                                            type='date'
                                            disabled
                                            value={startDate}
                                            aria-label="fdate" />
                                    </InputGroup>
                                </Col>
                                <Col md={4} lg={6} xl={3}>
                                    <InputGroup size='sm' className="mb-1">
                                        <InputGroup.Text>End   Date</InputGroup.Text>
                                        <Form.Control
                                            disabled
                                            value={endDate}
                                            name='EndDate'
                                            type='date'
                                            aria-label="edate" />
                                    </InputGroup>
                                </Col>
                                <Col md={4} lg={6} xl={3}>
                                    <Button  onClick={handleSubmit} disabled={loading ? true : false} size='sm' variant='secondary'>
                                        {loading ? <Spinner size='sm' animation='border' /> : 'Retrieve'}
                                    </Button>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col lg={12}>
                                    <Table size='sm' bordered hover id='my-table' className='text-center' responsive>
                                        <thead >
                                            <tr >
                                                <th>RatingType</th>
                                                <th>APR</th>
                                                <th>MAY</th>
                                                <th>JUN</th>
                                                <th>JUL</th>
                                                <th>AUG</th>
                                                <th>SEP</th>
                                                <th>OCT</th>
                                                <th>NOV</th>
                                                <th>DEC</th>
                                                <th>JAN</th>
                                                <th>FEB</th>
                                                <th>MAR</th>

                                            </tr>
                                        </thead>
                                        <tbody >
                                            {/* RatingDelivery */}
                                            <tr>
                                                <td>RatingDelivery
                                                </td>
                                                <td>

                                                    <Form.Control
                                                        type="text"
                                                        name={`APR`}
                                                        style={{ minWidth: '100px' }}
                                                        value={RatingDelivery[`RatingDelivery-APR`] !== undefined ? RatingDelivery[`RatingDelivery-APR`] : ratingData?.[0]?.APR ? ratingData?.[0]?.APR : ''}
                                                        onChange={(e) => handleChangeRatingDelivery(e.target, 'APR', 'RatingDelivery')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        style={{ minWidth: '100px' }}
                                                        name={`MAY`}
                                                        // disabled={new Date().toDateString().split(' ')[1].trim()}
                                                        value={RatingDelivery[`RatingDelivery-MAY`] !== undefined ? RatingDelivery[`RatingDelivery-MAY`] : ratingData?.[0]?.MAY ? ratingData?.[0]?.MAY : ''}
                                                        onChange={(e) => handleChangeRatingDelivery(e.target, 'MAY', 'RatingDelivery')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        style={{ minWidth: '100px' }}
                                                        name={`JUN`}
                                                        value={RatingDelivery[`RatingDelivery-JUN`] !== undefined ? RatingDelivery[`RatingDelivery-JUN`] : ratingData?.[0]?.JUN ? ratingData?.[0]?.JUN : ''}
                                                        onChange={(e) => handleChangeRatingDelivery(e.target, 'JUN', 'RatingDelivery')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        style={{ minWidth: '100px' }}
                                                        name={`JUL`}
                                                        value={RatingDelivery[`RatingDelivery-JUL`] !== undefined ? RatingDelivery[`RatingDelivery-JUL`] : ratingData?.[0]?.JUL ? ratingData?.[0]?.JUL : ''}
                                                        onChange={(e) => handleChangeRatingDelivery(e.target, 'JUL', 'RatingDelivery')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        style={{ minWidth: '100px' }}
                                                        name={`AUG`}
                                                        value={RatingDelivery[`RatingDelivery-AUG`] !== undefined ? RatingDelivery[`RatingDelivery-AUG`] : ratingData?.[0]?.AUG ? ratingData?.[0]?.AUG : ''}
                                                        onChange={(e) => handleChangeRatingDelivery(e.target, 'AUG', 'RatingDelivery')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        style={{ minWidth: '100px' }}
                                                        name={`SEP`}
                                                        value={RatingDelivery[`RatingDelivery-SEP`] !== undefined ? RatingDelivery[`RatingDelivery-SEP`] : ratingData?.[0]?.SEP ? ratingData?.[0]?.SEP : ''}
                                                        onChange={(e) => handleChangeRatingDelivery(e.target, 'SEP', 'RatingDelivery')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        style={{ minWidth: '100px' }}
                                                        name={`OCT`}
                                                        value={RatingDelivery[`RatingDelivery-OCT`] !== undefined ? RatingDelivery[`RatingDelivery-OCT`] : ratingData?.[0]?.OCT ? ratingData?.[0]?.OCT : ''}
                                                        onChange={(e) => handleChangeRatingDelivery(e.target, 'OCT', 'RatingDelivery')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        style={{ minWidth: '100px' }}
                                                        name={`NOV`}
                                                        value={RatingDelivery[`RatingDelivery-NOV`] !== undefined ? RatingDelivery[`RatingDelivery-NOV`] : ratingData?.[0]?.NOV ? ratingData?.[0]?.NOV : ''}
                                                        onChange={(e) => handleChangeRatingDelivery(e.target, 'NOV', 'RatingDelivery')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        style={{ minWidth: '100px' }}
                                                        name={`DEC`}
                                                        value={RatingDelivery[`RatingDelivery-DEC`] !== undefined ? RatingDelivery[`RatingDelivery-DEC`] : ratingData?.[0]?.DEC ? ratingData?.[0]?.DEC : ''}
                                                        onChange={(e) => handleChangeRatingDelivery(e.target, 'DEC', 'RatingDelivery')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        style={{ minWidth: '100px' }}
                                                        name={`JAN`}
                                                        value={RatingDelivery[`RatingDelivery-JAN`] !== undefined ? RatingDelivery[`RatingDelivery-JAN`] : ratingData?.[0]?.JAN ? ratingData?.[0]?.JAN : ''}
                                                        onChange={(e) => handleChangeRatingDelivery(e.target, 'JAN', 'RatingDelivery')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        style={{ minWidth: '100px' }}
                                                        name={`FEB`}
                                                        value={RatingDelivery[`RatingDelivery-FEB`] !== undefined ? RatingDelivery[`RatingDelivery-FEB`] : ratingData?.[0]?.FEB ? ratingData?.[0]?.FEB : ''}
                                                        onChange={(e) => handleChangeRatingDelivery(e.target, 'FEB', 'RatingDelivery')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        style={{ minWidth: '100px' }}
                                                        name={`MAR`}
                                                        value={RatingDelivery[`RatingDelivery-MAR`] !== undefined ? RatingDelivery[`RatingDelivery-MAR`] : ratingData?.[0]?.MAR ? ratingData?.[0]?.MAR : ''}
                                                        onChange={(e) => handleChangeRatingDelivery(e.target, 'MAR', 'RatingDelivery')}
                                                    />
                                                </td>
                                            </tr>
                                            {/* RatingQuality */}
                                            <tr>
                                                <td>RatingQuality</td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`APR`}
                                                        value={RatingQuality[`RatingQuality-APR`] !== undefined ? RatingQuality[`RatingQuality-APR`] : ratingData?.[1]?.APR ? ratingData?.[1]?.APR : ''}
                                                        onChange={(e) => handleChangeRatingQuality(e.target, 'APR', 'RatingQuality')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`MAY`}
                                                        value={RatingQuality[`RatingQuality-MAY`] !== undefined ? RatingQuality[`RatingQuality-MAY`] : ratingData?.[1]?.MAY ? ratingData?.[1]?.MAY : ''}
                                                        onChange={(e) => handleChangeRatingQuality(e.target, 'MAY', 'RatingQuality')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`JUN`}
                                                        value={RatingQuality[`RatingQuality-JUN`] !== undefined ? RatingQuality[`RatingQuality-JUN`] : ratingData?.[1]?.JUN ? ratingData?.[1]?.JUN : ''}
                                                        onChange={(e) => handleChangeRatingQuality(e.target, 'JUN', 'RatingQuality')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`JUL`}
                                                        value={RatingQuality[`RatingQuality-JUL`] !== undefined ? RatingQuality[`RatingQuality-JUL`] : ratingData?.[1]?.JUL ? ratingData?.[1]?.JUL : ''}
                                                        onChange={(e) => handleChangeRatingQuality(e.target, 'JUL', 'RatingQuality')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`AUG`}
                                                        value={RatingQuality[`RatingQuality-AUG`] !== undefined ? RatingQuality[`RatingQuality-AUG`] : ratingData?.[1]?.AUG ? ratingData?.[1]?.AUG : ''}
                                                        onChange={(e) => handleChangeRatingQuality(e.target, 'AUG', 'RatingQuality')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`SEP`}
                                                        value={RatingQuality[`RatingQuality-SEP`] !== undefined ? RatingQuality[`RatingQuality-SEP`] : ratingData?.[1]?.SEP ? ratingData?.[1]?.SEP : ''}
                                                        onChange={(e) => handleChangeRatingQuality(e.target, 'SEP', 'RatingQuality')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`OCT`}
                                                        value={RatingQuality[`RatingQuality-OCT`] !== undefined ? RatingQuality[`RatingQuality-OCT`] : ratingData?.[1]?.OCT ? ratingData?.[1]?.OCT : ''}
                                                        onChange={(e) => handleChangeRatingQuality(e.target, 'OCT', 'RatingQuality')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`NOV`}
                                                        value={RatingQuality[`RatingQuality-NOV`] !== undefined ? RatingQuality[`RatingQuality-NOV`] : ratingData?.[1]?.NOV ? ratingData?.[1]?.NOV : ''}
                                                        onChange={(e) => handleChangeRatingQuality(e.target, 'NOV', 'RatingQuality')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`DEC`}
                                                        value={RatingQuality[`RatingQuality-DEC`] !== undefined ? RatingQuality[`RatingQuality-DEC`] : ratingData?.[1]?.DEC ? ratingData?.[1]?.DEC : ''}
                                                        onChange={(e) => handleChangeRatingQuality(e.target, 'DEC', 'RatingQuality')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`JAN`}
                                                        value={RatingQuality[`RatingQuality-JAN`] !== undefined ? RatingQuality[`RatingQuality-JAN`] : ratingData?.[1]?.JAN ? ratingData?.[1]?.JAN : ''}
                                                        onChange={(e) => handleChangeRatingQuality(e.target, 'JAN', 'RatingQuality')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`FEB`}
                                                        value={RatingQuality[`RatingQuality-FEB`] !== undefined ? RatingQuality[`RatingQuality-FEB`] : ratingData?.[1]?.FEB ? ratingData?.[1]?.FEB : ''}
                                                        onChange={(e) => handleChangeRatingQuality(e.target, 'FEB', 'RatingQuality')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`MAR`}
                                                        value={RatingQuality[`RatingQuality-MAR`] !== undefined ? RatingQuality[`RatingQuality-MAR`] : ratingData?.[1]?.MAR ? ratingData?.[1]?.MAR : ''}
                                                        onChange={(e) => handleChangeRatingQuality(e.target, 'MAR', 'RatingQuality')}
                                                    />
                                                </td>
                                            </tr>
                                            {/* RatingFreight */}
                                            <tr>

                                                <td>RatingFreight</td>

                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`APR`}
                                                        value={RatingFreight[`RatingFreight-APR`] !== undefined ? RatingFreight[`RatingFreight-APR`] : ratingData?.[2]?.APR ? ratingData?.[2]?.APR : ''}
                                                        onChange={(e) => handleChangeRatingFreight(e.target, 'APR', 'RatingFreight')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`MAY`}
                                                        value={RatingFreight[`RatingFreight-MAY`] !== undefined ? RatingFreight[`RatingFreight-MAY`] : ratingData?.[2]?.MAY ? ratingData?.[2]?.MAY : ''}
                                                        onChange={(e) => handleChangeRatingFreight(e.target, 'MAY', 'RatingFreight')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`JUN`}
                                                        value={RatingFreight[`RatingFreight-JUN`] !== undefined ? RatingFreight[`RatingFreight-JUN`] : ratingData?.[2]?.JUN ? ratingData?.[2]?.JUN : ''}
                                                        onChange={(e) => handleChangeRatingFreight(e.target, 'JUN', 'RatingFreight')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`JUL`}
                                                        value={RatingFreight[`RatingFreight-JUL`] !== undefined ? RatingFreight[`RatingFreight-JUL`] : ratingData?.[2]?.JUL ? ratingData?.[2]?.JUL : ''}
                                                        onChange={(e) => handleChangeRatingFreight(e.target, 'JUL', 'RatingFreight')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`AUG`}
                                                        value={RatingFreight[`RatingFreight-AUG`] !== undefined ? RatingFreight[`RatingFreight-AUG`] : ratingData?.[2]?.AUG ? ratingData?.[2]?.AUG : ''}
                                                        onChange={(e) => handleChangeRatingFreight(e.target, 'AUG', 'RatingFreight')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`SEP`}
                                                        value={RatingFreight[`RatingFreight-SEP`] !== undefined ? RatingFreight[`RatingFreight-SEP`] : ratingData?.[2]?.SEP ? ratingData?.[2]?.SEP : ''}
                                                        onChange={(e) => handleChangeRatingFreight(e.target, 'SEP', 'RatingFreight')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`OCT`}
                                                        value={RatingFreight[`RatingFreight-OCT`] !== undefined ? RatingFreight[`RatingFreight-OCT`] : ratingData?.[2]?.OCT ? ratingData?.[2]?.OCT : ''}
                                                        onChange={(e) => handleChangeRatingFreight(e.target, 'OCT', 'RatingFreight')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`NOV`}
                                                        value={RatingFreight[`RatingFreight-NOV`] !== undefined ? RatingFreight[`RatingFreight-NOV`] : ratingData?.[2]?.NOV ? ratingData?.[2]?.NOV : ''}
                                                        onChange={(e) => handleChangeRatingFreight(e.target, 'NOV', 'RatingFreight')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`DEC`}
                                                        value={RatingFreight[`RatingFreight-DEC`] !== undefined ? RatingFreight[`RatingFreight-DEC`] : ratingData?.[2]?.DEC ? ratingData?.[2]?.DEC : ''}
                                                        onChange={(e) => handleChangeRatingFreight(e.target, 'DEC', 'RatingFreight')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`JAN`}
                                                        value={RatingFreight[`RatingFreight-JAN`] !== undefined ? RatingFreight[`RatingFreight-JAN`] : ratingData?.[2]?.JAN ? ratingData?.[2]?.JAN : ''}
                                                        onChange={(e) => handleChangeRatingFreight(e.target, 'JAN', 'RatingFreight')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`FEB`}
                                                        value={RatingFreight[`RatingFreight-FEB`] !== undefined ? RatingFreight[`RatingFreight-FEB`] : ratingData?.[2]?.FEB ? ratingData?.[2]?.FEB : ''}
                                                        onChange={(e) => handleChangeRatingFreight(e.target, 'FEB', 'RatingFreight')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`MAR`}
                                                        value={RatingFreight[`RatingFreight-MAR`] !== undefined ? RatingFreight[`RatingFreight-MAR`] : ratingData?.[2]?.MAR ? ratingData?.[2]?.MAR : ''}
                                                        onChange={(e) => handleChangeRatingFreight(e.target, 'MAR', 'RatingFreight')}
                                                    />
                                                </td>
                                            </tr>

                                            {/* RatingOverAll */}
                                            <tr>
                                                <td>RatingOverAll</td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`APR`}
                                                        value={RatingOverAll[`RatingOverAll-APR`] !== undefined ? RatingOverAll[`RatingOverAll-APR`] : ratingData?.[3]?.APR ? ratingData?.[3]?.APR : ''}
                                                        onChange={(e) => handleChangeRatingOverAll(e.target, 'APR', 'RatingOverAll')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`MAY`}
                                                        value={RatingOverAll[`RatingOverAll-MAY`] !== undefined ? RatingOverAll[`RatingOverAll-MAY`] : ratingData?.[3]?.MAY ? ratingData?.[3]?.MAY : ''}
                                                        onChange={(e) => handleChangeRatingOverAll(e.target, 'MAY', 'RatingOverAll')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`JUN`}
                                                        value={RatingOverAll[`RatingOverAll-JUN`] !== undefined ? RatingOverAll[`RatingOverAll-JUN`] : ratingData?.[3]?.JUN ? ratingData?.[3]?.JUN : ''}
                                                        onChange={(e) => handleChangeRatingOverAll(e.target, 'JUN', 'RatingOverAll')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`JUL`}
                                                        value={RatingOverAll[`RatingOverAll-JUL`] !== undefined ? RatingOverAll[`RatingOverAll-JUL`] : ratingData?.[3]?.JUL ? ratingData?.[3]?.JUL : ''}
                                                        onChange={(e) => handleChangeRatingOverAll(e.target, 'JUL', 'RatingOverAll')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`AUG`}
                                                        value={RatingOverAll[`RatingOverAll-AUG`] !== undefined ? RatingOverAll[`RatingOverAll-AUG`] : ratingData?.[3]?.AUG ? ratingData?.[3]?.AUG : ''}
                                                        onChange={(e) => handleChangeRatingOverAll(e.target, 'AUG', 'RatingOverAll')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`SEP`}
                                                        value={RatingOverAll[`RatingOverAll-SEP`] !== undefined ? RatingOverAll[`RatingOverAll-SEP`] : ratingData?.[3]?.SEP ? ratingData?.[3]?.SEP : ''}
                                                        onChange={(e) => handleChangeRatingOverAll(e.target, 'SEP', 'RatingOverAll')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`OCT`}
                                                        value={RatingOverAll[`RatingOverAll-OCT`] !== undefined ? RatingOverAll[`RatingOverAll-OCT`] : ratingData?.[3]?.OCT ? ratingData?.[3]?.OCT : ''}
                                                        onChange={(e) => handleChangeRatingOverAll(e.target, 'OCT', 'RatingOverAll')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`NOV`}
                                                        value={RatingOverAll[`RatingOverAll-NOV`] !== undefined ? RatingOverAll[`RatingOverAll-NOV`] : ratingData?.[3]?.NOV ? ratingData?.[3]?.NOV : ''}
                                                        onChange={(e) => handleChangeRatingOverAll(e.target, 'NOV', 'RatingOverAll')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`DEC`}
                                                        value={RatingOverAll[`RatingOverAll-DEC`] !== undefined ? RatingOverAll[`RatingOverAll-DEC`] : ratingData?.[3]?.DEC ? ratingData?.[3]?.DEC : ''}
                                                        onChange={(e) => handleChangeRatingOverAll(e.target, 'DEC', 'RatingOverAll')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`JAN`}
                                                        value={RatingOverAll[`RatingOverAll-JAN`] !== undefined ? RatingOverAll[`RatingOverAll-JAN`] : ratingData?.[3]?.JAN ? ratingData?.[3]?.JAN : ''}
                                                        onChange={(e) => handleChangeRatingOverAll(e.target, 'JAN', 'RatingOverAll')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`FEB`}
                                                        value={RatingOverAll[`RatingOverAll-FEB`] !== undefined ? RatingOverAll[`RatingOverAll-FEB`] : ratingData?.[3]?.FEB ? ratingData?.[3]?.FEB : ''}
                                                        onChange={(e) => handleChangeRatingOverAll(e.target, 'FEB', 'RatingOverAll')}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="text"
                                                        name={`MAR`}
                                                        value={RatingOverAll[`RatingOverAll-MAR`] !== undefined ? RatingOverAll[`RatingOverAll-MAR`] : ratingData?.[3]?.MAR ? ratingData?.[3]?.MAR : ''}
                                                        onChange={(e) => handleChangeRatingOverAll(e.target, 'MAR', 'RatingOverAll')}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>

                                    </Table >
                                    <div className='text-end mt-2'>
                                        <Button variant='secondary' size='sm' disabled={isloading ? true : false} type='submit'>
                                            {isloading ? <Spinner size='sm' animation='border' /> : 'Submit'}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                    <CustomToast showToast={showtoast} setShowToast={setshowtoast}  textMessage='Vendor Rating Modification Success' />
                    <CustomToast showToast={showToastRecord} setShowToast={setshowToastRecord} textMessage='No Record Found' Error='bg-danger' />
                </CardBody>

            </Card>


        </>
    )
}

export default VendorRatingModification;










