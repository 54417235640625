import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, Table, Button, Form, Container, Row, Col, InputGroup, Spinner } from 'react-bootstrap';
import 'jspdf-autotable';
import jsPDF from 'jspdf';
import JsonData from "../../utils/Pdf.json";
import qrCode from 'qrcode';
// import useAxios from '../../hooks/useAxios';
import urls from '../../url';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';
import { useAuth } from '../../components/dashboard/context/AuthContext';
import useAxiosPost from '../../hooks/useAxiosPost';
import axios from 'axios';
function DispatchReport() {
  const [asnList, setAsnList] = useState([]);
  const [asn, setAsn] = useState({
    // asn: "",
    fdate: "",
    edate: ""
  });
  const [auth] = useAuth()
  const [imagedata, setimagesdata] = useState([])
  // const { data, error, loading, execute } = useAxios()
  const [gridPdfData, setGridPdfData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [vendorpdfdetail, setvendorpdfdetail] = useState([])
  const [formPdfData, setFormPdfData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { imagesData, executeImageData } = useAxiosPost()
  const [DateError, setDateError] = useState('')
  // const [status, setstatus] = useState('')
  const userParse = JSON.parse(auth?.user)
  // console.log(userParse);
  const Vendor_idByType = userParse?.vType === 'VENDOR' ? userParse?._id : ''

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setAsn((prev) => ({
      ...prev,
      [name]: value
    }));
  };
  useEffect(() => {
    console.log(formPdfData);
  }, [vendorpdfdetail])
  // console.log(asn);
  useEffect(() => {
    if (imagesData?.Data?.[0]?.length > 0) {
      setimagesdata(new ImageData(1, 2));
      console.log(imagesData);
      const fileName = "DummyImage.png"
      extractFile(imagedata?.Data?.[0]?.IMAGEPATH, fileName)
      imagedata?.Data?.[0]?.IMAGEPATH.map((val) => {

        // const url = `${val.Destination}/${val.Filename} `;
        // const { Path, Originalname } = imageData;
        // const imagePath =`http://172.16.16.75:551/${val.Path}` ; 

        // // Create a temporary link element
        // const link = document.createElement('a');
        // link.href = imagePath;
        // link.setAttribute('download', val.Originalname); 

        // // Programmatically click the link to trigger the download
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        // console.log(val);
        // const url = `http://172.16.16.75:551`
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', val.Originalname);
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
      })
    }

  }, [imagesData])
  // useEffect(() => {
  //   // if (data) {

  //   //   // const searchAsnResult = data.filter((val) => asn.includes(val.newformdata.ASN));
  //   //   // setAsnList(searchAsnResult)
  //   //   // const dispatchDate = new Date(searchAsnResult[0].newformdata.DispatchDate);
  //   //   // const deliveryDate = new Date(searchAsnResult[0].newformdata.DilveryDate);

  //   //   // const currentDate = new Date();
  //   //   // let status;
  //   //   // if (currentDate < dispatchDate) {
  //   //   //   status = "Not Started";
  //   //   // } else if (currentDate <= deliveryDate) {
  //   //   //   status = "Pending";
  //   //   // } else {
  //   //   //   status = "Fulfilled";
  //   //   // }
  //   //   // setstatus(status);

  //   // }
  //   if (formPdfData&&vendorpdfdetail&&gridPdfData) {

  //   }
  // }, [formPdfData,vendorpdfdetail,gridPdfData])
  // console.log(asnList);
  // console.log(asnList?.[0]?.HEADER?.[0]?.Vendor);
  const RetrieveAsn = async () => {
    const startDate = new Date(asn.fdate).toISOString()

    function convertISODateToString(startDate) {
      const date = new Date(startDate);
      // Get the day, month, and year from the date object
      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() returns month from 0-11
      const year = date.getUTCFullYear();
      // Construct the date string in DD-MM-YYYY format
      return `${day}-${month}-${year}`;
    }
    // const g = convertISODateToString(startDate)  //commented on 06_04_2024
    const g = new Date(startDate).toISOString().split('T')[0]         //Added Sandeep
    const endDate = new Date(asn.edate).toISOString()
    // function convertISODateToString(endDate) {
    //   const date = new Date(endDate);
    //   // Get the day, month, and year from the date object
    //   const day = String(date.getUTCDate()).padStart(2, '0');
    //   const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() returns month from 0-11
    //   const year = date.getUTCFullYear();
    //   // Construct the date string in DD-MM-YYYY format
    //   return `${day}-${month}-${year}`;
    // }
    //  const j = convertISODateToString(endDate)  //commented on 06_04_2024

    const j = new Date(endDate).toISOString().split('T')[0]              //Added Sandeep
    try {
      console.log('Firstdate is' + g);
      console.log('LAstDate is' + j)
      if (g > j) {
        setDateError('Start date must be less than end date');
        setAsnList([])
      } else {

        const Data = { Data: { VendorCode: Vendor_idByType, DateFrom: g, DateTo: j } }
        console.log(Vendor_idByType);
        console.log('Data is ' + JSON.stringify(Data));
        // const asnlistData = await axios.get(`http://localhost:8050/data`);
        setIsLoading(true)
        const response = await axios.post(urls.generateASNReportApi, Data, {
          headers: {
            "Authorization": auth?.token
          }

        })


        // console.log(response.data);
        // console.log(response.data[0])


        function iterateStrength(obj) {
          if (Array.isArray(obj)) {
            for (const item of obj) {
              iterateStrength(item);
            }
          } else if (typeof obj === 'object' && obj !== null) {
            // console.log(obj);
            for (const key in obj) {
              if (key === 'TP') {
                // console.log('ObjKey is '+ JSON.stringify(obj[key]));
                // console.log('VendorCode is '+Vendor_idByType);
                // console.log(userParse?.vType);
                // console.log(obj[key]);
                if (obj[key] === "NO RECORD") {
                  setIsLoading(false)
                  setAsnList(obj[key])
                  setDateError("")
                } else {
                  // console.log(Vendor_idByType);
                  // console.log(obj[key]);
                  const filterDataByVendor_Vendor = obj[key].filter((val) => val.VendorCode.trim() === Vendor_idByType);

                  // console.log(filterDataByVendor_Vendor.length);
                  if (filterDataByVendor_Vendor.length === 0 && Vendor_idByType === 'VENDOR') {
                    setIsLoading(false)
                    setAsnList("NO RECORD")
                    setDateError("")
                  } else {
                    setIsLoading(false)
                    setDateError("")
                    setAsnList(userParse?.vType === 'VENDOR' ? filterDataByVendor_Vendor : obj[key])
                  }

                }

                //  console.log('filter data is '+filterDataByVendor_Vendor);

                // setAsnList(obj[key]);
                // console.log(Strength: ${ obj[key]});
              } else {
                iterateStrength(obj[key]);
              }
            }
          }
        }
        iterateStrength(response.data)

        // await execute(`${urls.generateASNReportApi}`, Data, {
        //   headers: {
        //     "Authorization": auth?.token
        //   }

        // })
      }  //  console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  const handleRetrieveClick = () => {
    // Call the function to fetch data
    RetrieveAsn();
    // Update status here
    // if (asnList.length > 0) {

    //   const dispatchDate = new Date(asnList[0].newformdata.DispatchDate);
    //   const deliveryDate = new Date(asnList[0].newformdata.DilveryDate);

    //   const currentDate = new Date();
    //   let status;
    //   if (currentDate < dispatchDate) {
    //     status = "Not Started";
    //   } else if (currentDate <= deliveryDate) {
    //     status = "Pending";
    //   } else {
    //     status = "Fulfilled";
    //   }
    //   setstatus(status);
    // }
  };

  // const calculateProgress = () => {
  //   switch (status) {
  //     case 'Not Started':
  //       return 0;
  //     case 'Pending':
  //       return 50;
  //     case 'Fulfilled':
  //       return 100;
  //     default:
  //       return 0;
  //   }
  // };

  // const progress = calculateProgress();
  const donloadPdf = async (docNumber, vendorcode) => {
    console.log(docNumber);
    const data = { Data: { VendorCode: vendorcode, DocumentNumber: docNumber } }
    const response = await axios.post(urls.asnQRReportApi, data, {
      headers: {
        "Authorization": auth?.token
      }

    })
    // console.log(response);
    function iterateStrength(obj) {
      if (Array.isArray(obj)) {
        for (const item of obj) {
          iterateStrength(item);
        }
      } else if (typeof obj === 'object' && obj !== null) {
        // console.log(obj);
        for (const key in obj) {
          if (key === 'DISPATCH') {
            // console.log((obj[key]));
            setGridPdfData(obj[key])
            // console.log(Strength: ${ obj[key]});
          } else if (key === 'HEADER') {
            // console.log((obj[key]));
            setFormPdfData(obj[key])
          } else if (key === 'VendorDetail') {
            setvendorpdfdetail(obj[key])

          } else {
            iterateStrength(obj[key]);
          }
        }
      }
    }
    iterateStrength(response.data)






  }

  useEffect(() => {
    if (vendorpdfdetail.length > 0) {
      downloadData(formPdfData, gridPdfData, vendorpdfdetail)
    }
  }, [vendorpdfdetail])
  const downloadData = async (griddata, formdata, vendorDetail) => {
    const doc = new jsPDF({ orientation: 'portrait' });

    console.log('Grid_Data is ' + JSON.stringify(griddata));
    console.log('FormData is ' + JSON.stringify(formdata));

    // const addHeader = () => {
    //   doc.setFont('helvetica');
    //   doc.setFontSize(20);
    //   doc.setTextColor(40);
    //   doc.text(JsonData.header, 10, 20);
    // };
    // console.log(asnList?.[0]?.VendorDetail?.[0]);
    const addVendorInfo = () => {


      doc.setFontSize(12);
      const vendorCodeText = `Vendor Code: ${vendorDetail[0].VendorCode}`;
      doc.text(vendorCodeText, 10, 30);
      // const vendorCodeWidth = doc.getStringUnitWidth(vendorCodeText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      doc.text(`Name: ${vendorDetail[0].VendorName1}`, 10, 35);
      doc.text(`${vendorDetail[0].street}`, 10, 40);
      // doc.text(`Phone : ${'2020202020'}`, 10, 45)
      doc.text(`GST : ${vendorDetail[0].GST}`, 10, 50);
      // doc.text(`ASN : ${val.ASN}`, vendorCodeWidth + 20, 30)



      // const contentWidth = doc.getTextWidth(`Vendor Code:`);

      // doc.text(`ASN Date: ${product.newformdata.DispatchDate}`, contentWidth + 44.3, 35)
      doc.line(10, 53, 195, 53);
    };

    // const addAddressInfo = () => {
    //   doc.setFontSize(15);
    //   doc.setTextColor(40);
    //   doc.text(`From Address:`, 10, 65);
    //   // const contentWidth = doc.getTextWidth(`From Address`);
    //   // doc.line(10, 68, contentWidth, 68)
    //   doc.setFontSize(12);
    //   doc.setTextColor(0);
    //   doc.text(`${product.newformdata.FromAddress}`, 10, 70);
    //   doc.setFontSize(15);
    //   doc.setTextColor(40);
    //   doc.text(`To Address:`, 155, 65);
    //   doc.setFontSize(12);
    //   doc.setTextColor(0);
    //   doc.text(` ${product.newformdata.ToAddress}`, 155, 70)
    // };

    // addHeader();
    const obj = {
      ASN: formdata[0].ASN,
      DispatchNo: formdata[0].DispatchNo,
      VehicleNo: formdata[0].VehicleNo,
      TransporterName: formdata[0].TransporterName,
      MobileNo: formdata[0].MobileNo,
      Vendor: vendorDetail[0].VendorCode
    }
    const qrCodeDataURL = await qrCode.toDataURL(JSON.stringify(obj));
    // const qrCodeDataURL = await qrCode.toDataURL(`http://localhost:8050/data/${product.id}`);
    // Add QR code image to the PDF
    doc.addImage(qrCodeDataURL, 'PNG', 150, 2, 50, 50);

    addVendorInfo();

    // addAddressInfo();
    // Function to add footer content
    const addFooter = (data) => {
      const pageCount = doc.internal.getNumberOfPages();
      doc.setFont('helvetica');
      doc.setFontSize(12);
      doc.setTextColor(40);
      doc.text(JsonData.footer, data.settings.margin.left, doc.internal.pageSize.height - 5);
      doc.line(data.settings.margin.left, doc.internal.pageSize.height - 10, 195, doc.internal.pageSize.height - 10);
      const textWidth = doc.getStringUnitWidth(`Page ${data.pageNumber} of ${pageCount}`) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      doc.text(`Page ${data.pageNumber} of ${pageCount}`, 205 - textWidth - data.settings.margin.right, doc.internal.pageSize.height - 5);
    };
    // Add more additional content as needed

    const addAdditionalSideContent = () => {
      doc.setFont('helvetica');
      doc.setFontSize(12);
      const columns = ['DispatchNo', 'ExpectedDelDate', 'VehicleNo', 'TransporterName', 'MobileNo'];

      const data = [
        [formdata[0].DispatchNo, formdata[0].ExpectedDelDate, formdata[0].VehicleNo, formdata[0].TransporterName, formdata[0].MobileNo,],
      ];

      const options = {
        startY: doc.autoTable.previous.finalY + 20,
        margin: { top: 10 },
        headStyles: {
          lineWidth: 0.1,
          fillColor: [255, 255, 255],
          fontStyle: 'bold',
          textColor: [0, 0, 0],
          fontSize: 10,
        },
        // didDrawPage: function (data) {
        //   addFooter(data);
        // },
        bodyStyles: { textColor: 0, fontSize: 10 },
      };

      doc.autoTable({
        head: [columns],
        body: data,
        ...options,
        margin: { left: 40 }
      });
    };

    // console.log(product.griddata);
    // const addAdditionalContent = () => {
    //   // const contentWidth = doc.getTextWidth(`Mobile No : ${product.newformdata.MobileNO}`);
    //   // const xCoordinate = 30 + contentWidth + 10;
    //   // const yCoordinate = doc.autoTable.previous.finalY + 10;
    //   // const columns = ['UOM', 'ScheduleLine', 'ScheduledQty', 'OrderDate', 'Plant'];
    //   // const rows = [];
    //   // product.griddata.map((val) => {
    //   //   rows.push([val.UOM, val.ScheduleLine, val.ScheduledQty, val.OrderDate, val.Plant]);
    //   // })
    //   // const columnStyles = {
    //   //   0: { width: 30, fontSize: 10 }, // ASN
    //   //   1: { width: 30, fontSize: 10 }, // From Address
    //   //   2: { width: 50, fontSize: 10 }, // To Address
    //   //   3: { width: 20, fontSize: 10 }, // Transporter Name
    //   //   4: { width: 20, fontSize: 10 }, // Plant
    //   //   5: { width: 20, fontSize: 10 },
    //   // };
    //   // const tableOptions = {
    //   //   startY: yCoordinate,
    //   //   // margin: { left: xCoordinate },
    //   //   margin: { left: 10 },
    //   // };

    //   // Add the table to the document
    //   doc.autoTable({
    //     head: [columns],
    //     body: rows,
    //     headStyles: {
    //       fillColor: [230, 230, 230],
    //       textColor: [0, 0, 0],
    //     },
    //     didDrawPage: function (data) {
    //       addFooter(data);
    //     },
    //     // margin: { top: yCoordinate },
    //     // ...tableOptions,
    //     columnStyles: columnStyles
    //   });

    // };



    // // Start adding content to the PDF



    // // Add the table
    const columns = ['OrderID', 'POItem', 'Dispatch Qty', 'InvoiceNo', 'InvoiceDate',];
    const rows = [];
    griddata.map((val) => (
      rows.push([val.OrderID, val.POItem, val.DispatchedQty, val.InvoiceNo, val.InvocieDate])
    ))


    const columnStyles = {
      0: { width: 30, fontSize: 10 }, // ASN
      1: { width: 30, fontSize: 10 }, // From Address
      2: { width: 50, fontSize: 10 }, // To Address
      3: { width: 20, fontSize: 10 }, // Transporter Name
      4: { width: 20, fontSize: 10 }, // Plant
      5: { width: 20, fontSize: 10 },
      6: { width: 20, fontSize: 10 },
    };

    // Add header and footer options to autoTable
    const options = {
      startY: 60, // Adjust this value to change the starting position of the table
      margin: { top: 50, left: 10, right: 15 },
      head: [columns],
      body: rows,
      headStyles: {
        fillColor: [100, 100, 100]
      },
      didDrawPage: function (data) {
        addFooter(data);
      },
      columnStyles: columnStyles
    };
    // Add header
    doc.autoTable(columns, rows, options);

    // addAdditionalContent();

    addAdditionalSideContent();
    // Save the PDF
    doc.save(`DispatchReport_${formdata[0].ASN}.pdf`);
  };

  // console.log(gridPdfData,formPdfData,vendorpdfdetail);

  // console.log(data);

  const handleImageDownload = async (DocumentNumber) => {
    try {
      const Data = { Data: { DocumentNumber: DocumentNumber } }
      // const asnlistData = await axios.get(`http://localhost:8050/data`);
      const imgD = await executeImageData(urls.disptachASNRepostImagesApi, Data, {
        headers: {
          "Authorization": auth?.token
        }

      })
      console.log(imgD);
      // const fileName="DummyImage.png"
      // extractFile(imagedata,fileName)
    } catch (error) {
      console.log(error);
    }


  }

  const downloadFile = (url, filename) => {
    const downloadLink = document.createElement('a');
    downloadLink.target = '_blank';
    downloadLink.href = url;
    downloadLink.download = filename;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };


  const extractFile = (response, filename) => {
    const blob = new Blob([response], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    downloadFile(url, filename);
    return response;
  };
  // console.log(imagedata);

  // const handleImageDownload = async (images) => {
  //   try {
  //     await Promise.all(images.map(async (val) => {
  //       const url = `/ public / images / ${ encodeURIComponent(val.name) } `;

  //       const response = await fetch(url);
  //       if (!response.ok) {
  //         throw new Error(`Failed to download ${ val.name } `);
  //       }

  //       const blob = await response.blob();
  //       const blobUrl = URL.createObjectURL(blob);

  //       const link = document.createElement('a');
  //       link.href = blobUrl;
  //       link.setAttribute('download', val.name);
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     }));
  //   } catch (error) {
  //     console.error('Error downloading images:', error);
  //   }
  // };
  // const renderTooltip = (props) => (

  //   <Tooltip id="button-tooltip" {...props}>
  //     {props[0].image.map((val, index) => (
  //       <>
  //         <span key={index}>{val.name}</span>
  //         <br />
  //       </>


  //     ))}
  //   </Tooltip>
  // );
  // SEARCH FILTER 
  const handleFilter = (e) => {
    const filteredData = asnList.filter(item => item.VendorCode.includes(e.target.value))
    // console.log(filteredData);
    setFilterData(filteredData);
  }




  // setCurrentPage(1);
  //CHECK => NO FILTERDATA =>SHOW MSG =>NO DATA FOUND

  return (
    <div>
      <Card className='shadow'>
        <CardTitle className="border-bottom d-flex justify-content-between p-2 mb-0 bg-secondary-light text-white">
          <h5>Generated ASN List</h5>
        </CardTitle>
        <CardBody>
          <Container>
            <Row>

              <Col md={6} lg={4} xl={3}>
                <InputGroup size='sm' className="mb-2 ">
                  <InputGroup.Text>From Date</InputGroup.Text>
                  <Form.Control name='fdate' onChange={handleDateChange} type='date' aria-label="fdate" />
                </InputGroup>
              </Col>
              <Col md={5} lg={4} xl={3}>
                <InputGroup size='sm' className="mb-2 ">
                  <InputGroup.Text>End Date</InputGroup.Text>
                  <Form.Control name='edate' onChange={handleDateChange} type='date' aria-label="edate" />
                </InputGroup>
                <span style={{ color: "red" }}>

                  {DateError ? "Start date must be less than end date" : ""}
                </span>
              </Col>
              {/* <Col md={2} lg={1} xl={2} className='mb-2 mt-2'>
                <Button size='sm' onClick={handleFetchData} variant="secondary">Retrieve</Button>
              </Col> */}
              {/* <Col md={4} lg={4} xl={4}>
                <InputGroup size='sm' className="mb-3">
                  <InputGroup.Text>Enter ASN Number</InputGroup.Text>
                  <Form.Control name='asn' onChange={handleDateChange} type='text' aria-label="asn" />
                </InputGroup>
              </Col>
              <Col md={4} lg={4} xl={4}>
                <InputGroup size='sm' className="mb-3">
                  <InputGroup.Text>From Date</InputGroup.Text>
                  <Form.Control name='fdate' onChange={handleDateChange} type='date' aria-label="fdate" />
                </InputGroup>
              </Col>
              <Col md={4} lg={4} xl={4}>
                <InputGroup size='sm' className="mb-3">
                  <InputGroup.Text>End Date</InputGroup.Text>
                  <Form.Control name='edate' onChange={handleDateChange} type='date' aria-label="edate" />
                </InputGroup>
              </Col> */}
              <Col md={1} lg={4} xl={3} className='mb-2 '>
                <Button disabled={isLoading ? true : false} onClick={handleRetrieveClick} variant="secondary" size='sm'>
                  {isLoading ? <Spinner size='sm' animation="border" /> : 'Retrieve'}
                </Button>
              </Col>
              <Col md={4} lg={4} xl={3}>
                <Form.Control
                  size='sm'
                  className='mb-2'
                  onChange={handleFilter}
                  placeholder='Search VendorCode....'
                  type='text'
                  aria-label='search'
                  aria-describedby='basic-addon1'
                />
              </Col>
              {/* <Col md={5} lg={3} xl={3}>
                <InputGroup className="mb-2" size='sm'>
                  <InputGroup.Text>From Date</InputGroup.Text>
                  <Form.Control name='fdate' type='date' aria-label="fdate" />
                </InputGroup>
              </Col>
              <Col md={5} lg={3} xl={3}>
                <InputGroup className="mb-2" size='sm'>
                  <InputGroup.Text>End Date</InputGroup.Text>
                  <Form.Control name='edate' type='date' aria-label="edate" />
                </InputGroup>
              </Col> */}
              {/* <Col md={2} lg={1} xl={2} className='mb-2'>
                <Button size='sm' variant="secondary">Retrieve</Button>
              </Col> */}
            </Row>
            {asnList.length > 0 ?
              <>
                <Row className='mt-3'>
                  <Col lg={12}>
                    {/* {asnList?.length > 0 ? */}
                    <>
                      {asnList !== 'NO RECORD' &&

                        <>
                          <div style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                            <Table bordered hover id='my-table' className='text-center'>
                              <thead style={{
                                position: 'sticky',
                                top: '0'
                              }}>
                                <tr>
                                  <th>Vendor</th>
                                  <th>ASN</th>
                                  <th>ASNDate</th>           {/* Added 07_06_2024 */}
                                  <th>From Address</th>
                                  <th>To Address</th>
                                  <th>Transporter Name</th>
                                  {/* <th>MobileNo</th> */}
                                  <th>ExpectedDelDate</th>
                                  <th>VehicleNo</th>
                                  {/* <th>Status</th> */}

                                </tr>
                              </thead>
                              <tbody>

                                {(filterData.length > 0 ? filterData : asnList).map((val, index) => {
                                  return (
                                    <tr>
                                      <td>{val.VendorCode}</td>
                                      <td >{val.DocumentNumber}</td>
                                      <td >{val.ASNDate}</td>            {/* Added 07_06_2024 */}
                                      <td>{val.FromAddress}</td>
                                      <td>{val.ToAddress}</td>
                                      <td>{val.TransporterName}</td>
                                      {/* <td>{asnList.MobileNo}</td> */}
                                      <td>{val.ExpectedDelDate}</td>
                                      <td>{val.VehicleNo}</td>


                                      <td>
                                        <Button onClick={() => donloadPdf(val.DocumentNumber, val.VendorCode)} className='rounded-circle' variant='success'>
                                          <i className="bi bi-download"></i>
                                        </Button>
                                        {/* <div>
                              {asnList[0].image.map((image, index) => (
                                <Button onClick={() => handleImageDownload(image.name)}>Download</Button>
                              ))}
                            </div> */}


                                      </td>
                                    </tr>
                                  )
                                })}

                              </tbody>
                            </Table >
                          </div>
                        </>
                      }
                      {asnList === 'NO RECORD' && 'NO RECORD'}
                    </>
                    {/* : null} */}
                  </Col>
                </Row>
              </>
              : null
            }

          </Container>
        </CardBody >
      </Card >
    </div >
  );
}

export default DispatchReport;