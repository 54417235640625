import React, { useState } from 'react'
import { Button, Spinner } from 'react-bootstrap';
import CustomToast from '../../components/toast/Toast';
import axios from 'axios';
import urls from '../../url';
import { useAuth } from '../../components/dashboard/context/AuthContext';


function RunSchedule() {
  const [auth] = useAuth();
  const [showToast, setShowToast] = useState(false)
  const [loading,setloading] = useState(false)
  // console.log(auth?.token);
  const handlesubmit = async () => {
    try {
      setloading(true)
      const response = await axios.post(urls.runScheduleApi, null, {
        headers: {
          "Authorization": auth?.token
        }
      });
      if (response.data.Data === "Schedule Run Successfully !") {
        setShowToast(true)
        setloading(false)
      }
      // console.log(response.data.Data);

    } catch (error) {
      console.log(error);
      setloading(false)
    }

  }
  return (
    <div style={{ textAlign: 'center', margin: "40px" }}>Click To Run.....
      <Button size='sm' disabled={loading ? true : false} variant='secondary' onClick={handlesubmit}>
        {loading ? <Spinner size='sm' animation='border' /> : 'Schedule'}
        </Button>
      <div className='d-flex justify-content-center mt-2'>
        <CustomToast
          position="top-left"
          textMessage={'Schedule Run Successfully !'}
          setShowToast={setShowToast}
          showToast={showToast} />
      </div>


    </div>
  )
}

export default RunSchedule
