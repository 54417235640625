
import React, { createContext, useState, useContext, useEffect } from 'react'
export const Auth = createContext();

function AuthContext({ children }) {
    const [auth, setauth] = useState({
        user: null,
        token: '',
        admin: null,
        username: ''
    })
    useEffect(() => {
        const data = localStorage.getItem('auth');
        if (data) {
            const parseData = JSON.parse(data);
        //    const userParseData = JSON.parse(parseData.user);
        //    console.log(userParseData);
           setauth((pre) => ({
            ...pre,
            user: parseData.user,
            token: parseData.token,
            admin:parseData.vType,
        }))
        };
        // eslint-disable-next-line
    }, [auth?.token])
    return (
        <>
            <Auth.Provider value={[auth, setauth]}>
                {children}
            </Auth.Provider>
        </>
    )
};

// USE AUTH VALUE
const useAuth = () => useContext(Auth);
export { useAuth };

export default AuthContext;
