import React from 'react';
import { ToastContainer, Toast} from 'react-bootstrap';

function CustomToast({position, setShowToast,showToast ,textMessage,Error,margintop}) {

    return (
        <>
            <ToastContainer className={`mb-1 ${margintop}?mt-${margintop}:''` } position={ position ||"bottom-center"}>
                <Toast onClose={() => setShowToast(false)} show={showToast} delay={5000} autohide>
                    <Toast.Header className={`text-white ${Error?Error:'bg-success'}`}>
                        <strong className="m-auto">{textMessage}  </strong>
                    </Toast.Header>
                </Toast>
            </ToastContainer>
        </>
    )
}

export default CustomToast