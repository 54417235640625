import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate ,useLocation} from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
// import axios from 'axios';

function PrivateRoute() {
  const [token, settoken] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [auth] = useAuth()
  useEffect(() => {
    // console.log(data);
    if (auth?.token) {
      // axios.defaults.headers.common["Authorization"] = `Bearer  ${auth?.token}`
      settoken(true)
      navigate(`${location.pathname}`)
    } else {
      navigate('/')
    }
  }, [auth?.token])
  return (
    <>
      {token && <Outlet />}
    </>
  )
}

export default PrivateRoute