import React from 'react'

function Footer() {
  return (
    // <div className="bg-sidebar-custom text-light p-2">
    //   <p><small>Powered By Viznon Pvt Ltd. &copy; Copyright 2024 | Privacy Policy</small></p>
    // </div>
    <div className="bg-sidebar-custom text-light d-flex align-items-center justify-content-center p-2" style={{ minHeight: '10px' }}>
      <p className="mb-0"><small> &copy; Guru Nanak Auto Enterprises Ltd. All Rights Reserved (Terms of Use) <br />
    Developed and Managed by Viznon Private Limited</small></p>
    </div>
  )
}

export default Footer