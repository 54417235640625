// import axios from 'axios';
import React  from 'react'
// import { Card, Container, Row, Col, CardBody, CardTitle, CardSubtitle, Table } from 'react-bootstrap'

// import { Label } from 'reactstrap';
// import { useAuth } from '../../components/dashboard/context/AuthContext';
import ProfileCard from '../../components/ProfileCard';
// import "../../assets/images/users/new.jpg"

function MyAccountDetail() {
    // const [users, setusers] = useState([])
    // const [auth] = useAuth()

    // useEffect(() => {

    //     const fetchData = async () => {
    //         try {
    //             const response = await axios.get(`http://localhost:8080/allUsers?userId=${auth.user}`);
    //             console.log('Data received from API:', response.data);
    //             setusers(response.data);
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //         }
    //     };

    //     fetchData();
    // }, []);


    return (
        <div>
            <ProfileCard />
            {/* <Container>
                <Card className='shadow'>
                    {/* <Card.Body>
                        {users.map((userData, index) => (
                            <div key={index}>
                                <h4>{userData.name}</h4>
                                {/* <span>Administration</span> */}
            {/* <hr style={{ width: "18%" }} />
                                <Container >
                                    <Row >
                                        <Col>
                                            <Label inline style={{ fontWeight: "600" }}>Name:</Label>
                                            <h6>{userData.name}</h6>
                                            <Label className='mt-4' inline style={{ fontWeight: "600" }}>Email:</Label>
                                            <h6>{userData.email}</h6>
                                        </Col>
                                        {userData.Addresses.map((address, index) => (
                                            <Col key={index}>
                                                <Label inline style={{ fontWeight: "600" }}>Address:</Label>
                                                <h6>{address.STREET}</h6>
                                                <Label className='mt-4' inline style={{ fontWeight: "600" }}>City:</Label>
                                                <h6>{address.City}</h6>
                                            </Col>
                                        ))}
                                        <Col >
                                            <Label inline style={{ fontWeight: "600" }}>Mobile:</Label>
                                            <h6>{userData.MOB_NUMBER}</h6>
                                            <Label className='mt-4' inline style={{ fontWeight: "600" }}>Postel code:</Label>
                                            <h6>{userData.PSTLZ}</h6>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        ))}
                    </Card.Body> */}
            {/* </Card> */}



            {/* <Row>
                    <Col>
                        <Card className='shadow' >
                            <CardBody>
                                <h2>Recent Activity</h2>
                                <Table className="no-wrap mt-3 align-middle" id='table' responsive border>
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Last login time</th>
                                            <th>Last Logout time</th>
                                            <th>Ip Address</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((userData, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {userData.id}
                                                </td>
                                                <td>{userData.last_login}</td>
                                                <td>{userData.recent_login}</td>
                                                <td>{userData.ip_address}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </CardBody>

                        </Card>
                    </Col>
                </Row> */}

            {/* </Container>  */}
        </div>
    )
}

export default MyAccountDetail