import React, { useState } from 'react';
import { Container, Row, Col, Card, Form, Button, FormGroup, Image } from 'react-bootstrap';
import { useFormik } from 'formik'
import logo from '../assets/images/logos/gna-logo.gif'
import { SignUpSchema } from './SignUpSchema';
// import useAxiosPost from '../hooks/useAxiosPost';
import { useAuth } from '../components/dashboard/context/AuthContext';
import urls from '../url';
import axios from 'axios';
import CustomToast from '../components/toast/Toast';

function SignUp() {
    // const [loginError, setLoginError] = useState('');
    // const [loginBlockError, setLoginBlockError] = useState('');
    // const { data, error, loading, execute } = useAxiosPost();
    const [auth] = useAuth()
    const LoginInitialvalue = {
        VendorCode: '',
        VendorSaltPassword: '',
        VendorName: '',
        VendorGSTNo: '',
        VendorIPAddress: '',
        VendorEMail: '',
        VendorType: 'VENDOR'
    };
    const[SuccessSignup,setSuccessSignup]= useState("")
    const[ErrorSignup,setErrorSignup]= useState("")
    const[showToast,setshowToast]= useState(false)
    const { handleBlur, handleChange, handleSubmit,resetForm, values, touched, errors } = useFormik({
        initialValues: LoginInitialvalue,
        validationSchema: SignUpSchema,
        onSubmit: async (values) => {
            let newobj = { Data: values }
            // console.log(JSON.stringify(newobj));
            try {
                const response = await axios.post(urls.registerAdmin,
                    newobj,
                    {
                        headers: {
                            "Authorization": auth?.token
                        }
                    }
                );
                // const responseData = response.data;
                // console.log(response.data);
           resetForm()
                // console.log(response.data);
                if (response.data[0].Status === "Successfull") {
                    setErrorSignup('')
                    setSuccessSignup(response.data[0].Msg)
                    setshowToast(true)
                }else{
                    setSuccessSignup('')
                    setErrorSignup(response.data[0].Msg)
                    
                    setshowToast(true)
                }
            } catch (error) {
                console.error(error);
            }

        }

    })
    return (
        // <div style={{ backgroundColor: '#E7E9EB' }}>
        <Container fluid style={{ backgroundColor: '#E7E9EB' }}>
            <Row className='d-flex justify-content-center align-items-center'>
                <Col col='12'>
                    <Card className='bg-white my-5 mx-auto shadow' style={{ borderRadius: '.5rem', maxWidth: '450px' }}>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Card.Body className='p-5 w-100 d-flex flex-column '>
                                <Image src={logo} fluid alt="Logo" style={{ height: '80px', maxWidth: '400px', margin: 'auto', width: '100%' }} className="mb-3" />
                                <FormGroup controlId="validationFormik01" >
                                    {/* <FloatingLabel controlId="formControlLgEmail" label="Vendor Name" > */}
                                    <Form.Label >Vendor Code</Form.Label>
                                    <Form.Control onChange={handleChange} onBlur={handleBlur}
                                        value={values.VendorCode}
                                        type="text"
                                        name='VendorCode'
                                        className={`mb-2 ${touched.VendorCode && (errors.VendorCode) ? 'is-invalid' : ''}`}
                                        size="sm"
                                        isValid={(touched.VendorCode && !errors.VendorCode)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {touched.VendorCode && errors.VendorCode}
                                    </Form.Control.Feedback>
                                    {/* </FloatingLabel> */}
                                </FormGroup>
                                {/* </FloatingLabel> */}
                                <Form.Label >Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.VendorSaltPassword}
                                    name='VendorSaltPassword'
                                    className={`mb-2 ${touched.VendorSaltPassword && (errors.VendorSaltPassword) ? 'is-invalid' : ''}`}
                                    size="sm"
                                    isValid={(touched.VendorSaltPassword && !errors.VendorSaltPassword)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {(touched.VendorSaltPassword && errors.VendorSaltPassword)}
                                </Form.Control.Feedback>

                                <FormGroup controlId="validationFormik02">
                                    {/* <FloatingLabel controlId="formControlLgEmail" label="Vendor Code" > */}
                                    <Form.Label >Vendor Name</Form.Label>
                                    <Form.Control onChange={handleChange} onBlur={handleBlur}
                                        value={values.VendorName}
                                        // maxLength='10'
                                        type="text"
                                        name='VendorName'
                                        // className={`mb-2 ${touched.VendorName && (errors.VendorName) ? 'is-invalid' : ''}`}
                                        size="sm"
                                        // isValid={(touched.VendorName && !errors.VendorName)}
                                    />
                                    {/* <Form.Control.Feedback type="invalid">
                                        {touched.VendorName && errors.VendorName}
                                    </Form.Control.Feedback> */}
                                    {/* </FloatingLabel> */}
                                </FormGroup>



                                <FormGroup controlId="validationFormik03">
                                    {/* <FloatingLabel controlId="formControlLgEmail" label="Vendor GST No" > */}
                                    <Form.Label >Vendor GST No</Form.Label>
                                    <Form.Control onChange={handleChange} onBlur={handleBlur}
                                        value={values.VendorGSTNo}
                                        // maxLength='10'
                                        type="text"
                                        name='VendorGSTNo'
                                        // className={`mb-2 ${touched.VendorGSTNo && (errors.VendorGSTNo) ? 'is-invalid' : ''}`}
                                        size="sm"
                                        // isValid={(touched.VendorGSTNo && !errors.VendorGSTNo)}
                                    />
                                    {/* <Form.Control.Feedback type="invalid">
                                        {touched.VendorGSTNo && errors.VendorGSTNo}
                                    </Form.Control.Feedback> */}
                                    {/* </FloatingLabel> */}
                                </FormGroup>

                                {/* <FormGroup controlId="validationFormik01"> */}
                                {/* <FloatingLabel controlId="formControlLgEmail" label="Vendor GST No" > */}
                                {/* <Form.Label >Vendor Ip Address</Form.Label>
                                    <Form.Control onChange={handleChange} onBlur={handleBlur}
                                        value={values.VendorIPAddress}
                                        maxLength='10'
                                        type="text"
                                        name='VendorIPAddress'
                                        className={`mb-2 ${touched.VendorIPAddress && (errors.VendorIPAddress || loginError) ? 'is-invalid' : ''}`}
                                        size="sm"
                                        isValid={(touched.VendorIPAddress && !errors.VendorIPAddress) || loginError}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {touched.VendorIPAddress && errors.VendorIPAddress}
                                    </Form.Control.Feedback>
                                    </FloatingLabel> */}
                                {/* </FormGroup> */}

                                <FormGroup controlId="validationFormik04">
                                    {/* <FloatingLabel controlId="formControlLgEmail" label="Vendor GST No" > */}
                                    <Form.Label >Vendor Email</Form.Label>
                                    <Form.Control onChange={handleChange} onBlur={handleBlur}
                                        value={values.VendorEMail}
                                        // maxLength='10'
                                        type="email"
                                        name='VendorEMail'
                                        // className={`mb-2 ${touched.VendorEMail && (errors.VendorEMail) ? 'is-invalid' : ''}`}
                                        size="sm"
                                        // isValid={(touched.VendorEMail && !errors.VendorEMail)}
                                    />
                                    {/* <Form.Control.Feedback type="invalid">
                                        {touched.VendorEMail && errors.VendorEMail}
                                    </Form.Control.Feedback> */}
                                    {/* </FloatingLabel> */}
                                </FormGroup>
                                {/* <Row className="mb-3">
                                    {loginBlockError && (
                                        <div className="alert alert-danger mt-2" role="alert">
                                            {loginBlockError}
                                        </div>
                                    )}
                                </Row> */}
                                <Button variant="secondary" type='submit' size='sm'>
                                    Create New Vendor
                                </Button>
                            </Card.Body>
                        </Form>
                        {SuccessSignup ? (
                            <CustomToast textMessage={SuccessSignup} setShowToast={setshowToast} showToast={showToast} />
                        ) : (
                            ErrorSignup && (
                                <CustomToast  textMessage={ErrorSignup} setShowToast={setshowToast} showToast={showToast} Error={`bg-danger`} />
                            )
                            )}
                    </Card>
                </Col>
            </Row>
        </Container>
        // </div>
    )
}

export default SignUp