import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { Card, CardBody, CardTitle } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Spinner} from 'react-bootstrap';
// import Loader from '../../layouts/loader/Loader';
import { useAuth } from '../../components/dashboard/context/AuthContext';
// import useAxios from '../../hooks/useAxios';
import PaginationComponent from '../../components/Pagination';
import CustomToast from '../../components/toast/Toast';
import urls from '../../url';
import axios from 'axios';
// import { json } from 'react-router-dom';





const GoodsReceiptReport = () => {
    const [datas, setdatas] = useState([])
    const [auth] = useAuth()

    const [formData, setFormData] = useState({
        inputValue: {},
        selectedDate: {}
    })
    const [PoToastMsg, setPoToastMsg] = useState('')
    const [showToast, setShowToast] = useState(false);
    const [product, setproducts] = useState([])
    const [isLoading, setIsLoading] = useState(false); // 08-04-2024
    const [selectedRows, setSelectedRows] = useState([]);
    const [filterData] = useState([])
    // const [filterCheck] = useState(true);
    // const [counter, setCounter] = useState(1000);
    const currentDate = new Date().toISOString().split('T')[0];
    const [currentPage, setCurrentPage] = useState(1);
    // const [daaat, setdaa] = useState("")
    const [dateRange, setDateRange] = useState({
        VendorCode: "",
        fdate: "",
        edate: ""

    })
    const[DateError,setDateError]=useState("")
    // const { data, error, loading } = useAxios(${urls.acceptanceOfPoApi}${auth.user})



    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setDateRange((prev) => ({
            ...prev,
            [name]: value
        }));
    };


    // const handleInputChange = (e, id, orderQty, pendingQuantity) => {
    //     const { name, value } = e.target
    //     if (name === 'inputValue') {
    //         if (Number(value) > (Number(pendingQuantity) || Number(orderQty))) alert(`Should be less then pending quantity ${(Number(pendingQuantity) || Number(orderQty))}`);
    //         else {
    //             setFormData((prevFormData) => ({
    //                 ...prevFormData,
    //                 inputValue: {
    //                     ...prevFormData.inputValue,
    //                     [id]: Number(value)
    //                 }
    //             }));
    //         }
    //     } else {
    //         value < currentDate
    //             ? alert('Please select a date on or after the current date.')
    //             : setFormData((prevFormData) => ({
    //                 ...prevFormData,
    //                 selectedDate: {
    //                     ...prevFormData.selectedDate,
    //                     [id]: value,
    //                 },
    //             }));
    //     }
    // };
    // PAGINATION 
    const itemsPerPage = 13;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedData = (filterData.length > 0 ? filterData : product).slice(startIndex, endIndex);
    const totalPages = Math.ceil((filterData.length > 0 ? filterData : product).length / itemsPerPage);
    const pagesToShow = 5;

    // const handleChange = (e) => {
    //     const filteredData = product.filter(item => {
    //         return (
    //             item.PONumber.toLowerCase().includes(e.target.value) ||
    //             item.Vendor.toLowerCase().includes(e.target.value) ||
    //             item.Org.toLowerCase().includes(e.target.value) ||
    //             item.LineItem.toLowerCase().includes(e.target.value) ||
    //             item.Plant.toLowerCase().includes(e.target.value) ||
    //             item.OrderQty.toLowerCase().includes(e.target.value) ||
    //             item.UOM.toLowerCase().includes(e.target.value)
    //         );
    //     }
    //     );
    //     setFilterData(filteredData);
    //     setCurrentPage(1);
    //     //CHECK => NO FILTERDATA =>SHOW MSG =>NO DATA FOUND
    //     setFilterCheck(filteredData.length > 0 ? true : false);
    // };


    //// get data api
    const vendorid = JSON.parse(auth?.user);
    const vendorcode = vendorid?.vType === 'VENDOR' ? vendorid?._id : ''
    const vendorByinput_not = dateRange.VendorCode.length > 0 ? dateRange.VendorCode : vendorcode
    const handleFetchData = async () => {

        const startDate = new Date(dateRange.fdate).toISOString().split('T')[0]
        const endDate = new Date(dateRange.edate).toISOString().split('T')[0]
        // console.log(startDate,dateRange.edate);
        // const vendorid = JSON.parse(auth?.user);
        // const vendorcode = vendorid?._id

        if (dateRange.fdate>dateRange.edate) {
                    setDateError('Start date must be less than end date');
                    setproducts([])
                  
        }else{
            
        
        const obj = {
            Data: {
                Vendor: vendorByinput_not,
                DateFrom: startDate,
                DateTo: endDate
            }
        };
        console.log(obj);
        setIsLoading(true)
        try {

            const response = await axios.post(urls.goodsReceiptReportApi,
                obj,
                {
                    headers: {
                        "Authorization": auth?.token
                    }
                }
            );

            // console.log(response.data);
            function iterateStrength(obj) {
                if (Array.isArray(obj)) {
                    for (const item of obj) {
                        iterateStrength(item);
                    }
                } else if (typeof obj === 'object' && obj !== null) {
                    // console.log(obj);
                    for (const key in obj) {
                        if (key === 'PO') {
                            // console.log((obj[key]));
                            setIsLoading(false)
                            setproducts(obj[key])
                            setDateError("")
                            // console.log(Strength: ${ obj[key]});
                        } else {
                            iterateStrength(obj[key]);
                        }
                    }
                }
            }
            iterateStrength(response.data)
            // setproducts(responseData)
            // console.log(product);
        } catch (error) {
            console.error(error);
            setIsLoading(false)
        }
    }
    };

    // checkbox selections 
    // const handleCheckBoxChange = (id) => {
    //     if (id === 'allselect') {
    //         // Check if all visible rows are selected
    //         const allVisibleRowsSelected = paginatedData.every((val) =>
    //             selectedRows.includes(`${val.PONumber + "" + val.POITEM}`)
    //         );
    //         // Toggle select all on the current page
    //         const newSelectedRows = allVisibleRowsSelected
    //             ? selectedRows.filter((rowId) => !paginatedData.map((val) => `${val.PONumber + "" + val.POITEM}`).includes(rowId))
    //             : Array.from(new Set([...selectedRows, ...paginatedData.map((val) => `${val.PONumber + "" + val.POITEM}`)]));
    //         // console.log(newSelectedRows);
    //         setSelectedRows(newSelectedRows);
    //     } else {
    //         const isSelected = selectedRows.includes(id);
    //         const newSelectedRows = isSelected
    //             ? selectedRows.filter((rowId) => rowId !== id)
    //             : Array.from(new Set([...selectedRows, id]));

    //         setSelectedRows(newSelectedRows);
    //         console.log(newSelectedRows);
    //     }
    // }

    const handleSubmit = async (e) => {
        const startDate = new Date(dateRange.fdate).toISOString().split('T')[0]
        const endDate = new Date(dateRange.edate)
        console.log(startDate, endDate);
        // const filterData = product.filter((val) => selectedRows.includes(`${val.PONumber + "" + val.PoItem}`));

        // const sendata = {
        //     Data: []
        // };

        // filterData.map(async (val, key) => {
        //     const newObject = {
        //         Vendor: val.Vendor,
        //         PurchasingDocType: val.PurchasingDocType,
        //         PONumber: val.PONumber,
        //         PoItem: val.PoItem,
        //         ExpectedDate: formData.selectedDate[`${val.PONumber + "" + val.PoItem}`],
        //         AcceptedQty: formData.inputValue[`${val.PONumber + "" + val.PoItem}`]
        //     };
        //     sendata.Data.push(newObject);
        // });

        // console.log(JSON.stringify(sendata));
        // const response = await axios.post(urls.acceptancepolistpost,
        //     sendata,
        //     {
        //         headers: {
        //             "Authorization": auth?.token
        //         }
        //     }
        // );
        // console.log(response.data[0]);
        // if (response.data[0].Status === "Success") {
        //     setPoToastMsg(`${response.data[0].Msg} :${response.data[0].DocumentNumber
        //         }`);
        //     setShowToast(true);
        //     handleFetchData()
        //     setSelectedRows([]);
        //     setFormData((pre) => ({
        //         ...pre,
        //         inputValue: {},
        //         selectedDate: {}
        //     }))
        // } else {

        // }
        // console.log(response.data);
        //     const updatedProduct = product.filter((val) => !selectedRows.includes(${val.PONumber + "" + val.PoItem}));
        // setProduct(updatedProduct);
    };



    return (
        <>
            <Card className=' shadow' >
                <CardTitle className="border-bottom d-flex justify-content-between p-2 mb-0 bg-secondary-light text-white ">
                    <h5>Goods Receipt Report</h5>
                </CardTitle>
                <CardBody>

                <Container >
                    <Row>
                        <Col md={4} lg={4} xl={3}>
                            <InputGroup size='sm' className="mb-2">
                                <InputGroup.Text>Vendor Code</InputGroup.Text>
                                <Form.Control name='VendorCode' onChange={handleDateChange} type='text' aria-label="VendorCode" />
                            </InputGroup>
                        </Col>
                        <Col md={6} lg={4} xl={3}>
                            <InputGroup size='sm' className="mb-2 ">
                                <InputGroup.Text>From Date</InputGroup.Text>
                                <Form.Control name='fdate' onChange={handleDateChange} type='date' aria-label="fdate"
                               
                                />
                               <span style={{color:"red"}}>

                                {DateError?"Start date must be less than end date":""}
                               </span>
                            
                              
                            </InputGroup>
                        </Col>
                        <Col md={5} lg={4} xl={3}>
                            <InputGroup size='sm' className="mb-2">
                                <InputGroup.Text>End Date</InputGroup.Text>
                                <Form.Control name='edate' onChange={handleDateChange} type='date' aria-label="edate" />
                            </InputGroup>
                        </Col>
                        <Col md={2} lg={1} xl={2} className='mb-2'>
                            <Button size='sm' disabled={isLoading ? true : false} onClick={handleFetchData} variant="secondary">
                                {isLoading ? <Spinner size='sm' animation='border' /> : 'Retrieve'}
                            </Button>
                        </Col>
                        {/* <Col className='text-end mt-3'>
                            {selectedRows.length > 0 && (
                                <Badge bg="secondary">{selectedRows.length} selected</Badge>
                            )}
                        </Col> */}
                    </Row>
                </Container>
                    
                </CardBody>
   
                    {product?.length > 0 && product !== "NO GRN RECORD FOUND"?
                        (
                            <>
                                <Table responsive size='sm' bordered className='mt-1' hover>
                                    <thead>
                                        <tr className='text-center'>
                                            {/* <th>
                                                <Form.Group id='formGridCheckbox'>
                                                    <Form.Check
                                                        name='allselect'
                                                        type='checkbox'
                                                        onChange={() => handleCheckBoxChange('allselect')}
                                                        checked={paginatedData.length > 0 && paginatedData.every((val) => selectedRows.includes(`${val.PONumber + "" + val.POITEM}`))}
                                                    />
                                                </Form.Group>
                                            </th> */}
                                            <th>PurchasingDocType</th>
                                            <th>Vendor</th>
                                            <th>CreatedBy</th>
                                            <th>PurchOrganization</th>
                                            {/* <th>DocumentDate</th> */}

                                            <th>PONumber</th>
                                            <th>POITEM</th>
                                            <th>MATERIALCODE</th>
                                            <th>PLANT</th>
                                            <th>OrderQuantity</th>
                                            <th>OrderUnit</th>

                                            <th>MaterialType</th>
                                            <th>DeliveryDate</th>
                                            <th>PurchaseOrderDate</th>
                                            <th>ScheduledQTY</th>
                                            <th>QuantityDel</th>
                                            {/* <th>OrderQty</th>
                                            <th>OrderUnit</th>
                                            <th>AcceptedQty</th>
                                            <th>PendingQty</th>
                                            <th>Expected Delivery Date</th>
                                            <th>AcceptQty</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {product !== "NO GRN RECORD FOUND" && <>
                                            {paginatedData.map((val, index) => (
                                                <tr key={index} className={`text-center`}>

                                                    {/* <td>
                                                        <Form.Check
                                                            type='checkbox'
                                                            onChange={() => handleCheckBoxChange(`${val.PONumber + "" + val.POITEM}`)}
                                                            checked={selectedRows.includes(`${val.PONumber + "" + val.POITEM}`)}
                                                        />
                                                    </td> */}
                                                    <td>{val.PurchasingDocType}</td>
                                                    <td>{val.Vendor}</td>
                                                    <td>{val.CreatedBy}</td>
                                                    <td>{val.PurchOrganization}</td>
                                                    {/* <td>
                                                        <Form.Group key={index} className={`text-center ${selectedRows.includes(val.id) ? 'table-active' : ''}`}>
                                                            <Form.Control
                                                                min={currentDate}
                                                                // value={selectedDate[val.id]}
                                                                value={formData.selectedDate[val.id] !== undefined && formData.selectedDate[`${val.id}`]}
                                                                onChange={(e) => handleInputChange(e, val.id)}
                                                                type='date'
                                                                name='selectedDate'
                                                            />
                                                        </Form.Group>
                                                    </td>
                                                    <td>
                                                        <Form.Group key={index} className={`text-center ${selectedRows.includes(val.id) ? 'table-active' : ''}`}>
                                                            <Form.Control
                                                             
                                                                // value={formData.inputValue[val.id] !== undefined ? formData.inputValue[val.id] : ""}
                                                                value={formData.inputValue[`${val.id}`] !== undefined ? formData.inputValue[val.id].toString() : " "}
                                                                onChange={(e) => handleInputChange(e, val.id, val.OrderQuantity, val.PendingQty)}
                                                                onInput={(e) => {
                                                                    e.target.value = e.target.value.replace(/[^\d.]/g, '');
                                                                }}
                                                                type='text'
                                                                name='inputValue'
                                                            />
                                                        </Form.Group>
                                                    </td> */}
                                                    {/* <td>{new Date(val.DocumentDate).toISOString().split('T')[0]}</td> */}

                                                    <td>{val.PONumber}</td>
                                                    <td>{val.POITEM}</td>
                                                    <td>{val.MATERIALCODE}</td>
                                                    <td>{val.PLANT}</td>
                                                    <td>{val.OrderQuantity}</td>
                                                    <td>{val.OrderUnit}</td>

                                                    <td>{val.MaterialType}</td>
                                                    <td>{val.DeliveryDate}</td>
                                                    <td>{val.PurchaseOrderDate}</td>
                                                    <td>{val.ScheduledQTY}</td>
                                                    <td>{val.QuantityDel}</td>
                                                    {/* <td style={{ whiteSpace: 'nowrap' }}>{val.MaterialCode}</td>
                                                    <td>{val.OrderQuantity}</td>
                                                    <td>{val.OrderUnit}</td>
                                                    <td>{val.AcceptedQty}</td>
                                                    <td>{val.PendingQty === '' ? val.OrderQuantity : val.PendingQty}</td> */}

                                                    {/* <td>
                                                        <Form.Group key={index} className={`text-center ${selectedRows.includes(`${val.PONumber + "" + val.PoItem}`) ? 'table-active' : ''}`}>
                                                            <Form.Control
                                                                min={currentDate}
                                                                // value={selectedDate[val.id]}
                                                                value={formData.selectedDate[`${val.PONumber + "" + val.PoItem}`] !== undefined && formData.selectedDate[`${val.PONumber + "" + val.PoItem}`]}
                                                                onChange={(e) => handleInputChange(e, `${val.PONumber + "" + val.PoItem}`)}
                                                                type='date'

                                                                name='selectedDate'
                                                            />
                                                        </Form.Group>
                                                    </td>
                                                    <td>
                                                        <Form.Group key={index} className={`text-center ${selectedRows.includes(`${val.PONumber + "" + val.PoItem}`) ? 'table-active' : ''}`}>
                                                            <Form.Control
                                                                maxLength='5'
                                                                // value={formData.inputValue[val.id] !== undefined ? formData.inputValue[val.id] : ""}
                                                                value={formData.inputValue[`${val.PONumber + "" + val.PoItem}`] !== undefined ? formData.inputValue[`${val.PONumber + "" + val.PoItem}`].toString() : " "}
                                                                onChange={(e) => handleInputChange(e, `${val.PONumber + "" + val.PoItem}`, val.OrderQuantity, val.PendingQty)}
                                                                onInput={(e) => {
                                                                    e.target.value = e.target.value.replace(/[^\d.]/g, '');
                                                                }}
                                                                type='text'
                                                                name='inputValue'
                                                            />
                                                        </Form.Group>
                                                    </td> */}
                                                </tr>
                                            ))}
                                        </>}
                                        {product === "NO GRN RECORD FOUND" && "NO GRN RECORD FOUND"}




                                    </tbody>
                                </Table>
                                <Container>
                                    <Row className='mt-3'>
                                        <Col md={4} className='text-center '>
                                            <PaginationComponent
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                totalPages={totalPages}
                                                pagesToShow={pagesToShow}
                                            />
                                        </Col>
                                        {/* <Col className='text-end'>
                                            <Button variant='danger' onClick={() => {
                                                setSelectedRows([]); setFormData((pre) => ({
                                                    ...pre, inputValue: {},
                                                    selectedDate: {}
                                                }))
                                            }} size='sm'>
                                                clear
                                            </Button>
                                            <Button variant='secondary' className='ms-2' onClick={handleSubmit} size='sm'>
                                                submit
                                            </Button>
                                        </Col> */}
                                        {/* <CustomToast textMessage={PoToastMsg} setShowToast={setShowToast} showToast={showToast} /> */}
                                    </Row>
                                </Container>
                            </>
                        ) : product === "NO GRN RECORD FOUND" && "NO GRN RECORD FOUND"}
               
            </Card>
        </>
    );
};

export default GoodsReceiptReport