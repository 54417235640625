import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Navbar,
  Collapse,
  Nav,
  NavbarBrand,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Button,
} from "reactstrap";
// import { ReactComponent as LogoWhite } from "../assets/images/logos/adminprowhite.svg";
import user1 from "../assets/images/users/user4.jpg";
import log from '../assets/images/logos/gna-logo.gif'
import { useAuth } from "../components/dashboard/context/AuthContext";
import { NavItem } from "react-bootstrap";

const Header = () => {
  const [auth, setauth] = useAuth()
  const [isOpen, setIsOpen] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const navigate = useNavigate();
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const Handletoggle = () => {
    setIsOpen(!isOpen);
  };
  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };
  const handlLogOut = () => {
    setauth({
      ...auth,
      user: null,
      token: '',
      admin: null
    });
    localStorage.removeItem('auth')
    navigate('/')
  }
  const handlechange = () => {
    navigate("/dashboard/change-password"); // Navigating directly to the desired route
  }
  const userParse = JSON.parse(auth?.user)
  return (
    <Navbar color="white" light expand="md" className="fix-header shadow sticky-top">
      {/* <Container fluid> */}
      {/* <Row> */}
      {/* <Col lg={2}> */}
      <div className="d-flex align-items-center" style={{ minWidth: '260px', maxHeight: '40px' }} >
        <div className="d-lg-block d-none me-5 pe-3 flex-grow-1" style={{ height: '100%' }}>
          {/* <Logo /> */}
          <div className="d-flex justify-content-around align-items-center">
            <img src={log} alt="Logo" style={{ width: 'auto', maxHeight: '40px' }} />
            {/* <h6 style={{ fontWeight: 'bold' }}>VMS</h6> */}
          </div>

          {/* Viznon */}
        </div>
        <NavbarBrand href="/">
          {/* <LogoWhite className="d-lg-none" /> */}
          <img src={log} alt="Logo" className="d-lg-none" style={{ width: 'auto', maxHeight: '40px' }} />
        </NavbarBrand>
        <Button
          color="primary"
          className=" d-lg-none"
          onClick={() => showMobilemenu()}
        >
          <i className="bi bi-list"></i>
        </Button>
      </div>
      <div className="hstack gap-2">
        <Button
          color="primary"
          size="sm"
          className="d-sm-block d-md-none"
          onClick={Handletoggle}
        >
          {isOpen ? (
            <i className="bi bi-x"></i>
          ) : (
            <i className="bi bi-three-dots-vertical"></i>
          )}
        </Button>
      </div>
      {/* </Col> */}

      {/* <Col> */}

      <Collapse navbar isOpen={isOpen}>
        <Nav className="ms-auto me-2" navbar>
          <div className='d-flex flex-column text-center' style={{ color: '#002366', fontWeight: 'bold' }}>
            <NavItem className="small">
              {userParse?.vName.toUpperCase()}
            </NavItem>
            <NavItem className="small">
              {userParse?._id}
            </NavItem>
          </div>
          {/* <NavItem>
                  <Link to="/about" className="nav-link">
                    About
                  </Link>
                </NavItem> */}
          {/* <UncontrolledDropdown inNavbar nav>
                  <DropdownToggle caret nav>
                    DD Menu
                  </DropdownToggle>
                  <DropdownMenu end>
                    <DropdownItem>Option 1</DropdownItem>
                    <DropdownItem>Option 2</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>Reset</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> */}
        </Nav>
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle color="transparent">
            <img
              src={user1}
              alt="profile"
              className="rounded-circle"
              width="30"
            ></img>
          </DropdownToggle>
          <DropdownMenu>
            {/* <DropdownItem header>Info</DropdownItem> */}
            <DropdownItem onClick={() => navigate('/dashboard/my-account')}>My Account</DropdownItem>
            {/* <DropdownItem>Edit Profile</DropdownItem> */}
            <DropdownItem divider />
            {/* <DropdownItem>My Balance</DropdownItem> */}
            {/* <DropdownItem>Inbox</DropdownItem> */}
            <DropdownItem onClick={handlechange} >Change Password</DropdownItem>
            <DropdownItem onClick={handlLogOut}>Logout</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Collapse>
      {/* </Col> */}
      {/* </Row> */}
      {/* // </Container> */}

    </Navbar>
  );
};

export default Header;
