import React from 'react';
import { Card, CardTitle } from 'react-bootstrap';

function CustomCard({ heading, className, status }) {
    return (
        <>
            <Card body className={`${className} shadow`}  >
                <CardTitle as="h5" style={{ minHeight: '100px', fontWeight: '600' }} >{heading}</CardTitle>
                <div className="d-flex">
                    <h3 className="ms-auto">{status}</h3>
                </div>
            </Card >
        </>
    )
};
export default CustomCard
